@import url(https://fonts.googleapis.com/css?family=Playfair+Display);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dosis&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@600;700;800&family=Nunito+Sans:wght@400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
body {
    margin: 0;
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;
    overscroll-behavior-y: none;
}

.float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 20px;
    right: 20px;
    background-color: #25d366;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}

.my-float {
    margin-top: 16px;
}

#cool-wrap {
    height: 100vh;
    display: grid;
    grid-template-columns: 30px 1fr 1fr 30px;
    grid-template-rows: -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content;
    grid-template-rows: min-content min-content min-content min-content min-content min-content min-content min-content min-content min-content min-content min-content;
    grid-template-areas:
        ". header header ."
        ". header-title-cool header-title-cool ."
        "free-shipping free-shipping free-shipping free-shipping"
        "content-filter content-filter content-filter content-filter"
        "content-view content-view content-view content-view"
        ". search-bar search-bar ."
        "banner banner banner banner"
        "load-more-button load-more-button load-more-button load-more-button"
        ". rating rating ."
        "line line line line"
        ". social-media social-media ."
        ". footer footer .";
}

.rating {
    grid-area: rating;
}

.rating .rating-wrap {
    margin-top: 30px;
}

.rating .rating-content {
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 15px 50px 0px rgba(0, 0, 0, 0.1);
    font-size: 13px;
    color: #707070;
    padding-bottom: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.rating .rating-wrap .title {
    margin-top: 30px;
    font-size: 22px;
    color: #707070;
    padding-left: 20px;
}

.rating .first-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
}

.rating .rating-user {
    font-style: italic;
    padding-left: 30px;
}

.rating .rating-title {
    font-size: 15px;
}

.rating .second-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.rating .rating-review {
    margin-top: 15px;
    text-align: left;
    margin-bottom: 15px;
    padding-left: 30px;
    padding-right: 30px;
    text-align: left;
}

.rating .separator {
    width: "100%";
    height: 0px;
    background-color: gray;
    margin-top: 40px;
}

.free-shipping .food {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 10px;
    color: #ffff;
    background-color: #999999;
    padding: 6px;
}

.free-shipping .food .highlight {
    color: #ffffff;
    text-decoration: underline;
}

.social-media-container-cool {
    display: flex;
    align-items: center;
    margin-top: 30px;
}
.social-media-container-cool .icon {
    margin-left: 10px;
}
.header {
    grid-area: header;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 12px;
}

.header img {
    margin-top: -5px;
}

.header .cart {
    border: 2px solid rgba(var(--primary-color), 1);
    border-color: rgba(var(--primary-color), 1);
    border-radius: 10px;
    padding: 4px;
}

.header .cart img {
    width: 24px;
    height: 24px;
}

.header-title-cool {
    grid-area: header-title-cool;
}

.header-title-cool img {
    width: 50%;
}

.header-title-cool .title {
    color: #707070;
    font-size: 34px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0;
    line-height: 31px;
    letter-spacing: 0;
}

.header-title-cool p {
    font-size: 12px;
    line-height: 20px;
    -webkit-margin-after: 10px;
            margin-block-end: 10px;
}
.content-filter {
    grid-area: content-filter;
    margin-bottom: 20px;
    margin-top: 10px;
}
.content-filter hr {
    border: none;
    height: 1px;
    color: #707070;
    background-color: #707070;
}

.content-view {
    grid-area: content-view;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.copyright {
    grid-area: copyright;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #e3e3e3;
    padding: 10px;
    line-height: 10px;
}

.banner {
    grid-area: banner;
    margin-bottom: 30px;
}
.content {
    background-color: #e3e3e3;
    grid-area: content;
}
.line {
    grid-area: line;
}
.footer {
    margin-top: 20px;
    margin-bottom: 30px;
    grid-area: footer;
    display: flex;
    flex-direction: column;
}
.footer .logo {
    width: 90px;
    height: 90px;
}
.footer li {
    list-style: none;
    margin-left: 30px;
}
.footer li ul {
    margin: 0;
    padding: 0.4em;
    font-size: 12px;
    word-break: break-word;
}
.footer li ul img {
    width: 15px;
    height: 15px;
}
.footer-wrapper {
    flex-direction: row;
    display: flex;
}
.copyright {
    grid-area: copyright;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.copyright img {
    width: 63px;
    height: 22px;
    margin-bottom: 5px;
}
.copyright span {
    color: #9b9b9b;
    font-size: 9px;
}
.line {
    margin-top: 10px;
    border: none;
    height: 1px;
    color: #e5e5e5;
    background-color: #e5e5e5;
    width: "100%";
}

.list-view {
    display: flex;
    flex: 1 1;
    margin-right: 20px;
    margin-top: 20px;
}
.list-view .image {
    width: 112px;
    height: 112px;
    margin-left: 20px;
    margin-top: 0;
}
.list-view .list-details {
    margin-left: 15px;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: space-between;
}

.list-view .list-details .item-title {
    margin-top: 3px;
    font-size: 20px;
    font-weight: bold;
    color: #707070;
}

.list-view .list-details .item-description {
    font-size: 12px;
    line-height: 15px;
    color: #9b9b9b;
}

.list-view .list-details .item-price {
    font-size: 22px;
    margin-bottom: 3px;
    color: rgba(var(--primary-color), 1);
}

#product-wrap {
    height: 100vh;
    display: grid;
    grid-template-columns: 35px 1fr 1fr 35px;
    grid-template-rows: -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content;
    grid-template-rows: min-content min-content min-content min-content min-content;
    grid-template-areas:
        "product-picture product-picture product-picture product-picture"
        ". product-description product-description ."
        ". product-variations product-variations ."
        ". buttons buttons ."
        ". product-recomendations product-recomendations ."
        "copyright copyright copyright copyright";
}

.product-picture {
    grid-area: product-picture;
}

.product-description {
    grid-area: product-description;
}
.product-description h1 {
    font-size: 32px;
    color: #707070;
    font-weight: bold;
    display: block;
    font-size: 2em;
    -webkit-margin-before: 0.67em;
            margin-block-start: 0.67em;
    -webkit-margin-after: 0.67em;
            margin-block-end: 0.67em;
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
    -webkit-margin-end: 0px;
            margin-inline-end: 0px;
}

.product-description .description {
    font-size: 16px;
    color: #000000;
    margin-bottom: 20px;
}

.product-description h2 {
    font-size: 31px;
    color: rgba(var(--primary-color), 1);
    font-weight: bold;
}

.buttons {
    grid-area: buttons;
    margin-top: 60px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-around;
}
.buttons .secondary {
    font-size: 14px;
    border: 2px solid rgba(var(--primary-color), 1);
    border-radius: 20px;
    padding: 15px 33px;
    color: rgba(var(--primary-color), 1);
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.buttons .primary {
    font-size: 14px;
    border: 2px solid rgba(var(--primary-color), 1);
    background-color: rgba(var(--primary-color), 1);
    border-radius: 20px;
    padding: 15px 33px;
    color: white;
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
}
.buttons .disabled {
    font-size: 14px;
    border: 2px solid lightgray;
    background-color: lightgray;
    border-radius: 20px;
    padding: 15px 33px;
    color: white;
    font-weight: 600;
    text-decoration: none;
}
.buttons .disabled-long {
    font-size: 14px;
    border: 2px solid lightgray;
    background-color: lightgray;
    border-radius: 20px;
    padding: 15px 8px;
    color: white;
    font-weight: 600;
    text-decoration: none;
}

.buttons .primary-long {
    font-size: 14px;
    border: 2px solid rgba(var(--primary-color), 1);
    background-color: rgba(var(--primary-color), 1);
    border-radius: 20px;
    padding: 15px 8px;
    color: white;
    font-weight: 600;
    text-decoration: none;
}

.product-recomendations {
    grid-area: product-recomendations;
    font-size: 14px;
}
.product-recomendations h3 {
    grid-area: product-recomendations;
    font-size: 14px;
    font-weight: bold;
}
.product-recomendations .images {
    display: flex;
    flex: 1 1;
    justify-content: space-between;
}
.product-recomendations img {
    width: 90px;
    height: 120px;
    object-fit: cover;
    margin: 2px;
    margin-bottom: 30px;
}

#cart-wrap {
    height: 100vh;
    display: grid;
    grid-template-columns: 30px 1fr 1fr 30px;
    grid-template-rows: -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content 50px;
    grid-template-rows: min-content min-content min-content min-content min-content min-content min-content 50px;
    grid-template-areas:
        ". cart-header cart-header ."
        ". cart-header-title cart-header-title ."
        ". cart-items cart-items ."
        ". cart-shipping-food cart-shipping-food ."
        ". cart-coupon cart-coupon ."
        ". item-footer item-footer ."
        ". buttons buttons ."
        ". blank blank .";
}

.cart-shipping-food {
    grid-area: cart-shipping-food;
}

.cart-shipping-food .cart-shipping {
    display: flex;
    margin-top: 25px;
    border-radius: 10px;
    justify-content: space-between;
    display: flex;
    margin-top: 20px;
}

.cart-shipping-food .cart-shipping .free-shipping {
    font-family: "Roboto", sans-serif;
    justify-content: center;
    font-size: 15px;
    color: rgb(66, 66, 66);
    flex: 1 1;
    display: flex;
    align-items: center;
}

.cart-shipping-food .cart-shipping .apply-button {
    position: relative;
    border: solid #000000 1px;
    border-radius: 5px;
    margin: 0px;
    font-size: 0.1em;
    width: 50px;
    height: 19px;
    padding-top: 13px;
    justify-content: center;
    align-items: center;
    flex: 0.2 1;
    padding-bottom: 6px;
    margin-left: 5px;
    cursor: pointer;
    text-align: center;
}

.cart-shipping-food .cart-shipping-results {
    display: flex;
    border: solid rgba(0, 0, 0, 0.23) 1px;
    padding: 5px;
    border-radius: 0 0 10px 10px;
    justify-content: space-between;
    display: flex;
    margin-top: 5px;
    margin-bottom: 10px;
}

.cart-shipping-food .cart-shipping-results .MuiFormGroup-root {
    margin-left: 20px !important;
}

.line {
    grid-area: line;
}

.blank {
    grid-area: blank;
}
.item-footer {
    grid-area: item-footer;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    font-size: 20px;
    color: #707070;
}
.cart-header {
    grid-area: cart-header;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.cart-header .cart-icon img {
    width: 35px;
    height: 35px;
}

.cart-header-title {
    grid-area: cart-header-title;
}

.cart-header-title .title {
    color: #707070;
    font-size: 34px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0;
    line-height: 31px;
    letter-spacing: 0;
}

.cart-items {
    grid-area: cart-items;
    margin-top: 40px;
}

.cart-items ul {
    text-decoration: none;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    justify-content: flex-start;
}
.cart-items li {
    font-size: 13px;
    color: #9b9b9b;
    margin-right: 40px;
    margin-bottom: 10px;
}

.cart-items .item {
    display: flex;
    flex: 1 1;
    margin-top: 20px;
}
.cart-items .item img {
    width: 90px;
    height: 120px;
}
.cart-items .item-variation {
    font-size: 13px;
    color: #222222;
}

.cart-items .item .item-picture {
    flex: 0.4 1;
    margin-left: 10px;
}
.cart-items .item .item-quantity {
    flex: 0.55 1;
    display: flex;
    align-items: center;
}
.cart-items .item .item-quantity .minus {
    border: 1px solid #cccccc;
    width: 28px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: -1px;
    background-color: white;
    box-sizing: unset;
    padding: 0;
}

.cart-items .item .item-quantity .qty {
    border: 1px solid #cccccc;
    width: 28px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cart-items .item .item-quantity .add {
    border: 1px solid #cccccc;
    width: 28px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -1px;
    background-color: white;
    box-sizing: unset;
    padding: 0;
}
.cart-items .item .item-desc {
    flex: 0.4 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.cart-items .item .item-desc .item-name {
    color: #707070;
    font-size: 14px;
    line-height: 13px;
}
.cart-items .item .item-desc .item-price {
    color: #707070;
    font-size: 18px;
}
.cart-items span {
    font-size: 14px;
}
.total {
    display: flex;
    justify-content: flex-end;
    font-size: 25px;
    color: rgba(var(--primary-color), 1);
    margin-right: 14px;
    margin-top: 10px;
    margin-bottom: 20px;
}
.MuiButton-textPrimary {
    color: slategray;
}

@media screen and (min-width: 480px) {
    #cool-wrap {
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr 320px 320px 1fr;
        grid-template-rows: -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content 170px;
        grid-template-rows: min-content min-content min-content min-content min-content min-content min-content min-content min-content min-content 170px;
        grid-template-areas:
            ". header header ."
            ". header-title-cool header-title-cool ."
            ". free-shipping free-shipping ."
            "content-filter content-filter content-filter content-filter"
            "content-view content-view content-view content-view"
            ". search-bar search-bar ."
            ". banner banner ."
            "load-more-button load-more-button load-more-button load-more-button"
            ". rating rating ."
            "line line line line"
            ". social-media social-media ."
            ". footer footer .";
    }
    #product-wrap {
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr 320px 320px 1fr;
        grid-template-rows: -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content;
        grid-template-rows: min-content min-content min-content min-content min-content;
        grid-template-areas:
            ". product-picture product-picture ."
            ". product-description product-description ."
            ". product-variations product-variations ."
            ". buttons buttons ."
            ". product-recomendations product-recomendations ."
            "copyright copyright copyright copyright";
    }
    #cart-wrap {
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr 320px 320px 1fr;
        grid-template-rows: -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content 50px;
        grid-template-rows: min-content min-content min-content min-content min-content min-content min-content 50px;
        grid-template-areas:
            ". cart-header cart-header ."
            ". cart-header-title cart-header-title ."
            ". cart-items cart-items ."
            ". cart-shipping-food cart-shipping-food ."
            ". cart-coupon cart-coupon ."
            ". item-footer item-footer ."
            ". buttons buttons ."
            ". blank blank .";
    }

    .product-recomendations .images {
        justify-content: space-evenly;
    }

    .buttons {
        justify-content: center;
    }

    .buttons .primary {
        margin-left: 30px;
    }

    .buttons .disabled {
        margin-left: 30px;
    }

    .buttons .disabled-long {
        margin-left: 30px;
    }

    .buttons .primary-long {
        margin-left: 30px;
    }

    .float {
        bottom: 40px;
        right: 40px;
    }
    .header-title-cool img {
        width: 30%;
    }
}

/*================================== */
/* CHECKOUT
/*================================== */
#checkout-wrap {
    height: 100vh;
    display: grid;
    grid-template-columns: 30px 1fr 1fr 30px;
    grid-template-rows: -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content;
    grid-template-rows: min-content min-content min-content min-content min-content min-content min-content min-content min-content;
    grid-template-areas:
        "checkout-header checkout-header checkout-header checkout-header"
        ". checkout-header-2 checkout-header-2 ."
        ". checkout-client-data checkout-client-data ."
        ". checkout-delivery checkout-delivery ."
        ". checkout-sumary checkout-sumary ."
        ". checkout-payment checkout-payment ."
        ". checkout-note checkout-note ."
        ". checkout-card checkout-card ."
        ". checkout-buttons checkout-buttons ."
        "loading loading loading loading";
}

/*================================== */
/* HEADER
/*================================== */

.checkout-card {
    grid-area: checkout-card;
    margin-top: 40px;
    margin-bottom: 20px;
}

.checkout-card .title {
    color: rgba(var(--primary-color), 1);
    font-size: 14px;
    font-weight: 500;
}

.checkout-card .title-slip {
    color: rgba(var(--primary-color), 1);
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 20px;
}

.checkout-header-2 {
    grid-area: checkout-header-2;
}

.checkout-header {
    grid-area: checkout-header;
}

.checkout-header-2 .checkout-header-food img {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.checkout-header-2 .checkout-header-food h1 {
    color: #707070;
    font-size: 34px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: 0;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0;
    line-height: 31px;
}

.checkout-header .checkout-header-minimalist .title {
    font-family: "Bebas Neue", cursive;
    font-size: 18px;
    color: #222222;
}

.checkout-header .checkout-header-minimalist .checkout-icon img {
    width: 35px;
    height: 35px;
}

.checkout-header .checkout-header-minimalist {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    top: 0;
    position: fixed;
    height: 70px;
    width: 100%;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
    z-index: 100;
}

.checkout-header .checkout-header-fendi .title {
    font-family: "Bebas Neue", cursive;
    font-size: 18px;
    color: #222222;
}

.checkout-header .checkout-header-fendi .checkout-icon img {
    width: 35px;
    height: 35px;
}

.checkout-header .checkout-header-fendi {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    top: 0;
    position: fixed;
    height: 70px;
    width: 100%;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
    z-index: 100;
}

/*================================== */
/* DELIVERY
/*================================== */

.checkout-delivery {
    grid-area: checkout-delivery;
}

.checkout-client-data .marginTopFood {
    margin-top: 40px;
}

.checkout-client-data .marginTopMinimalist {
    margin-top: 110px;
}

.checkout-delivery .title {
    color: rgba(var(--primary-color), 1);
    font-size: 14px;
    font-weight: 500;
}

.checkout-delivery .options {
    display: grid;
    padding-left: 23px;
    margin-top: 20px;
}

.mdl-radio__label {
    cursor: pointer;
    padding-left: 12px;
    font-size: 14px;
}

.mdl-radio.is-checked .mdl-radio__outer-circle {
    border: 2px solid rgba(var(--primary-color), 1) !important;
}

.mdl-radio__inner-circle {
    background: rgba(var(--primary-color), 1) !important;
}

.mdl-textfield__label {
    font-size: 14px !important;
}

/*================================== */
/* CLIENT DATA
/*================================== */

.checkout-client-data {
    grid-area: checkout-client-data;
    margin-top: 20px;
}
.checkout-client-data .title {
    color: rgba(var(--primary-color), 1);
    font-size: 14px;
    font-weight: 500;
}

.checkout-client-data .data {
    margin-top: 20px;
}

/*================================== */
/* SUMARY
/*================================== */

.checkout-sumary {
    grid-area: checkout-sumary;
    margin-top: 20px;
}
.checkout-sumary .title {
    color: rgba(var(--primary-color), 1);
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 20px;
}

/*================================== */
/* PAYMENT
/*================================== */

.checkout-payment {
    grid-area: checkout-payment;
}
.checkout-payment .title {
    color: rgba(var(--primary-color), 1);
    font-size: 14px;
    font-weight: 500;
}
.checkout-payment .options {
    display: grid;
    padding-left: 23px;
    margin-top: 20px;
}

/*================================== */
/* NOTE
/*================================== */

.checkout-note {
    grid-area: checkout-note;
    margin-top: 30px;
}
.checkout-note .title {
    color: rgba(var(--primary-color), 1);
    font-size: 14px;
    font-weight: 500;
}

.checkout-note .data {
    margin-top: 20px;
}

/*================================== */
/* BUTTONS
/*================================== */

.checkout-buttons {
    grid-area: checkout-buttons;
}

.checkout-buttons .checkout-button-food {
    margin-top: 60px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-around;
}
.checkout-buttons .checkout-button-food .secondary {
    font-size: 14px;
    border: 2px solid rgba(var(--primary-color), 1);
    border-radius: 20px;
    padding: 15px 33px;
    color: rgba(var(--primary-color), 1);
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
}

.checkout-buttons .checkout-button-food .disabled {
    font-size: 14px;
    border: 2px solid rgba(0, 0, 0, 0.23);

    border-radius: 20px;
    padding: 15px 8px;
    color: white;
    font-weight: 600;
    text-decoration: none;
}

.checkout-buttons .checkout-button-food .primary {
    font-size: 14px;
    border: 2px solid rgba(var(--primary-color), 1);
    background-color: rgba(var(--primary-color), 1);
    border-radius: 20px;
    padding: 15px 8px;
    color: white;
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
}

.container-header-dark {
    display: flex;
    flex: 1 1;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    background-color: #424242;
}

.checkout-buttons .checkout-button-dark .enabled {
    margin-top: 40px;
    margin-bottom: 40px;
    background-color: rgba(var(--primary-color), 1);
    height: 58px;
    display: flex;
    cursor: pointer;
    font-family: "Dosis", sans-serif;
    font-size: 15px;
    color: rgba(var(--dynamic-text-color), 1);
    letter-spacing: 0.1em;
    align-items: center;
    justify-content: center;
}

.checkout-buttons .checkout-button-dark .disabled {
    margin-top: 40px;
    margin-bottom: 100px;
    height: 58px;
    display: flex;

    font-family: "Dosis", sans-serif;
    font-size: 15px;

    border: 1px solid rgba(0, 0, 0, 0.23);
    color: #e0e0e0;
    letter-spacing: 0.1em;
    align-items: center;
    justify-content: center;
}

.checkout-buttons .checkout-button-minimalist .enabled {
    margin-top: 40px;
    margin-bottom: 100px;
    background-color: rgba(var(--primary-color), 1);
    height: 58px;
    display: flex;
    box-shadow: 0px 15px 15px 0px rgba(0, 0, 0, 0.1);
    /*background: linear-gradient(to top, #444444 0%, rgba(0, 0, 0, 0.6) 100%);*/
    font-family: "Bebas Neue", cursive;
    font-size: 15px;
    color: rgba(var(--dynamic-text-color), 1);
    letter-spacing: 0.1em;
    align-items: center;
    justify-content: center;
    width: 100%;
    cursor: pointer;
}

.checkout-buttons .checkout-button-minimalist .disabled {
    margin-top: 40px;
    margin-bottom: 100px;
    height: 58px;
    display: flex;

    font-family: "Bebas Neue", cursive;
    font-size: 15px;

    border: 1px solid rgba(0, 0, 0, 0.23);
    color: #e0e0e0;
    letter-spacing: 0.1em;
    align-items: center;
    justify-content: center;
}

/*================================== */
/* LOADING
/*================================== */

.loading {
    grid-area: loading;
}

/*================================== */
/* TABLE
/*================================== */
.Rtable {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 2em 0;
    padding: 0;
}
.Rtable-cell {
    box-sizing: border-box;
    flex-grow: 1;
    width: 100%;
    border-bottom: 1px solid #e5e5e5;
    overflow: hidden;
    padding: 8px;
    list-style: none;
}
.Rtable-cell > h1,
.Rtable-cell > h2,
.Rtable-cell > h3,
.Rtable-cell > h4,
.Rtable-cell > h5,
.Rtable-cell > h6 {
    margin: 0;
}
.Rtable-footer {
    box-sizing: border-box;
    flex-grow: 1;
    width: 100%;
    overflow: hidden;
    list-style: none;
    padding: 8px;
}
.Rtable-footer > h1,
.Rtable-footer > h2,
.Rtable-footer > h3,
.Rtable-footer > h4,
.Rtable-footer > h5,
.Rtable-footer > h6 {
    margin: 0;
}
.Rtable-header {
    font-size: 13px;
    color: #9b9b9b;
}
.Rtable-content {
    font-size: 14px;
    color: #707070;
}
.Rtable-total {
    font-size: 16px;
    color: rgba(var(--primary-color), 1);
}
/* Table column sizing
================================== */
.Rtable--2cols > .Rtable-cell {
    width: 50%;
}
.Rtable--3cols > .Rtable-cell {
    width: 33.33%;
}
.Rtable--3cols > .Rtable-footer {
    width: 33.33%;
}
.Rtable--4cols > .Rtable-cell {
    width: 25%;
}
.Rtable--4cols > .Rtable-footer {
    width: 25%;
}
.Rtable--5cols > .Rtable-cell {
    width: 20%;
}
.Rtable--6cols > .Rtable-cell {
    width: 16.6%;
}

.MuiGridListTileBar-root {
    left: 0;
    right: 0;
    height: 22px !important;
    display: flex;
    position: absolute;
    background: none !important;
    align-items: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.MuiGridListTileBar-titleWrap {
    color: black !important;
    overflow: hidden;
    flex-grow: 0 !important;
    margin-left: 5px !important;
    margin-right: 16px;
    margin-bottom: 5px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 2px;
}

.MuiGridListTileBar-title {
    overflow: hidden;
    font-size: 11px !important;
    line-height: 12px !important;
    white-space: nowrap;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 5px;
    text-overflow: ellipsis;
    padding-right: 5px;
}
.secureImage {
    width: 100%;
}

/*================================== */
/* DESKTOP
/*================================== */
@media screen and (min-width: 480px) {
    #checkout-wrap {
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr 320px 320px 1fr;
        grid-template-rows: -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content;
        grid-template-rows: min-content min-content min-content min-content min-content min-content min-content min-content min-content;
        grid-template-areas:
            "checkout-header checkout-header checkout-header checkout-header"
            ". checkout-header-2 checkout-header-2 ."
            ". checkout-client-data checkout-client-data ."
            ". checkout-delivery checkout-delivery ."
            ". checkout-sumary checkout-sumary ."
            ". checkout-payment checkout-payment ."
            ". checkout-note checkout-note ."
            ". checkout-card checkout-card ."
            ". checkout-buttons checkout-buttons ."
            "loading loading loading loading";
    }

    .buttons {
        justify-content: center;
    }

    .buttons .primary {
        margin-left: 30px;
    }

    .buttons .disabled {
        margin-left: 30px;
    }

    .buttons .disabled-long {
        margin-left: 30px;
    }

    .buttons .primary-long {
        margin-left: 30px;
    }
    .secureImage {
        width: 50%;
    }
}

#success-wrap {
    height: 100vh;
    display: grid;
    grid-template-columns: 30px 1fr 1fr 30px;
    grid-template-rows: -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content;
    grid-template-rows: min-content min-content min-content min-content min-content min-content min-content min-content min-content min-content min-content min-content;
    grid-template-areas:
        ". success-header success-header ."
        ". success-header-title success-header-title ."
        "success-order success-order success-order success-order"
        ". success-sumary success-sumary ."
        ". success-details success-details ."
        ". success-status success-status ."
        ". success-delivery success-delivery ."
        ". success-shipping success-shipping ."
        ". success-payment success-payment ."
        ". success-note success-note ."
        ". success-buttons success-buttons ."
        "copyright copyright copyright copyright";
}
.success-header {
    grid-area: success-header;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.success-header .success-icon img {
    width: 35px;
    height: 35px;
}

.success-header-title {
    grid-area: success-header-title;
}

.success-header-title h1 {
    color: #707070;
    font-size: 34px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: 0;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0;
    line-height: 31px;
}

.success-order {
    grid-area: success-order;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #e3e3e3;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 40px;
    margin-bottom: 10px;
}

.success-order h1 {
    font-size: 23px;
    color: #707070;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 5px;
    margin-block-start: 0;
    margin-block-end: 0;
    line-height: 31px;
}

.success-order p {
    font-size: 14px;
    color: #707070;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0;
    margin-left: 22px;
    margin-right: 22px;
    margin-top: 5px;
}

.success-order h3 {
    font-size: 14px;
    color: #707070;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0;
    line-height: 15px;
}

.success-order span {
    margin-top: 10px;

    font-size: 23px;
    color: rgba(var(--primary-color), 1);
    -webkit-margin-before: 0;
            margin-block-start: 0;
}

.success-shipping {
    grid-area: success-shipping;
    margin-top: 20px;
}
.success-shipping .title {
    color: rgba(var(--primary-color), 1);
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
}

.success-shipping p {
    font-size: 15px;
    color: #707070;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0;
}

.success-delivery {
    grid-area: success-delivery;
    margin-top: 20px;
}
.success-delivery .title {
    color: rgba(var(--primary-color), 1);
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
}

.success-delivery p {
    font-size: 15px;
    color: #707070;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0;
}
.success-sumary {
    grid-area: success-sumary;
    margin-top: 20px;
}
.success-sumary .title {
    color: rgba(var(--primary-color), 1);
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 20px;
}

.success-details {
    grid-area: success-details;
}

.success-status {
    grid-area: success-status;
    margin-top: 20px;
}

.success-status .title {
    color: rgba(var(--primary-color), 1);
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
}
.success-status p {
    font-size: 15px;
    color: #707070;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0;
}

.success-details .title {
    color: rgba(var(--primary-color), 1);
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
}
.success-details p {
    font-size: 15px;
    color: #707070;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0;
}

.success-note {
    grid-area: success-note;
}
.success-note .title {
    color: rgba(var(--primary-color), 1);
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 20px;
}

.success-note p {
    font-size: 15px;
    color: #707070;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0;
}

.success-payment {
    grid-area: success-payment;
}
.success-payment .title {
    color: rgba(var(--primary-color), 1);
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 20px;
}
.success-payment p {
    font-size: 15px;
    color: #707070;

    -webkit-margin-before: 0;

            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0;
}

.success-buttons {
    grid-area: success-buttons;
}

.success-buttons .success-button-minimalist {
    margin-top: 40px;
    margin-bottom: 100px;
    background-color: #444444;
    height: 58px;
    display: flex;
    box-shadow: 0px 15px 15px 0px rgba(0, 0, 0, 0.1);
    background: linear-gradient(to top, #444444 0%, rgba(0, 0, 0, 0.6) 100%);
    font-family: "Bebas Neue", cursive;
    font-size: 15px;
    color: #fff;
    letter-spacing: 0.1em;
    align-items: center;
    justify-content: center;
    width: 100%;
    cursor: pointer;
}
.slip-button {
    margin-top: 40px;
    background-color: #444444;
    height: 58px;
    display: flex;
    box-shadow: 0px 15px 15px 0px rgba(0, 0, 0, 0.1);
    background: linear-gradient(to top, #444444 0%, rgba(0, 0, 0, 0.6) 100%);
    font-size: 15px;
    color: #fff;
    letter-spacing: 0.1em;
    align-items: center;
    justify-content: center;
    width: 100%;
    cursor: pointer;
}
.success-buttons .success-button-food {
    display: flex;
    justify-content: center;
}
.success-buttons .success-button-food .start {
    margin-top: 60px;
    margin-bottom: 30px;

    font-size: 14px;
    border: 2px solid rgba(var(--primary-color), 1);
    border-radius: 20px;
    padding: 15px 33px;
    color: rgba(var(--primary-color), 1);
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
}

@media screen and (min-width: 480px) {
    #success-wrap {
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr 320px 320px 1fr;
        grid-template-rows: -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content;
        grid-template-rows: min-content min-content min-content min-content min-content min-content min-content min-content min-content min-content min-content min-content;
        grid-template-areas:
            ". success-header success-header ."
            ". success-header-title success-header-title ."
            "success-order success-order success-order success-order"
            ". success-sumary success-sumary ."
            ". success-details success-details ."
            ". success-status success-status ."
            ". success-delivery success-delivery ."
            ". success-shipping success-shipping ."
            ". success-payment success-payment ."
            ". success-note success-note ."
            ". success-buttons success-buttons ."
            "copyright copyright copyright copyright";
    }
}

#review-wrap {
  height: 100vh;
  display: grid;
  grid-template-columns: 30px 1fr 1fr 30px;
  grid-template-rows: -webkit-min-content -webkit-min-content;
  grid-template-rows: min-content min-content;
  grid-template-areas:
    ". review-header review-header ."
    ". review-data review-data .";
  margin-top: 30px;
}

.review-header {
  grid-area: review-header;
}
.review-data {
  grid-area: review-data;
}

.review-data
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: gray;
  border-width: 2px;
}
.review-success {
  margin-top: 30px;
  text-align: center;
}
.review-title {
  margin-top: 10px;
}

.review-title .label {
  margin-bottom: 15px;
}
.review-stars {
  text-align: center;
  margin-top: 0px;
}

.review-stars .label {
  margin-top: 30px;
  margin-bottom: 10px;
}

.review-content .label {
  margin-bottom: 15px;
}

.review-button {
  margin-top: 40px;
  margin-bottom: 100px;
  background-color: #444444;
  height: 58px;
  display: flex;
  box-shadow: 0px 15px 15px 0px rgba(0, 0, 0, 0.1);
  background: linear-gradient(to top, #444444 0%, rgba(0, 0, 0, 0.6) 100%);
  font-family: "Bebas Neue", cursive;
  font-size: 15px;
  color: #fff;
  letter-spacing: 0.1em;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
}

.review-header {
  text-align: center;
}

.review-header .store-name {
  font-size: 25px;
}

.review-logo {
  width: 50px;
  height: 50px;
}

/*================================== */
/* DESKTOP
/*================================== */
@media screen and (min-width: 480px) {
  #review-wrap {
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr 320px 320px 1fr;
    grid-template-rows: -webkit-min-content -webkit-min-content;
    grid-template-rows: min-content min-content;
    grid-template-areas:
      ". review-header review-header ."
      ". review-data review-data .";
    margin-top: 30px;
  }
}

body {
    margin: 0;
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;
    overscroll-behavior-y: none;
}

.float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 20px;
    right: 20px;
    background-color: #25d366;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}

.my-float {
    margin-top: 16px;
}

#site-wrap {
    height: 100vh;
    display: grid;
    grid-template-columns: 30px 1fr 1fr 30px;
    grid-template-rows: -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content;
    grid-template-rows: min-content min-content min-content min-content min-content min-content min-content min-content min-content min-content min-content min-content min-content;
    grid-template-areas:
        ". header header ."
        ". social-media social-media ."
        ". header-title header-title ."
        "free-shipping free-shipping free-shipping free-shipping"
        "promo-banner promo-banner promo-banner promo-banner"
        "content-filter content-filter content-filter content-filter"
        "content-view content-view content-view content-view"
        ". search-bar search-bar ."
        "banner banner banner banner"
        "load-more-button load-more-button load-more-button load-more-button"
        ". rating rating ."
        "line line line line"
        ". footer footer .";
}

#categories-wrap {
    height: 100vh;
    display: grid;
    grid-template-columns: 30px 1fr 1fr 30px;
    grid-template-rows: -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content;
    grid-template-rows: min-content min-content min-content min-content min-content min-content min-content min-content;
    grid-template-areas:
        "promo-banner promo-banner promo-banner promo-banner"
        ". search-bar search-bar ."
        "banner banner banner banner"
        "load-more-button load-more-button load-more-button  load-more-button"
        ". buttons buttons .";
}

.rating {
    grid-area: rating;
}

.rating .rating-wrap {
    margin-top: 30px;
}

.rating .rating-content {
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 15px 50px 0px rgba(0, 0, 0, 0.1);
    font-size: 13px;
    color: #707070;
    padding-bottom: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.rating .rating-wrap .title {
    margin-top: 30px;
    font-size: 22px;
    color: #707070;
    padding-left: 20px;
}

.rating .first-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
}

.rating .rating-user {
    font-style: italic;
    padding-left: 30px;
}

.rating .rating-title {
    font-size: 15px;
}

.rating .second-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.rating .rating-review {
    margin-top: 15px;
    text-align: left;
    margin-bottom: 15px;
    padding-left: 30px;
    padding-right: 30px;
    text-align: left;
}

.rating .separator {
    width: "100%";
    height: 0px;
    background-color: gray;
    margin-top: 40px;
}

.free-shipping .food {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 10px;
    color: #ffff;
    background-color: #999999;
    padding: 6px;
}

.free-shipping .food .highlight {
    color: #ffffff;
    text-decoration: underline;
}

.social-media-container-food {
    display: flex;

    align-items: center;
}
.header {
    grid-area: header;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 12px;
}

.header img {
    margin-top: -5px;
}

.header .cart {
    border: 2px solid rgba(var(--primary-color), 1);
    border-color: rgba(var(--primary-color), 1);
    border-radius: 10px;
    padding: 4px;
    color: #fff;
}

.header .cart img {
    width: 24px;
    height: 24px;
}

.badge-food .MuiBadge-colorSecondary {
    background-color: #fff;
    border: 2px solid rgba(var(--primary-color), 1);
    color: rgba(var(--dynamic-text-color), 1);
}

.header-title {
    grid-area: header-title;
    margin-top: 23px;
}

.header-title .title {
    color: #707070;
    font-size: 34px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0;
    line-height: 31px;
    letter-spacing: 0;
}

.header-title p {
    font-size: 12px;
    line-height: 20px;
    -webkit-margin-before: 30px;
            margin-block-start: 30px;
    -webkit-margin-after: 10px;
            margin-block-end: 10px;
}
.content-filter {
    grid-area: content-filter;
    margin-bottom: 20px;
    margin-top: 10px;
}
.content-filter hr {
    border: none;
    height: 1px;
    color: #707070;
    background-color: #707070;
}

.content-view {
    grid-area: content-view;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.copyright {
    grid-area: copyright;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #e3e3e3;
    padding: 10px;
    line-height: 10px;
}

.banner {
    grid-area: banner;
    margin-bottom: 30px;
}
.content {
    background-color: #e3e3e3;
    grid-area: content;
}
.line {
    grid-area: line;
}
.footer {
    margin-top: 20px;
    margin-bottom: 30px;
    grid-area: footer;
    display: flex;
}
.footer .logo {
    width: 90px;
    height: 90px;
}
.footer li {
    list-style: none;
    margin-left: 30px;
}
.footer li ul {
    margin: 0;
    padding: 0.4em;
    font-size: 12px;
    word-break: break-word;
}
.footer li ul img {
    width: 15px;
    height: 15px;
}
.copyright {
    grid-area: copyright;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.copyright img {
    width: 63px;
    height: 22px;
    margin-bottom: 5px;
}
.copyright span {
    color: #9b9b9b;
    font-size: 9px;
}
.line {
    margin-top: 10px;
    border: none;
    height: 1px;
    color: #e5e5e5;
    background-color: #e5e5e5;
    width: "100%";
}

.list-view {
    display: flex;
    flex: 1 1;
    margin-right: 20px;
    margin-top: 20px;
}
.list-view .image {
    width: 150px;
    height: 150px;
    margin-left: 20px;
    margin-top: 0;
}
.list-view .list-details {
    margin-left: 15px;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: space-between;
}

.list-view .list-details .item-title {
    margin-top: 3px;
    font-size: 20px;
    font-weight: bold;
    color: #707070;
}

.list-view .list-details .item-description {
    font-size: 12px;
    line-height: 15px;
    color: #9b9b9b;
}

.list-view .list-details .item-price {
    font-size: 22px;
    margin-bottom: 3px;
    color: rgba(var(--primary-color), 1);
}

#product-wrap {
    height: 100vh;
    display: grid;
    grid-template-columns: 35px 1fr 1fr 35px;
    grid-template-rows: -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content;
    grid-template-rows: min-content min-content min-content min-content min-content;
    grid-template-areas:
        "product-picture product-picture product-picture product-picture"
        ". product-description product-description ."
        ". product-variations product-variations ."
        ". buttons buttons ."
        ". product-recomendations product-recomendations ."
        "copyright copyright copyright copyright";
}

.product-picture {
    grid-area: product-picture;
}

.product-description {
    grid-area: product-description;
}
.product-description h1 {
    font-size: 32px;
    color: #707070;
    font-weight: bold;
    display: block;
    font-size: 2em;
    -webkit-margin-before: 0.67em;
            margin-block-start: 0.67em;
    -webkit-margin-after: 0.67em;
            margin-block-end: 0.67em;
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
    -webkit-margin-end: 0px;
            margin-inline-end: 0px;
}

.product-description .description {
    font-size: 16px;
    color: #000000;
    margin-bottom: 20px;
}

.product-description h2 {
    font-size: 31px;
    color: rgba(var(--primary-color), 1);
    font-weight: bold;
}

.buttons {
    grid-area: buttons;
    margin-top: 60px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-around;
}
.buttons .secondary {
    font-size: 14px;
    border: 2px solid rgba(var(--primary-color), 1);
    border-radius: 20px;
    padding: 15px 33px;
    color: rgba(var(--primary-color), 1);
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.buttons .primary {
    font-size: 14px;
    border: 2px solid rgba(var(--primary-color), 1);
    background-color: rgba(var(--primary-color), 1);
    border-radius: 20px;
    padding: 15px 33px;
    color: white;
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
}
.buttons .disabled {
    font-size: 14px;
    border: 2px solid lightgray;
    background-color: lightgray;
    border-radius: 20px;
    padding: 15px 33px;
    color: white;
    font-weight: 600;
    text-decoration: none;
}
.buttons .disabled-long {
    font-size: 14px;
    border: 2px solid lightgray;
    background-color: lightgray;
    border-radius: 20px;
    padding: 15px 8px;
    color: white;
    font-weight: 600;
    text-decoration: none;
}

.buttons .primary-long {
    font-size: 14px;
    border: 2px solid rgba(var(--primary-color), 1);
    background-color: rgba(var(--primary-color), 1);
    border-radius: 20px;
    padding: 15px 8px;
    color: white;
    font-weight: 600;
    text-decoration: none;
}

.product-recomendations {
    grid-area: product-recomendations;
    font-size: 14px;
}
.product-recomendations h3 {
    grid-area: product-recomendations;
    font-size: 14px;
    font-weight: bold;
}
.product-recomendations .images {
    display: flex;
    flex: 1 1;
    justify-content: space-between;
}
.product-recomendations img {
    width: 90px;
    height: 120px;
    object-fit: contain;
    margin: 2px;
    margin-bottom: 30px;
}

#cart-wrap {
    height: 100vh;
    display: grid;
    grid-template-columns: 30px 1fr 1fr 30px;
    grid-template-rows: -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content 50px;
    grid-template-rows: min-content min-content min-content min-content min-content min-content min-content 50px;
    grid-template-areas:
        ". cart-header cart-header ."
        ". cart-header-title cart-header-title ."
        ". cart-items cart-items ."
        ". cart-shipping-food cart-shipping-food ."
        ". cart-coupon cart-coupon ."
        ". item-footer item-footer ."
        ". buttons buttons ."
        ". blank blank .";
}

.cart-shipping-food {
    grid-area: cart-shipping-food;
}

.cart-shipping-food .cart-shipping {
    display: flex;
    margin-top: 25px;
    border-radius: 10px;
    justify-content: space-between;
    display: flex;
    margin-top: 20px;
}

.cart-shipping-food .cart-shipping .free-shipping {
    font-family: "Roboto", sans-serif;
    justify-content: center;
    font-size: 15px;
    color: rgb(66, 66, 66);
    flex: 1 1;
    display: flex;
    align-items: center;
}

.cart-shipping-food .cart-shipping .apply-button {
    position: relative;
    border: solid #000000 1px;
    border-radius: 5px;
    margin: 0px;
    font-size: 0.1em;
    width: 50px;
    height: 19px;
    padding-top: 13px;
    justify-content: center;
    align-items: center;
    flex: 0.2 1;
    padding-bottom: 6px;
    margin-left: 5px;
    cursor: pointer;
    text-align: center;
}

.cart-shipping-food .cart-shipping-results {
    display: flex;
    border: solid rgba(0, 0, 0, 0.23) 1px;
    padding: 5px;
    border-radius: 0 0 10px 10px;
    justify-content: space-between;
    display: flex;
    margin-top: 5px;
    margin-bottom: 10px;
}

.cart-shipping-food .cart-shipping-results .MuiFormGroup-root {
    margin-left: 20px !important;
}

.line {
    grid-area: line;
}

.blank {
    grid-area: blank;
}
.item-footer {
    grid-area: item-footer;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    font-size: 20px;
    color: #707070;
}
.cart-header {
    grid-area: cart-header;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.cart-header .cart-icon img {
    width: 35px;
    height: 35px;
}

.cart-header-title {
    grid-area: cart-header-title;
}

.cart-header-title .title {
    color: #707070;
    font-size: 34px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0;
    line-height: 31px;
    letter-spacing: 0;
}

.cart-items {
    grid-area: cart-items;
    margin-top: 40px;
}

.cart-items ul {
    text-decoration: none;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    justify-content: flex-start;
}
.cart-items li {
    font-size: 13px;
    color: #9b9b9b;
    margin-right: 40px;
    margin-bottom: 10px;
}

.cart-items .item {
    display: flex;
    flex: 1 1;
    margin-top: 20px;
}
.cart-items .item img {
    width: 90px;
    height: 120px;
}
.cart-items .item-variation {
    font-size: 13px;
    color: #222222;
}

.cart-items .item .item-picture {
    flex: 0.4 1;
    margin-left: 10px;
}
.cart-items .item .item-quantity {
    flex: 0.55 1;
    display: flex;
    align-items: center;
}
.cart-items .item .item-quantity .minus {
    border: 1px solid #cccccc;
    width: 28px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: -1px;
    background-color: white;
    box-sizing: unset;
    padding: 0;
}

.cart-items .item .item-quantity .qty {
    border: 1px solid #cccccc;
    width: 28px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cart-items .item .item-quantity .add {
    border: 1px solid #cccccc;
    width: 28px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -1px;
    background-color: white;
    box-sizing: unset;
    padding: 0;
}
.cart-items .item .item-desc {
    flex: 0.4 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.cart-items .item .item-desc .item-name {
    color: #707070;
    font-size: 14px;
    line-height: 13px;
}
.cart-items .item .item-desc .item-price {
    color: #707070;
    font-size: 18px;
}
.cart-items span {
    font-size: 14px;
}
.total {
    display: flex;
    justify-content: flex-end;
    font-size: 25px;
    color: rgba(var(--primary-color), 1);
    margin-right: 14px;
    margin-top: 10px;
    margin-bottom: 20px;
}
.MuiButton-textPrimary {
    color: slategray;
}

@media screen and (min-width: 480px) {
    #site-wrap {
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr 320px 320px 1fr;
        grid-template-rows: -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content 170px;
        grid-template-rows: min-content min-content min-content min-content min-content min-content min-content min-content min-content min-content min-content 170px;
        grid-template-areas:
            ". header header ."
            ". social-media social-media ."
            ". header-title header-title ."
            ". free-shipping free-shipping ."
            ". promo-banner promo-banner ."
            "content-filter content-filter content-filter content-filter"
            "content-view content-view content-view content-view"
            ". search-bar search-bar ."
            ". banner banner ."
            "load-more-button load-more-button load-more-button load-more-button"
            ". rating rating ."
            "line line line line"
            ". footer footer .";
    }
    #categories-wrap {
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr 320px 320px 1fr;
        grid-template-rows: -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content;
        grid-template-rows: min-content min-content min-content min-content min-content min-content min-content min-content;
        grid-template-areas:
            ". promo-banner promo-banner ."
            ". search-bar search-bar ."
            ". banner banner ."
            "load-more-button load-more-button load-more-button  load-more-button"
            ". buttons buttons .";
    }
    #product-wrap {
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr 320px 320px 1fr;
        grid-template-rows: -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content;
        grid-template-rows: min-content min-content min-content min-content min-content;
        grid-template-areas:
            ". product-picture product-picture ."
            ". product-description product-description ."
            ". product-variations product-variations ."
            ". buttons buttons ."
            ". product-recomendations product-recomendations ."
            "copyright copyright copyright copyright";
    }
    #cart-wrap {
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr 320px 320px 1fr;
        grid-template-rows: -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content 50px;
        grid-template-rows: min-content min-content min-content min-content min-content min-content min-content 50px;
        grid-template-areas:
            ". cart-header cart-header ."
            ". cart-header-title cart-header-title ."
            ". cart-items cart-items ."
            ". cart-shipping-food cart-shipping-food ."
            ". cart-coupon cart-coupon ."
            ". item-footer item-footer ."
            ". buttons buttons ."
            ". blank blank .";
    }

    .product-recomendations .images {
        justify-content: space-evenly;
    }

    .buttons {
        justify-content: center;
    }

    .buttons .primary {
        margin-left: 30px;
    }

    .buttons .disabled {
        margin-left: 30px;
    }

    .buttons .disabled-long {
        margin-left: 30px;
    }

    .buttons .primary-long {
        margin-left: 30px;
    }

    .float {
        bottom: 40px;
        right: 40px;
    }

    .product-recomendations img {
        width: 150px;
        height: 100%;
        object-fit: contain;
        margin: 2px;
        margin-bottom: 30px;
    }
}

:root {
    --primary-color: 34, 34, 34;
    --dynamic-text-color: 255, 255, 255;
    --bg-color: 255, 255, 255;
}
body {
    background-color: rgba(255, 255, 255, 1);
    background-color: rgba(var(--bg-color), 1);
}
.carousel-container {
    position: relative;
    max-width: 800px;
    margin: auto;
}
.carousel-position {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 18px;
    border-radius: 15px;
    font-size: 12px;
    border-color: rgb(41, 4, 4);
    color: #ffffff;
    margin-right: 10px;
    margin-top: 10px;
    background-color: rgba(197, 105, 25, 0.988);
    text-align: center;
    padding-top: 4px;
}

.badge-minimalist .MuiBadge-colorSecondary {
    background-color: rgba(34, 34, 34, 1);
    background-color: rgba(var(--primary-color), 1);
    color: rgba(255, 255, 255, 1);
    color: rgba(var(--dynamic-text-color), 1);
}
.carousel-dotgroup {
    text-align: center;
    margin-top: 10px;
}

.carousel__dot--selected {
    background-color: gray;
}
.carousel-dotgroup button {
    width: 10px;
    padding: 0px;
    height: 10px;
    margin-right: 5px;
    border-radius: 5px;
}

#minimalist-wrap {
    height: 100vh;
    display: grid;
    grid-template-columns: 21px 1fr 21px;
    grid-template-rows: 70px 70px -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content;
    grid-template-rows: 70px 70px min-content min-content min-content min-content min-content min-content min-content min-content min-content min-content;
    grid-template-areas:
        "header-minimalist header-minimalist header-minimalist"
        "blank blank blank"
        "free-shipping free-shipping free-shipping"
        "promo-banner promo-banner promo-banner"
        "banner-minimalist banner-minimalist banner-minimalist"
        "product-anchor-area product-anchor-area product-anchor-area"
        "search-bar search-bar search-bar"
        ". product-grid-minimalist ."
        "load-more-button load-more-button load-more-button"
        ". rating-minimalist ."
        ". line ."
        ". footer-minimalist ."
        ". social-media ."
        "copyright-minimalist copyright-minimalist copyright-minimalist";
}

#minimalist-categories-wrap {
    height: 100vh;
    display: grid;
    grid-template-columns: 21px 1fr 21px;
    grid-template-rows: 45px -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content;
    grid-template-rows: 45px min-content min-content min-content min-content min-content min-content min-content min-content;
    grid-template-areas:
        "header-minimalist header-minimalist header-minimalist"
        "blank blank blank"
        "minimalist-categories-title minimalist-categories-title minimalist-categories-title"
        "promo-banner promo-banner promo-banner"
        ". search-bar ."
        ". product-grid-minimalist ."
        "load-more-button load-more-button load-more-button"
        ". footer-minimalist ."
        ". social-media .";
}
.payment-section {
    padding: 10px;
}
.payment-label {
    font-family: Proxima Nova, -apple-system, Helvetica Neue, Helvetica, Roboto, Arial,
        sans-serif;
    font-size: 12px;
    margin-top: 20px;
}
.payment-wrapper ul {
    list-style: disc;
    margin: 0 0 1em;
    padding: 0;
    display: inline-block;

    margin: 2px;
    overflow: hidden;
    text-indent: 100%;
    vertical-align: middle;
    white-space: nowrap;
    background-repeat: no-repeat;
    background-image: url("https://http2.mlstatic.com/secure/org-img/ui/payment-methods/1.8.0/br/payment-methods-large@2x.png");
    background-size: 100px;
}

.payment-visa {
    height: 45px;
    width: 60px;
    background-position: 0 -30px;
}
.payment-mastercard {
    height: 37px;
    width: 45px;
    background-position: 0 -82px;
}
.payment-hipercard {
    height: 42px;
    width: 60px;
    background-position: 0 -250px;
}
.payment-amex {
    height: 43px;
    width: 30px;
    background-position: 0 -120px;
}
.payment-diners {
    height: 43px;
    width: 90px;
    background-position: 0 -162px;
}
.payment-elo {
    height: 45px;
    width: 35px;
    background-position: 0 -290px;
}
.payment-melicard {
    height: 42px;
    width: 47px;
    background-position: 0 -335px;
}
.payment-bolbradesco {
    height: 40px;
    width: 60px;
    background-position: 0 -378px;
}

.product-anchor-area {
    grid-area: product-anchor-area;
}
.rating-minimalist {
    grid-area: rating-minimalist;
}

.rating-minimalist .rating-wrap {
    margin-top: 30px;
    font-family: "Playfair Display", serif;
}

.rating-minimalist .rating-content {
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 15px 50px 0px rgba(0, 0, 0, 0.1);
    font-size: 13px;
    color: #707070;
    padding-bottom: 20px;
    margin-top: 30px;
}

.rating-minimalist .rating-wrap .title {
    margin-top: 30px;
    font-size: 30px;
    color: #222222;
}

.rating-minimalist .first-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
}

.rating-minimalist .rating-user {
    font-style: italic;
    padding-left: 30px;
}

.rating-minimalist .rating-title {
    font-size: 15px;
}

.rating-minimalist .second-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.rating-minimalist .rating-review {
    margin-top: 15px;
    text-align: left;
    margin-bottom: 15px;
    padding-left: 30px;
    padding-right: 30px;
    text-align: left;
}

.rating-minimalist .separator {
    width: "100%";
    height: 0px;
    background-color: gray;
    margin-top: 40px;
}

.free-shipping {
    grid-area: free-shipping;
}

.free-shipping .minimalist {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Bebas Neue", cursive;
    font-size: 12px;
    color: #222222;
    background-color: #ffffff;
    padding: 6px;
}

.free-shipping .highlight {
    color: rgba(34, 34, 34, 1);
    color: rgba(var(--primary-color), 1);
}

.free-shipping .space {
    margin-left: 3px;
    margin-right: 3px;
}

.load-more-button {
    grid-area: load-more-button;
    display: flex;
    text-align: center;
    padding: 10px;
    border: 1px solid;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    cursor: pointer;
}

.social-media {
    grid-area: social-media;
}

.social-media-container {
    display: flex;
    margin-top: 30px;
    align-items: center;
}

.social-media .title {
    font-family: "Playfair Display", serif;
    font-size: 20px;
    color: #222222;
    margin-right: 10px;
}

.social-media .icon {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: rgba(34, 34, 34, 1);
    background-color: rgba(var(--primary-color), 1);
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    margin-right: 10px;
}

.social-media i {
    color: white;
    font-size: 20px;
}

.drawer-title {
    margin-left: 30px;
    font-family: "Bebas Neue", cursive;
    font-size: 20px;
    color: #222222;
}

.drawer-clean {
    margin-right: 20px;
    font-family: "Bebas Neue", cursive;
    font-size: 16px;
    color: #222222;
    border: solid 1px #222222;
    padding: 5px;
    cursor: pointer;
}

.drawer-items {
    margin-top: 30px;
    margin-left: 30px;
    font-family: "Playfair Display", serif;
    font-size: 28px;
    color: #888888;
    list-style-type: none;
}
.drawer-items li:not(:last-child) {
    margin-bottom: 10px;
}
.empty-cart-image {
    width: 40%;
    margin-top: 60px;
}

.empty-cart-container {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.empty-cart-container .title {
    font-family: "Montserrat", sans-serif;
    color: #f1456f;
    margin-top: 10px;
}
.empty-cart-container .subtitle {
    font-family: "Montserrat", sans-serif;
    color: gray;
    font-size: 12px;
}

#cart-minimalist-wrap {
    height: 100vh;
    display: grid;
    grid-template-columns: 21px 1fr 21px;
    grid-template-rows: 70px -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content 1fr -webkit-min-content;
    grid-template-rows: 70px min-content min-content min-content min-content 1fr min-content;
    grid-template-areas:
        "cart-header-minimalist cart-header-minimalist cart-header-minimalist"
        ". cart-items-minimalist ."
        ". cart-shipping-minimalist ."
        ". cart-coupon ."
        ". cart-total-minimalist ."
        ". cart-button-minimalist ."
        "copyright-minimalist copyright-minimalist copyright-minimalist";
}
.cart-shipping-minimalist {
    grid-area: cart-shipping-minimalist;
}

.cart-shipping-minimalist .cart-shipping {
    display: flex;
    margin-top: 25px;
    border-radius: 10px;
    justify-content: space-between;
    display: flex;
    margin-top: 20px;
}

.cart-shipping-minimalist .cart-shipping .free-shipping {
    font-family: "Roboto", sans-serif;
    justify-content: center;
    font-size: 15px;
    color: rgb(66, 66, 66);
    flex: 1 1;
    display: flex;
    align-items: center;
}

.cart-shipping-minimalist .cart-shipping .apply-button {
    position: relative;
    border: solid #000000 1px;
    border-radius: 5px;
    margin: 0px;
    font-size: 0.6em;
    width: 50px;
    height: 19px;
    padding-top: 13px;
    justify-content: center;
    align-items: center;
    flex: 0.2 1;
    padding-bottom: 6px;
    margin-left: 5px;
    cursor: pointer;
    text-align: center;
}

.cart-shipping-minimalist .cart-shipping-results {
    display: flex;
    border: solid rgba(0, 0, 0, 0.23) 1px;
    padding: 5px;
    border-radius: 0 0 10px 10px;
    justify-content: space-between;
    display: flex;
    margin-top: 5px;
    margin-bottom: 10px;
}

.cart-shipping-minimalist .cart-shipping-results .MuiFormGroup-root {
    margin-left: 20px !important;
}

.cart-coupon {
    grid-area: cart-coupon;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
}

.cart-coupon .apply-button {
    border: solid #000000 1px;
    border-radius: 5px;
    margin: 0px;
    font-size: 0.6em;
    width: 50px;
    height: 19px;
    padding-top: 13px;
    justify-content: center;
    align-items: center;
    flex: 0.2 1;
    padding-bottom: 6px;
    margin-left: 5px;
    cursor: pointer;
    text-align: center;
}
.cart-header-minimalist {
    grid-area: cart-header-minimalist;
    display: flex;
    align-items: center;
    background-color: white;
    top: 0;
    position: fixed;
    height: 70px;
    width: 100%;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
    z-index: 100;
}
.cart-items-minimalist {
    grid-area: cart-items-minimalist;
}

.cart-items-minimalist .item {
    display: flex;
    margin-top: 25px;
}

.cart-items-minimalist .item .item-description {
    margin-left: 30px;
    margin-top: 30px;
}
.cart-items-minimalist .item-picture-image {
    width: 130px;
    height: 190px;
    object-fit: cover;
}

.cart-items-minimalist .item-name {
    font-family: "Playfair Display", serif;
    line-height: 25px;
    font-size: 22px;
    color: #222222;
}
.cart-items-minimalist .item-variation {
    font-family: "Playfair Display", serif;
    color: #222222;
}
.cart-items-minimalist .item-price {
    margin-top: 15px;
    margin-left: 3px;
    font-family: "Bebas Neue", cursive;
    font-size: 16px;
    color: #888888;
    letter-spacing: 0.05em;
}

.cart-items-minimalist .item-price-line {
    margin-top: 15px;
    margin-left: 3px;
    font-family: "Bebas Neue", cursive;
    font-size: 16px;
    color: #888888;

    text-decoration: line-through;
}

.cart-items-minimalist .item-promoprice {
    margin-top: 15px;
    margin-left: 10px;
    font-family: "Bebas Neue", cursive;
    font-size: 16px;
    color: #000;
    letter-spacing: 0.05em;
}

.cart-items-minimalist .item-quantity {
    display: flex;
    margin-top: 15px;
}

.cart-items-minimalist .item .item-quantity .minus {
    border: 1px solid #cccccc;
    width: 28px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: -1px;
    background-color: white;
    box-sizing: unset;
    padding: 0;
}

.cart-items-minimalist .item .item-quantity .qty {
    border: 1px solid #cccccc;
    width: 28px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}
.cart-items-minimalist .item .item-quantity .add {
    border: 1px solid #cccccc;
    width: 28px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -1px;
    background-color: white;
    box-sizing: unset;
    padding: 0;
}

.cart-total-minimalist {
    grid-area: cart-total-minimalist;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    font-family: "Playfair Display", serif;

    font-size: 20px;
    color: #222222;
}
.cart-button-minimalist {
    grid-area: cart-button-minimalist;
    margin-top: 40px;
    margin-bottom: 100px;
    background-color: rgba(34, 34, 34, 1);
    background-color: rgba(var(--primary-color), 1);
    height: 58px;
    display: flex;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
    /*background: linear-gradient(to top, #444444 0%, rgba(0, 0, 0, 0.6) 100%);*/
    font-family: "Bebas Neue", cursive;
    font-size: 15px;
    color: rgba(255, 255, 255, 1);
    color: rgba(var(--dynamic-text-color), 1);
    letter-spacing: 0.1em;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

#product-minimalist-wrap {
    height: 100vh;
    display: grid;
    grid-template-columns: 21px 1fr 21px;
    grid-template-rows: -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content;
    grid-template-rows: min-content min-content min-content min-content min-content min-content min-content;
    grid-template-areas:
        "product-minimalist-picture product-minimalist-picture product-minimalist-picture"
        ". product-minimalist-description ."
        ". product-variations ."
        ". product-minimalist-button ."
        ". product-minimalist-recomendations ."
        ". product-share ."
        "copyright-minimalist copyright-minimalist copyright-minimalist";
}

.product-minimalist-picture {
    grid-area: product-minimalist-picture;
}
.product-minimalist-description {
    grid-area: product-minimalist-description;
    margin-top: 35px;
}
.product-variations {
    grid-area: product-variations;
}

.product-share {
    grid-area: product-share;
}

.product-share .title {
    margin-top: 20px;
    font-size: 14px;
    font-weight: bold;
}

.product-share .share-buttons {
    display: flex;
    display: flex;
    flex-direction: row;
    grid-gap: 15px;
    gap: 15px;
    justify-content: flex-start;
    align-items: end;
    margin-bottom: 20px;
}
.product-share .share-button {
    text-align: center;
}

.product-share .share-button .button-title {
    font-size: 12px;
}

.product-share .share-button .copy-link {
    font-size: 30px;
    cursor: pointer;
}
.product-share .share-button .whatsapp-icon {
    font-size: 28px;
}

.product-variations .title {
    background-color: #e6e6e6;
    padding: 24px;
    margin-top: 20px;

    font-size: 20px;
    margin-bottom: 10px;
}

.product-variations .title-box {
    margin-top: 20px;
    font-size: 14px;
    color: rgb(17 24 39 / 1);
    font-weight: 500;
}

.product-variations .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Playfair Display";
    padding-top: 14px;
    padding-bottom: 10px;
    border-bottom: solid 1px;
    border-bottom-color: #e6e6e6;
}

.product-variations .list-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 2%;
    gap: 2%;
}

.product-variations .list-box .description-on {
    border: solid 1px;
    background: black;
    color: white;
    padding: 10px;
    margin-bottom: 5px;
    margin-right: 5px;
}

.product-variations .list-box .box {
    margin-top: 10px;
    border: solid 1px;
    background-color: rgb(255 255 255 / 1);
    color: rgb(17 24 39 /1);
    text-transform: uppercase;
    font-weight: 500;
    font-size: 15px;
    line-height: 1.25rem;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    border-color: rgb(229 231 235 / 1);
    border-radius: 0.375rem;
    cursor: pointer;
}
.product-variations .list-box .box-disabled {
    margin-top: 10px;
    border: solid 1px;
    background-color: rgb(255 255 255 / 0.2);
    color: rgb(17 24 39 / 0.2);
    text-transform: uppercase;
    font-weight: 500;
    font-size: 15px;
    line-height: 1.25rem;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    border-color: rgb(229 231 235 /0.2);
    border-radius: 0.375rem;
    cursor: not-allowed;
}
.product-variations .list-box .box-selected {
    margin-top: 10px;
    border: solid 1px;
    background-color: #444444;
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    font-weight: 500;
    font-size: 15px;
    line-height: 1.25rem;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    border-color: rgb(229 231 235 / 1);
    border-radius: 0.375rem;
    cursor: pointer;
}

.product-variations .list-box .description-out-of-stock {
    border: solid 1px;
    background: lightgray;
    color: white;
    padding: 10px;
    margin-bottom: 5px;
    margin-right: 5px;
}

.product-variations .title-color-size {
    margin-top: 20px;
    font-size: 14px;

    font-weight: 500;
}

.product-variations .list-box .text-gray-900 {
    --tw-text-opacity: 1;
    color: rgb(17 24 39 /1);
}
.product-variations .list-box .uppercase {
    text-transform: uppercase;
}
.product-variations .list-box .font-medium {
    font-weight: 500;
}
.product-variations .list-box .text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.product-variations .list-box .py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}
.product-variations .list-box .px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}
.product-variations .list-box .bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / 1);
}
.product-variations .list-box .border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / 1);
}
.product-variations .list-box .border {
    border-width: 1px;
}
.product-variations .list-box .rounded-md {
    border-radius: 0.375rem;
}
.product-variations .list-box .justify-center {
    justify-content: center;
}
.product-variations .list-box .items-center {
    align-items: center;
}
.product-variations .list-box .cursor-pointer {
    cursor: pointer;
}
.product-variations .list-box .flex {
    display: flex;
}
.product-variations .list-box .description-off {
    border: solid 1px;
    background: white;
    padding: 10px;
    margin-bottom: 5px;
    margin-right: 5px;
}

.product-variations .item .description {
    padding-left: 23px;

    font-size: 16px;
}
.product-variations .quantity {
    display: flex;
    padding-right: 23px;
}

.product-variations .quantity .value {
    font-size: 22px;
    padding-right: 15px;
    padding-left: 15px;
}
.product-variations .item .button {
    font-size: 25px;
    color: red;
    font-weight: 600;
    cursor: pointer;
}

.product-variations .list-radio {
    margin-top: 20px;
    margin-left: 23px;
}

.product-variations .list-radio .label {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.product-variations .list-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.product-variations .list-radio .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
}

/* When the radio button is checked, add a blue background */
.product-variations .list-radio input:checked ~ .checkmark {
    background-color: black;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.product-variations .list-radio .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.product-variations .list-radio input:checked ~ .checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.product-variations .list-radio .checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}

.product-minimalist-button {
    grid-area: product-minimalist-button;
    display: flex;
    margin-top: 40px;
    margin-bottom: 40px;
    font-family: "Bebas Neue", cursive;
    font-size: 15px;
    color: rgba(255, 255, 255, 1);
    color: rgba(var(--dynamic-text-color), 1);
    letter-spacing: 0.1em;
    align-items: center;
    justify-content: center;
}

.product-minimalist-button .in-stock {
    background-color: rgba(34, 34, 34, 1);
    background-color: rgba(var(--primary-color), 1);
    height: 58px;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
    /*background: linear-gradient(to top, #444444 0%, rgba(0, 0, 0, 0.6) 100%);*/
    cursor: pointer;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
}

.product-minimalist-button .out-of-stock {
    height: 58px;
    border: 1px solid lightgray;
    color: gray;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
}

.product-minimalist-button .in-stock:active {
    transform: translateY(4px);
    background: gray;
}

.product-minimalist-description .product-minimalist-name {
    font-family: "Playfair Display", serif;
    line-height: 47px;
    font-size: 28px;
    color: #222222;
}
.product-minimalist-description .product-minimalist-price {
    font-family: "Bebas Neue", cursive;
    font-size: 20px;
    color: #888888;
    margin-top: 10px;
    letter-spacing: 0.05em;
    margin-left: 5px;
}
.product-minimalist-description .product-minimalist-price-line {
    font-family: "Bebas Neue", cursive;
    font-size: 20px;
    color: #888888;
    margin-top: 10px;

    text-decoration: line-through;
}
.product-minimalist-description .product-minimalist-promoprice {
    font-family: "Bebas Neue", cursive;
    font-size: 20px;
    color: rgba(34, 34, 34, 1);
    color: rgba(var(--primary-color), 1);
    margin-top: 10px;
    letter-spacing: 0.05em;
    margin-left: 15px;
}
.product-minimalist-container-price {
    display: flex;
}
.product-grid-minimalist-container-price {
    display: flex;
    align-items: center;
    justify-content: center;
}
.product-minimalist-description .product-minimalist-details {
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
    line-height: 21px;
    color: #222222;
    margin-top: 10px;
    letter-spacing: 0.05em;
    word-break: break-word;
}

.product-minimalist-recomendations {
    grid-area: product-minimalist-recomendations;
    font-size: 14px;
}
.product-minimalist-recomendations h3 {
    font-size: 14px;
    font-weight: bold;
}
.product-minimalist-recomendations .images {
    display: flex;
    flex: 1 1;
    justify-content: space-between;
}
.product-minimalist-recomendations img {
    width: 90px;
    height: 120px;
    object-fit: contain;
    margin: 2px;
    margin-bottom: 30px;
}
.blank {
    grid-area: blank;
    display: flex;
    align-items: center;
    justify-content: center;
}

.blank span {
    font-weight: bold;
}

.blank p {
    font-family: "Montserrat", sans-serif;
    font-size: 11px;
    color: #707070;
    line-height: 2px;
}

.blank a {
    text-decoration: none;
}
.blank i {
    color: #707070;
    font-size: 20px;
    margin-right: 10px;
}

.blank-minimalist-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-minimalist {
    grid-area: header-minimalist;
    display: flex;
    align-items: center;
    background-color: white;
    position: fixed;
    height: 70px;
    width: 100%;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
    z-index: 100;
}
.container-header-minimalist {
    display: flex;
    flex: 1 1;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-end;
}

.banner-minimalist {
    grid-area: banner-minimalist;
}

.product-grid-minimalist {
    grid-area: product-grid-minimalist;
    margin-bottom: 30px;
}

.line {
    grid-area: line;
}

.footer-minimalist {
    grid-area: footer-minimalist;
}

.copyright-minimalist {
    grid-area: copyright-minimalist;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #e3e3e3;
    padding: 10px;
    line-height: 10px;
}
.copyright-minimalist img {
    width: 63px;
    height: 22px;
    margin-bottom: 5px;
}
.copyright-minimalist span {
    color: #9b9b9b;
    font-size: 9px;
}

.title-minimalist {
    font-family: "Bebas Neue", cursive;
    font-size: 18px;
    color: #222222;
}
.menu-minimalist img {
    width: 20px;
    height: 14px;
    margin-left: 21px;
}
.back-minimalist img {
    width: 20px;
    height: 20px;
    margin-left: 21px;
}
.cart-minimalist {
    margin-right: 21px;
    margin-bottom: 4px;
}
.cart-minimalist img {
    width: 20px;
    height: 21px;
}

.selectall-minimalist img {
    width: 22px;
    height: 18px;
    margin-right: 21px;
}

.product-grid-minimalist {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    grid-column-gap: 13px;
}
.product-grid-container-minimalist {
    position: relative;
    margin-top: 30px;
}
.product-grid-minimalist img {
    width: 100%;
    object-fit: cover;
}

.product-grid-minimalist-title {
    font-family: "Playfair Display", serif;
    line-height: 24px;
    font-size: 18px;
    align-items: center;
    color: rgba(34, 34, 34, 1);
    margin-top: 15px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
}

.product-grid-minimalist-price {
    font-family: "Bebas Neue", cursive;
    font-size: 13px;
    color: #999999;
    margin-top: 10px;
    text-align: center;
    letter-spacing: 0.05em;
}

.product-grid-minimalist-promoprice {
    font-family: "Bebas Neue", cursive;
    font-size: 13px;
    color: rgba(34, 34, 34, 1);
    color: rgba(var(--primary-color), 1);
    margin-top: 10px;
    text-align: center;
    margin-left: 10px;
    letter-spacing: 0.05em;
}

.product-grid-minimalist-price-line {
    font-family: "Bebas Neue", cursive;
    font-size: 13px;
    color: #999999;
    margin-top: 10px;
    text-align: center;
    text-decoration: line-through;
}

.banner-container-minimalist {
    position: relative;
}

.banner-container-minimalist:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.6) 100%);
}

.banner-minimalist-title {
    position: absolute;
    bottom: 65px;
    left: 21px;
    z-index: 1;
    color: white;
    font-family: "Playfair Display", serif;
    line-height: 30px;
    font-size: 28px;
    padding-right: 120px;
}

.banner-minimalist-price {
    position: absolute;
    bottom: 30px;
    left: 25px;
    text-align: center;
    font-size: 30px;
    z-index: 1;
    color: white;
    font-family: "Bebas Neue", cursive;
    font-size: 16px;
    letter-spacing: 0.05em;
}

.banner-minimalist-promoprice {
    position: absolute;
    bottom: 10px;
    left: 25px;
    text-align: center;

    z-index: 1;
    color: white;
    font-family: "Bebas Neue", cursive;
    font-size: 22px;
    letter-spacing: 0.05em;
}
.banner-minimalist-price-line {
    position: absolute;
    bottom: 35px;
    left: 25px;
    text-align: center;
    font-size: 33px;
    z-index: 1;
    color: lightgray;
    font-family: "Bebas Neue", cursive;
    font-size: 16px;
    text-decoration: line-through;
}

.banner-minimalist-add {
    position: absolute;
    width: 40px;
    height: 40px;
    bottom: 40px;
    right: 21px;
    background-color: #fff;
    color: #fff;
    border-radius: 20px;
    text-align: center;
    font-size: 30px;
    box-shadow: 0px 15px 5px 0px rgba(0, 0, 0, 0.1);
    z-index: 1;
}

.card-minimalist {
    margin-top: 30px;
    width: 100%;
    height: 200px;
    background-color: #fff;
    box-shadow: 0px 15px 50px 0px rgba(0, 0, 0, 0.1);
}

.card-minimalist-text {
    font-family: "Playfair Display", serif;
    font-size: 13px;
    color: #707070;
    padding: 30px;
}

.footer-minimalist-title {
    margin-top: 30px;
    font-family: "Playfair Display", serif;
    font-size: 33px;
    color: #222222;
}
.footer-minimalist-logo {
    display: flex;
    margin-top: 30px;
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
    color: #707070;
    padding: 30px 15px;
}

.footer-minimalist-logo img {
    width: 80px;
    height: 80px;
    border-radius: 40px;
}
.footer-minimalist-logo li {
    list-style: none;
    margin-left: 30px;
}
.footer-minimalist-logo li ul {
    margin: 0;
    padding: 0.4em;
    font-size: 12px;
    word-break: break-word;
}
.footer-minimalist-logo li ul img {
    width: 15px;
    height: 15px;
}

@media screen and (min-width: 480px) {
    #minimalist-wrap {
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr 600px 1fr;
        grid-template-rows: 70px 70px -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content;
        grid-template-rows: 70px 70px min-content min-content min-content min-content min-content min-content min-content;
        grid-template-areas:
            ". header-minimalist ."
            "blank blank blank"
            ". free-shipping ."
            ". promo-banner ."
            ". banner-minimalist ."
            ". product-anchor-area ."
            ". search-bar ."
            ". product-grid-minimalist ."
            "load-more-button load-more-button load-more-button"
            ". rating-minimalist ."
            ". line ."
            ". social-media ."
            ". footer-minimalist ."
            "copyright-minimalist copyright-minimalist copyright-minimalist";
    }
    #minimalist-categories-wrap {
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr 600px 1fr;
        grid-template-rows: 45px -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content;
        grid-template-rows: 45px min-content min-content min-content min-content min-content min-content min-content min-content;
        grid-template-areas:
            ". header-minimalist ."
            ". blank ."
            ". promo-banner ."
            "minimalist-categories-title minimalist-categories-title minimalist-categories-title"
            ". search-bar ."
            ". product-grid-minimalist ."
            ". load-more-button ."
            ". footer-minimalist ."
            ". social-media .";
    }
    #product-minimalist-wrap {
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr 600px 1fr;
        grid-template-rows: -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content;
        grid-template-rows: min-content min-content min-content min-content min-content min-content min-content;
        grid-template-areas:
            ". product-minimalist-picture ."
            ". product-minimalist-description ."
            ". product-variations ."
            ". product-minimalist-button ."
            ". product-minimalist-recomendations ."
            ". product-share ."
            "copyright-minimalist copyright-minimalist copyright-minimalist";
    }
    #cart-minimalist-wrap {
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr 600px 1fr;
        grid-template-rows: 70px -webkit-min-content -webkit-min-content -webkit-min-content 200px -webkit-min-content;
        grid-template-rows: 70px min-content min-content min-content 200px min-content;
        grid-template-areas:
            "cart-header-minimalist cart-header-minimalist cart-header-minimalist"
            ". cart-items-minimalist ."
            ". cart-shipping-minimalist ."
            ". cart-coupon ."
            ". cart-total-minimalist ."
            ". cart-button-minimalist ."
            ". cart-blank-minimalist ."
            "copyright-minimalist copyright-minimalist copyright-minimalist";
    }

    .cart-minimalist {
        margin-right: 420px;
        margin-bottom: 4px;
    }

    .menu-minimalist img {
        width: 20px;
        height: 14px;
        margin-left: 420px;
    }
    .back-minimalist img {
        width: 20px;
        height: 20px;
        margin-left: 420px;
    }

    .selectall-minimalist img {
        width: 22px;
        height: 18px;
        margin-right: 420px;
    }

    .cart-blank-minimalist {
        grid-area: cart-blank-minimalist;
    }

    .empty-cart-image {
        width: 30%;
    }
    .empty-cart-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .product-minimalist-recomendations img {
        width: 150px;
        object-fit: contain;
        margin: 2px;
        margin-bottom: 30px;
        height: 100%;
    }
}

#dark-wrap {
    height: 100vh;
    display: grid;
    grid-template-columns: 21px 1fr 21px;
    grid-template-rows: 40px -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content;
    grid-template-rows: 40px min-content min-content min-content min-content min-content min-content min-content;
    grid-template-areas:
        "header-dark header-dark header-dark"
        "blank blank blank"
        "promo-banner promo-banner promo-banner"
        "featured-dark featured-dark featured-dark"
        ". grid-categories ."
        "top-sellers top-sellers top-sellers"
        "rating-dark rating-dark rating-dark"
        "footer-dark footer-dark footer-dark"
        "social-media-dark social-media-dark social-media-dark";
}

#dark-categories-wrap {
    height: 100vh;
    display: grid;
    grid-template-columns: 21px 1fr 21px;
    grid-template-rows: 40px -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content;
    grid-template-rows: 40px min-content min-content min-content min-content min-content min-content min-content min-content;
    grid-template-areas:
        "header-dark header-dark header-dark"
        "blank blank blank"
        "dark-categories-title dark-categories-title dark-categories-title"
        "promo-banner promo-banner promo-banner"
        "search-bar search-bar search-bar"
        "product-grid-dark product-grid-dark product-grid-dark"
        "load-more-button-dark load-more-button-dark load-more-button-dark"
        "footer-dark footer-dark footer-dark"
        "social-media-dark social-media-dark social-media-dark";
}

.promo-banner {
    grid-area: promo-banner;
    margin-bottom: 10px;
}

.loading-area {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
}

.search-bar {
    grid-area: search-bar;
}

.search-input {
    margin-top: 20px;
    height: 52px;
    position: relative;
}

.search-input::before {
    content: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAAAXNSR0IArs4c6QAAAQJJREFUKBWVkr2uQUEUhf3ET6GRaC5aFRoJKrf1BKpb8SwqovYGXkCj00k0QnRKEYkILYobvpUYmeMMyVnJl7P3mjN7Zu9zwiGv2qRFyMMSRrAFp6JPN8XzBj+wgDkUYAg7WINTYdwpDECxrRLJHeq2accdkgm8bzTvNAg2EDOGeUYI1KNO1gkuzTA1g8T7ojbn4ONQWPuHPWgeHmnzCqoe15tkSNPgPEAn68oVcOmA2XMtGK9FoE/VhOTTVNExqLCGZnxCv2pYauEC6lF0oQxX6IOvb7yX9NPEQafan+aPXDdQC18LsO6Tip5BBY6gIQaSbnMCFRCBZRcIvFkbsvCr4AFGOCxQy+JdGQAAAABJRU5ErkJggg==");
    display: block;
    position: absolute;
    width: 15px;
    z-index: 3;
    height: 15px;
    font-size: 0px;
    top: 9px;
    left: 7px;
    line-height: 32px;
    opacity: 0.6;
}

.search-input > input {
    width: 100%;
    font-size: 18px;
    border: none;
    line-height: 22px;
    padding: 5px 10px 5px 25px;
    height: 32px;
    position: relative;
    border: solid 1px rgb(242, 242, 242);
}

.search-input > input:focus {
    outline: none;
}

.rating-dark {
    grid-area: rating-dark;
}

.rating-dark .rating-wrap {
    color: #ffffff;
    margin-top: 10px;
    font-family: "Roboto", sans-serif;
    font-size: 15px;

    padding-left: 30px;
    padding-right: 30px;
}

.rating-dark .rating-wrap .title {
    margin-top: 30px;
    font-size: 19px;
    margin-bottom: 20px;
}

.rating-dark .first-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.rating-dark .rating-user {
    font-style: italic;
}

.rating-dark .rating-title {
    font-size: 18px;
}

.rating-dark .second-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.rating-dark .rating-review {
    margin-top: 15px;
    text-align: justify;
    margin-bottom: 15px;
}

.rating-dark .separator {
    width: "100%";
    height: 2px;
    background-color: gray;
    margin-top: 40px;
}

.back-dark i {
    font-size: 18px;
    margin-left: 21px;
    color: white;
}
.back-group i {
    font-size: 18px;
    margin-left: 21px;
    color: white;
}
.dark-categories-title {
    grid-area: dark-categories-title;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 0px;
    font-weight: 200;
    color: white;
    font-size: 30px;
    font-family: "Dosis", sans-serif;
}

.load-more-button-dark {
    grid-area: load-more-button-dark;
    display: flex;
    text-align: center;
    padding: 10px;
    border: 1px solid white;
    color: white;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    cursor: pointer;
}

.grid-categories {
    grid-area: grid-categories;
}

.grid-categories .category {
    text-align: center;
    margin-top: 15px;
    color: white;
    font-family: "Dosis", sans-serif;
}

.grid-categories .title {
    text-align: center;

    margin-bottom: 40px;
    font-weight: 200;
    color: white;
    font-size: 30px;
    font-family: "Dosis", sans-serif;
}

.grid-categories .title-group {
    text-align: center;
    font-weight: 200;
    color: white;
    font-size: 30px;
    font-family: "Dosis", sans-serif;
}

.featured-dark {
    grid-area: featured-dark;
}

.promo-banner-container {
    cursor: pointer;
}

.image-dark-button {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: white;
    color: black;
    border: 1px solid #999999;
    font-size: 16px;
    padding: 12px 24px;

    cursor: pointer;
    font-family: "Dosis", sans-serif;
}

.social-media-dark {
    grid-area: social-media-dark;
}

.social-media-container {
    display: flex;
    margin-top: 20px;
    align-items: center;
    padding-left: 30px;
    margin-bottom: 30px;
}

.social-media-dark .title {
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    color: #424242;
    margin-right: 10px;
}

.social-media-dark .icon {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    margin-right: 10px;
}

.social-media-dark i {
    color: #424242;
    font-size: 20px;
}

#product-dark-wrap {
    height: 100vh;
    display: grid;
    grid-template-columns: 21px 1fr 21px;
    grid-template-rows: -webkit-min-content -webkit-min-content -webkit-min-content 200px;
    grid-template-rows: min-content min-content min-content 200px;
    grid-template-areas:
        "product-dark-picture product-dark-picture product-dark-picture"
        ". product-dark-variations ."
        ". product-dark-description ."
        "product-dark-button product-dark-button product-dark-button";
}

.product-dark-picture {
    grid-area: product-dark-picture;
    margin-top: 60px;
}
.product-dark-description {
    grid-area: product-dark-description;
    margin-top: 35px;
}

.product-dark-variations {
    grid-area: product-dark-variations;
}

.product-dark-variations .product-variations .quantity .value {
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    padding-right: 15px;
    padding-left: 15px;
    color: #ffffff;
    padding-top: 5px;
}
.product-dark-variations .product-variations .title {
    background-color: #888888;
    color: #ffffff;
    font-size: 15px;
    padding: 20px;
}

.product-dark-variations .title-color-size {
    color: #ffffff;
    margin-top: 20px;
    font-size: 14px;
    font-weight: 500;
}

.product-dark-variations .product-variations .list-radio .label {
    display: block;
    color: #ffffff;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 15px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.product-dark-variations .product-variations .list-radio .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #eee;
    border-radius: 50%;
}

.product-dark-variations .product-variations .list-box .box-selected {
    margin-top: 10px;
    border: solid 1px;
    background-color: #000000;
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    font-weight: 500;
    font-size: 15px;
    line-height: 1.25rem;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    border-color: rgb(229 231 235 / 1);
    border-radius: 0.375rem;
    cursor: pointer;
}

.product-dark-variations .product-variations .list-box .box-disabled {
    margin-top: 10px;
    border: solid 1px;
    background-color: rgb(66, 66, 66);
    color: gray;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 15px;
    line-height: 1.25rem;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    border-color: gray;
    border-radius: 0.375rem;
    cursor: not-allowed;
}

.product-dark-variations .product-variations .list-radio .checkmark:after {
    top: 3px;
    left: 2.8px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: white;
}

.product-dark-variations .product-variations .item .description {
    padding-left: 23px;
    color: #ffffff;
    font-size: 15px;
    font-family: "Roboto", sans-serif;
}

.product-dark-variations .product-variations .item .button {
    font-size: 25px;
    color: #18dbbe;
    font-weight: 600;
    cursor: pointer;
}

.product-dark-button {
    grid-area: product-dark-button;
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    font-family: "Dosis", sans-serif;
    font-size: 15px;
    color: rgba(var(--dynamic-text-color), 1);
    letter-spacing: 0.1em;
    align-items: center;
    justify-content: center;
}
.product-dark-button .in-stock {
    background-color: rgba(var(--primary-color), 1);
    height: 58px;
    cursor: pointer;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
}

.product-dark-button .out-of-stock {
    height: 58px;
    border: 1px solid #fff;
    color: #fff;
    width: 80%;
    align-items: center;
    justify-content: center;
    display: flex;
}

.product-dark-button .in-stock:active {
    transform: translateY(4px);
    background: gray;
}

.product-dark-description .product-dark-name {
    font-family: "Roboto", sans-serif;
    line-height: 47px;
    font-size: 20px;
    color: #fff;
}
.product-dark-description .product-dark-price {
    font-family: "Oswald", sans-serif;
    font-size: 14px;
    color: #fff;
    margin-top: 10px;
    letter-spacing: 0.05em;
    margin-left: 5px;
}
.product-dark-description .product-dark-price-line {
    font-family: "Oswald", sans-serif;
    font-size: 14px;
    color: #888888;
    margin-top: 10px;

    text-decoration: line-through;
}
.product-dark-description .product-dark-promoprice {
    font-family: "Oswald", sans-serif;
    font-size: 14px;
    color: rgba(var(--primary-color), 1);
    margin-top: 10px;
    letter-spacing: 0.05em;
    margin-left: 15px;
}
.product-dark-container-price {
    display: flex;
}
.product-grid-dark-container-price {
    display: flex;
    align-items: center;
    justify-content: center;
}
.product-dark-description .product-dark-details {
    font-family: "Roboto", sans-serif;
    font-size: 13px;
    line-height: 15px;

    color: #ffffff;
    margin-top: 10px;
    letter-spacing: 0.05em;
    word-break: break-word;
}

.product-dark-description .product-dark-stock {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: #ffffff;
    margin-top: 10px;
}

.grid-dark-categories {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-column-gap: 21px;
    grid-row-gap: 21px;
    row-gap: 21px;
}

.grid-dark-categories .title {
    text-align: center;
    margin-top: 15px;
    color: white;
    font-family: "Dosis", sans-serif;
}

.product-dark-description .product-dark-title-details {
    font-family: "Roboto", sans-serif;
    font-size: 13px;
    line-height: 15px;

    color: #888888;
    margin-top: 30px;
}

.product-dark-recomendations {
    grid-area: product-dark-recomendations;
    overflow: auto;
    white-space: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
    margin-left: 20px;
}

.product-dark-recomendations h3 {
    font-size: 14px;
    font-weight: bold;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
    vertical-align: middle;
    width: 100%;
    line-height: 0;
    height: 80px !important;
    object-fit: cover !important;
}

.dark-back {
    font-size: 20px;
    color: white;
    text-shadow: 1px 2px 4px #000000;
    cursor: pointer;
}

.product-dark-recomendations img {
    width: 90px;
    height: 120px;
    object-fit: contain;
    margin: 2px;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}
.top-sellers {
    grid-area: top-sellers;
    margin-bottom: 80px;
}

.top-sellers .title {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
    font-weight: 200;
    color: white;
    font-size: 30px;
    font-family: "Dosis", sans-serif;
}

.header-dark {
    grid-area: header-dark;
    display: flex;
    align-items: center;
    position: fixed;
    height: 60px;
    width: 100%;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
    z-index: 100;
    justify-content: center;
    background-color: #424242;
}

.container-header-dark {
    display: flex;
    flex: 1 1;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    background-color: #424242;
}

.container-header-dark .zap {
    margin-left: 21px;
    font-size: 24px;
    cursor: pointer;
}

.container-header-dark .zap .link {
    color: white;
    text-decoration: none;
}

.product-grid-dark {
    grid-area: product-grid-dark;
    margin-bottom: 30px;
}

.line {
    grid-area: line;
}

.footer-dark {
    grid-area: footer-dark;
}

.footer-dark .separator {
    width: "100%";

    height: 2px;
    background-color: gray;
    margin-top: 40px;
}

.title-dark {
    font-family: "Dosis", sans-serif;
    font-size: 18px;
    color: #ffffff;

    text-transform: uppercase;
    word-wrap: break-word;
    text-align: center;
}

.cart-dark {
    margin-right: 21px;
    margin-bottom: 4px;
}
.cart-dark img {
    width: 18px;
    height: 17px;
}

.product-grid-dark {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    grid-column-gap: 13px;
}
.product-grid-container-dark {
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;
}

.product-grid-dark img {
    width: 100%;
    object-fit: cover;
    margin-top: 10px;
}

.product-grid-dark-title {
    font-family: "Roboto", sans-serif;
    line-height: 24px;
    font-size: 14px;
    align-items: center;
    color: #ffffff;
    margin-top: 15px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    text-transform: uppercase;
}

.product-grid-dark-sale {
    position: absolute;
    color: white;
    border: 1px solid white;
    top: 10px;
    right: 0;
    background-color: gray;
    padding: 5px;
    font-size: 13px;
}

.badge-dark {
    margin-right: 10px;
}

.badge-dark .MuiBadge-colorSecondary {
    background-color: rgba(var(--primary-color), 1);
    color: rgba(var(--dynamic-text-color), 1);
}

.badge-dark .icon {
    background-color: "white";
    color: white;
    font-size: 20px;
}

.product-grid-dark-price {
    font-family: "Oswald", sans-serif;
    font-size: 13px;
    color: #ffffff;
    margin-top: 5px;
    text-align: center;
}

.product-grid-dark-promoprice {
    font-family: "Oswald", sans-serif;
    font-size: 13px;
    color: rgba(var(--primary-color), 1);
    margin-top: 5px;
    text-align: center;
    margin-left: 10px;
    letter-spacing: 0.05em;
}

.product-grid-dark-price-line {
    font-family: "Oswald", sans-serif;
    font-size: 13px;
    color: #999999;
    margin-top: 5px;
    text-align: center;
    text-decoration: line-through;
}

.footer-dark-about-title {
    margin-top: 30px;
    font-family: "Roboto", sans-serif;
    font-size: 19px;
    color: white;
    padding-left: 30px;
    padding-right: 30px;
}

.footer-dark-about-text {
    margin-top: 10px;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    color: #ffffff;
    padding-left: 30px;
    padding-right: 30px;
}

.empty-cart-image {
    width: 40%;
    margin-top: 60px;
}

.empty-cart-container {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.empty-cart-container .title {
    font-family: "Montserrat", sans-serif;
    color: #f1456f;
    margin-top: 10px;
}
.empty-cart-container .subtitle {
    font-family: "Montserrat", sans-serif;
    color: gray;
    font-size: 12px;
}

#cart-dark-wrap {
    height: 100vh;
    display: grid;
    grid-template-columns: 21px 1fr 21px;
    grid-template-rows: 70px -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content 1fr -webkit-min-content;
    grid-template-rows: 70px min-content min-content min-content min-content 1fr min-content;
    grid-template-areas:
        "cart-header-dark cart-header-dark cart-header-dark"
        ". cart-items-dark ."
        ". cart-shipping-dark ."
        ". cart-coupon-dark ."
        ". cart-total-dark ."
        ". cart-button-dark .";
}

.cart-shipping-dark {
    grid-area: cart-shipping-dark;
}

.cart-shipping-dark .cart-shipping {
    display: flex;
    margin-top: 25px;
    background-color: white;
    padding: 15px;
    border-radius: 10px;
    justify-content: space-between;
    display: flex;
    margin-top: 20px;
}

.cart-shipping-dark .cart-shipping .free-shipping {
    font-family: "Roboto", sans-serif;
    justify-content: center;
    font-size: 12px;
    color: rgb(66, 66, 66);
    flex: 1 1;
    display: flex;
    align-items: center;
}

.cart-shipping-dark .cart-shipping .MuiFormLabel-root.Mui-focused {
    color: #18dbbe !important;
}

.cart-shipping-dark
    .cart-shipping
    .MuiOutlinedInput-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border-color: #18dbbe !important;
    border-width: 2px;
}

.cart-shipping .apply-button {
    position: relative;
    border: solid #000000 1px;
    border-radius: 5px;
    margin: 0px;
    font-size: 0.1em;
    width: 50px;
    height: 19px;
    padding-top: 13px;
    justify-content: center;
    align-items: center;
    flex: 0.2 1;
    padding-bottom: 6px;
    margin-left: 5px;
    cursor: pointer;
    text-align: center;
}

.cart-shipping-dark .cart-shipping-results {
    display: flex;
    background-color: white;
    padding: 5px;
    border-radius: 0 0 10px 10px;
    justify-content: space-between;
    display: flex;
    margin-top: -10px;
    margin-bottom: 10px;
}

.cart-shipping-dark .cart-shipping-results .MuiRadio-colorSecondary.Mui-checked {
    color: #18dbbe !important;
}
.cart-shipping-dark .cart-shipping-results .MuiFormGroup-root {
    margin-left: 20px !important;
}

.cart-coupon-dark {
    grid-area: cart-coupon-dark;
}

.cart-coupon-dark .cart-coupon {
    display: flex;
    margin-top: 25px;
    background-color: white;
    padding: 15px;
    border-radius: 10px;
    justify-content: space-between;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
}

.cart-coupon-dark .cart-coupon .MuiFormLabel-root.Mui-focused {
    color: #18dbbe !important;
}

.cart-coupon-dark
    .cart-coupon
    .MuiOutlinedInput-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border-color: #18dbbe !important;
    border-width: 2px;
}

.cart-coupon-dark .cart-coupon .apply-button {
    border: solid #000000 1px;
    border-radius: 5px;
    margin: 0px;
    font-size: 0.1em;
    width: 50px;
    height: 19px;
    padding-top: 13px;
    justify-content: center;
    align-items: center;
    flex: 0.2 1;
    padding-bottom: 6px;
    margin-left: 5px;
    cursor: pointer;
    text-align: center;
}
.cart-header-dark {
    grid-area: cart-header-dark;
    display: flex;
    align-items: center;
    background-color: white;
    top: 0;
    position: fixed;
    height: 70px;
    width: 100%;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
    z-index: 100;
}
.cart-items-dark {
    grid-area: cart-items-dark;
}

.cart-items-dark .item {
    display: flex;
    margin-top: 25px;
    background-color: white;
    padding: 15px;
    border-radius: 10px;
    justify-content: space-between;
}

.cart-items-dark .item .item-dark {
    margin-left: 30px;
    margin-top: 30px;
}

.cart-items-dark .item-description {
    width: 50%;
    margin-left: 30px;
}
.cart-items-dark .item-picture {
    width: 30%;
}

.cart-items-dark .item-picture-image {
    width: 90px;
    height: 90px;
    object-fit: cover;
}

.cart-items-dark .item-name {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: #222222;
}
.cart-items-dark .item-variation {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    color: #888888;
}

.cart-items-dark .item-price {
    margin-top: 15px;
    margin-left: 3px;
    font-family: "Oswald", sans-serif;
    font-size: 18px;
    color: #888888;
    letter-spacing: 0.05em;
}

.cart-items-dark .item-price-line {
    margin-top: 15px;
    margin-left: 3px;
    font-family: "Oswald", sans-serif;
    font-size: 18px;
    color: #888888;

    text-decoration: line-through;
}

.cart-items-dark .item-promoprice {
    margin-top: 5px;
    font-family: "Oswald", sans-serif;
    font-size: 18px;
    color: black;
    letter-spacing: 0.05em;
}

.cart-items-dark .item-quantity {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    width: 20%;
    align-items: center;
}

.cart-items-dark .item .item-quantity .minus {
    font-size: 40px;
    color: lightgray;
    display: flex; /* or inline-flex */
    align-items: center;
    justify-content: center;
    font-family: "Dosis", sans-serif;
    height: 0px;
    cursor: pointer;
}

.cart-items-dark .item .item-quantity .qty {
    border: 1px solid rgba(var(--primary-color), 1);
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: rgba(var(--primary-color), 1);
    color: rgba(var(--dynamic-text-color), 1);
    display: flex; /* or inline-flex */
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
}
.cart-items-dark .item .item-quantity .add {
    font-size: 40px;
    color: lightgray;
    display: flex; /* or inline-flex */
    align-items: center;
    justify-content: center;
    font-family: "Dosis", sans-serif;
    height: 15px;
    cursor: pointer;
    margin-bottom: 5px;
}

.cart-total-dark {
    grid-area: cart-total-dark;
    display: flex;
    margin-top: 25px;
    background-color: white;
    padding: 15px;
    border-radius: 10px;
    justify-content: space-between;
    font-family: "Roboto", sans-serif;
}
.cart-button-dark {
    grid-area: cart-button-dark;
    margin-top: 40px;
    margin-bottom: 40px;
    background-color: rgba(var(--primary-color), 1);
    height: 58px;
    display: flex;
    cursor: pointer;
}

.cart-button-dark {
    font-family: "Dosis", sans-serif;
    font-size: 15px;
    color: rgba(var(--dynamic-text-color), 1);
    letter-spacing: 0.1em;
    align-items: center;
    justify-content: center;
}

@media screen and (min-width: 480px) {
    #dark-wrap {
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr 600px 1fr;
        grid-template-rows: 40px -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content;
        grid-template-rows: 40px min-content min-content min-content min-content min-content min-content min-content min-content;
        grid-template-areas:
            ". header-dark ."
            ". blank ."
            ". promo-banner ."
            ". featured-dark ."
            ". grid-categories ."
            ". top-sellers ."
            ". rating-dark ."
            ". footer-dark ."
            ". social-media-dark .";
    }

    #dark-categories-wrap {
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr 600px 1fr;
        grid-template-rows: 40px -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content;
        grid-template-rows: 40px min-content min-content min-content min-content min-content min-content min-content min-content;
        grid-template-areas:
            ". header-dark ."
            ". blank ."
            ". promo-banner ."
            "dark-categories-title dark-categories-title dark-categories-title"
            ". search-bar ."
            ". product-grid-dark ."
            ". load-more-button-dark ."
            ". footer-dark ."
            ". social-media-dark .";
    }

    #product-dark-wrap {
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr 600px 1fr;
        grid-template-rows: -webkit-min-content -webkit-min-content -webkit-min-content 200px;
        grid-template-rows: min-content min-content min-content 200px;
        grid-template-areas:
            ". product-dark-picture ."
            ". product-dark-variations ."
            ". product-dark-description ."
            ". product-dark-button .";
    }

    .product-dark-button .out-of-stock {
        height: 58px;
        border: 1px solid #fff;
        color: #fff;
        width: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    #cart-dark-wrap {
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr 600px 1fr;
        grid-template-rows: 70px -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content 1fr -webkit-min-content;
        grid-template-rows: 70px min-content min-content min-content min-content 1fr min-content;
        grid-template-areas:
            ". cart-header-dark ."
            ". cart-items-dark ."
            ". cart-shipping-dark ."
            ". cart-coupon-dark ."
            ". cart-total-dark ."
            ". cart-button-dark .";
    }

    .container-header-dark .zap .link {
        width: 20px;
        height: 14px;
        margin-left: 420px;
    }

    .dark-back {
        display: none;
    }
    .selectall-dark {
        margin-right: 420px;
    }

    .container-header-dark .badge-dark {
        margin-right: 420px;
    }

    .back-dark {
        margin-left: 420px;
    }

    .cart-shipping-dark .cart-shipping .free-shipping {
        font-family: "Roboto", sans-serif;
        justify-content: center;
        font-size: 15px;
        color: rgb(66, 66, 66);
        flex: 1 1;
        display: flex;
        align-items: center;
    }
}

.cart-fendi .MuiBadge-colorSecondary {
    background-color: #6e7075;
    color: #f9fafc;
}

.carousel-container {
    position: relative;
    max-width: 800px;
    margin: auto;
}
.carousel-position {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 18px;
    border-radius: 15px;
    font-size: 12px;
    border-color: gray;
    color: #ffffff;
    margin-right: 10px;
    margin-top: 10px;
    background-color: #504a4afc;
    text-align: center;
    padding-top: 4px;
}

.carousel-dotgroup {
    text-align: center;
    margin-top: 10px;
}

.carousel__dot--selected {
    background-color: gray;
}
.carousel-dotgroup button {
    width: 10px;
    padding: 0px;
    height: 10px;
    margin-right: 5px;
    border-radius: 5px;
}

#fendi-wrap {
    height: 100vh;
    display: grid;
    grid-template-columns: 21px 1fr 21px;
    grid-template-rows: 70px 30px -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content;
    grid-template-rows: 70px 30px min-content min-content min-content min-content min-content min-content min-content min-content min-content;
    grid-template-areas:
        "header-fendi header-fendi header-fendi"
        "announcement-fendi announcement-fendi announcement-fendi"
        "free-shipping free-shipping free-shipping"
        "banner-fendi banner-fendi banner-fendi"
        ". product-grid-fendi ."
        "load-more-button load-more-button load-more-button"
        ". rating-fendi ."
        ". line ."
        ". footer-fendi ."
        ". social-media ."
        "copyright-fendi copyright-fendi copyright-fendi";
}

.rating-fendi {
    grid-area: rating-fendi;
}

.rating-fendi .rating-wrap {
    margin-top: 30px;
    font-family: "Abhaya Libre", serif;
}

.rating-fendi .rating-content {
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 15px 50px 0px rgba(0, 0, 0, 0.1);
    font-size: 13px;
    color: #6e7075;
    padding-bottom: 20px;
    margin-top: 30px;
}

.rating-fendi .rating-wrap .title {
    margin-top: 30px;
    font-size: 30px;
    color: #373435;
}

.rating-fendi .first-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
}

.rating-fendi .rating-user {
    font-style: italic;
    padding-left: 30px;
}

.rating-fendi .rating-title {
    font-size: 15px;
}

.rating-fendi .second-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.rating-fendi .rating-review {
    margin-top: 15px;
    text-align: left;
    margin-bottom: 15px;
    padding-left: 30px;
    padding-right: 30px;
    text-align: left;
}

.rating-fendi .separator {
    width: "100%";
    height: 0px;
    background-color: gray;
    margin-top: 40px;
}

.free-shipping {
    grid-area: free-shipping;
}

.free-shipping .fendi {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Nunito Sans", sans-serif;
    font-size: 12px;
    color: #373435;
    background-color: #ffffff;
    padding: 6px;
}

.free-shipping .highlight {
    color: #6e7075;
}

.free-shipping .space {
    margin-left: 3px;
    margin-right: 3px;
}

.load-more-button {
    grid-area: load-more-button;
    display: flex;
    text-align: center;
    padding: 10px;
    border: 1px solid;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    cursor: pointer;
}

.social-media {
    grid-area: social-media;
}

.social-media-container {
    display: flex;
    margin-top: 30px;
    align-items: center;
}

.social-media .title {
    font-family: "Abhaya Libre", serif;
    font-size: 20px;
    color: #373435;
    margin-right: 10px;
}

.social-media .icon {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: #6e7075;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    margin-right: 10px;
}

.social-media i {
    color: white;
    font-size: 20px;
}

.drawer-title {
    margin-left: 30px;
    font-family: "Nunito Sans", sans-serif;
    font-size: 20px;
    color: #373435;
}

.drawer-items {
    margin-top: 30px;
    margin-left: 30px;
    font-family: "Abhaya Libre", serif;
    font-size: 28px;
    color: #888888;
    list-style-type: none;
}
.drawer-items li:not(:last-child) {
    margin-bottom: 10px;
}
.empty-cart-image {
    width: 40%;
    margin-top: 60px;
}

.empty-cart-container {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.empty-cart-container .title {
    font-family: "Nunito Sans", sans-serif;
    color: #f1456f;
    margin-top: 10px;
}
.empty-cart-container .subtitle {
    font-family: "Nunito Sans", sans-serif;
    color: gray;
    font-size: 12px;
}

#cart-fendi-wrap {
    height: 100vh;
    display: grid;
    grid-template-columns: 21px 1fr 21px;
    grid-template-rows: 70px -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content 1fr -webkit-min-content;
    grid-template-rows: 70px min-content min-content min-content min-content 1fr min-content;
    grid-template-areas:
        "cart-header-fendi cart-header-fendi cart-header-fendi"
        ". cart-items-fendi ."
        ". cart-shipping-fendi ."
        ". cart-coupon-fendi ."
        ". cart-total-fendi ."
        ". cart-button-fendi ."
        "copyright-fendi copyright-fendi copyright-fendi";
}
.cart-shipping-fendi {
    grid-area: cart-shipping-fendi;
}

.cart-shipping-fendi .cart-shipping {
    display: flex;
    margin-top: 25px;
    border-radius: 10px;
    justify-content: space-between;
    display: flex;
    margin-top: 20px;
}

.cart-shipping-fendi .cart-shipping .free-shipping {
    font-family: "Roboto", sans-serif;
    justify-content: center;
    font-size: 15px;
    color: rgb(66, 66, 66);
    flex: 1 1;
    display: flex;
    align-items: center;
}

.cart-shipping-fendi .cart-shipping .apply-button {
    position: relative;
    border: solid #373435 1px;
    border-radius: 5px;
    margin: 0px;
    font-size: 0.1em;
    width: 50px;
    height: 19px;
    padding-top: 13px;
    justify-content: center;
    align-items: center;
    flex: 0.2 1;
    padding-bottom: 6px;
    margin-left: 5px;
    cursor: pointer;
    text-align: center;
}

.cart-shipping-fendi .cart-shipping-results {
    display: flex;
    border: solid rgba(0, 0, 0, 0.23) 1px;
    padding: 5px;
    border-radius: 0 0 10px 10px;
    justify-content: space-between;
    display: flex;
    margin-top: 5px;
    margin-bottom: 10px;
}

.cart-shipping-fendi .cart-shipping-results .MuiFormGroup-root {
    margin-left: 20px !important;
}

.cart-coupon-fendi {
    grid-area: cart-coupon-fendi;
}

.cart-coupon-fendi .cart-coupon {
    display: flex;
    margin-top: 25px;
    padding: 15px;
    border-radius: 10px;
    justify-content: space-between;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
}

.cart-coupon-fendi .apply-button {
    border: solid #373435 1px;
    border-radius: 5px;
    margin: 0px;
    font-size: 0.1em;
    width: 50px;
    height: 19px;
    padding-top: 13px;
    justify-content: center;
    align-items: center;
    flex: 0.2 1;
    padding-bottom: 6px;
    margin-left: 5px;
    cursor: pointer;
    text-align: center;
}

.cart-coupon-fendi .cart-coupon .MuiFormLabel-root.Mui-focused {
    color: #373435 !important;
}

.cart-coupon-fendi
    .cart-coupon
    .MuiOutlinedInput-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border-color: #373435 !important;
    border-width: 2px;
}

.cart-header-fendi {
    grid-area: cart-header-fendi;
    display: flex;
    align-items: center;
    background-color: white;
    top: 0;
    position: fixed;
    height: 70px;
    width: 100%;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
    z-index: 100;
}
.cart-items-fendi {
    grid-area: cart-items-fendi;
}

.cart-items-fendi .item {
    display: flex;
    margin-top: 25px;
}

.cart-items-fendi .item .item-description {
    margin-left: 30px;
    margin-top: 30px;
}
.cart-items-fendi .item-picture-image {
    width: 130px;
    height: 190px;
    object-fit: cover;
}

.cart-items-fendi .item-name {
    font-family: "Abhaya Libre", serif;
    line-height: 25px;
    font-size: 22px;
    color: #373435;
}
.cart-items-fendi .item-variation {
    font-family: "Abhaya Libre", serif;
    color: #373435;
}
.cart-items-fendi .item-price {
    margin-top: 15px;
    margin-left: 3px;
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    color: #888888;
    letter-spacing: 0.05em;
}

.cart-items-fendi .item-price-line {
    margin-top: 15px;
    margin-left: 3px;
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    color: #888888;

    text-decoration: line-through;
}

.cart-items-fendi .item-promoprice {
    margin-top: 15px;
    margin-left: 10px;
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    color: #6e7075;
    letter-spacing: 0.05em;
}

.cart-items-fendi .item-quantity {
    display: flex;
    margin-top: 15px;
}

.cart-items-fendi .item .item-quantity .minus {
    border: 1px solid #cccccc;
    width: 28px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: -1px;
    background-color: #b4b3a0;
    box-sizing: unset;
    padding: 0;
}

.cart-items-fendi .item .item-quantity .qty {
    border: 1px solid #cccccc;
    width: 28px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #b4b3a0;
}
.cart-items-fendi .item .item-quantity .add {
    border: 1px solid #cccccc;
    width: 28px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -1px;
    background-color: #b4b3a0;
    box-sizing: unset;
    padding: 0;
}

.cart-total-fendi {
    grid-area: cart-total-fendi;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    font-family: "Abhaya Libre", serif;

    font-size: 20px;
    color: #373435;
}
.cart-button-fendi {
    grid-area: cart-button-fendi;
    margin-top: 40px;
    margin-bottom: 100px;
    background-color: #444444;
    height: 58px;
    display: flex;
    box-shadow: 0px 15px 15px 0px rgba(0, 0, 0, 0.1);
    background: linear-gradient(to top, #444444 0%, rgba(0, 0, 0, 0.6) 100%);
    font-family: "Nunito Sans", sans-serif;
    font-size: 15px;
    color: #fff;
    letter-spacing: 0.1em;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

#product-fendi-wrap {
    height: 100vh;
    display: grid;
    grid-template-columns: 21px 1fr 21px;
    grid-template-rows: -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content;
    grid-template-rows: min-content min-content min-content min-content min-content min-content min-content;
    grid-template-areas:
        "product-fendi-picture product-fendi-picture product-fendi-picture"
        ". product-fendi-description ."
        "product-variations product-variations product-variations"
        ". product-fendi-button ."
        ". product-fendi-recomendations ."
        "copyright-fendi copyright-fendi copyright-fendi";
}

.product-fendi-picture {
    grid-area: product-fendi-picture;
}
.product-fendi-description {
    grid-area: product-fendi-description;
    margin-top: 35px;
}
.product-variations {
    grid-area: product-variations;
}
.product-variations .title {
    background-color: #e6e6e6;
    padding: 24px;
    margin-top: 20px;

    font-size: 20px;
}
.product-variations .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Abhaya Libre";
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: solid 1px;
    border-bottom-color: #e6e6e6;
}

.product-variations .list-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.product-variations .list-box .description-on {
    border: solid 1px;
    background: black;
    color: white;
    padding: 10px;
    margin-bottom: 5px;
    margin-right: 5px;
}
.product-variations .list-box .description-off {
    border: solid 1px;
    background: white;
    padding: 10px;
    margin-bottom: 5px;
    margin-right: 5px;
}

.product-variations .item .description {
    padding-left: 23px;

    font-size: 16px;
}
.product-variations .quantity {
    display: flex;
    padding-right: 23px;
}

.product-variations .quantity .value {
    font-size: 22px;
    padding-right: 15px;
    padding-left: 15px;
}
.product-variations .item .button {
    font-size: 25px;
    color: red;
    font-weight: 600;
    cursor: pointer;
}

.product-variations .list-radio {
    margin-top: 20px;
    margin-left: 23px;
}

.product-variations .list-radio .label {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.product-variations .list-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.product-variations .list-radio .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
}

/* When the radio button is checked, add a blue background */
.product-variations .list-radio input:checked ~ .checkmark {
    background-color: black;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.product-variations .list-radio .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.product-variations .list-radio input:checked ~ .checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.product-variations .list-radio .checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}
.product-fendi-stock {
    display: none;
}

.product-fendi-button {
    grid-area: product-fendi-button;
    display: flex;
    margin-top: 40px;
    margin-bottom: 40px;
    font-family: "Nunito Sans", sans-serif;
    font-size: 15px;
    color: #fff;
    letter-spacing: 0.1em;
    align-items: center;
    justify-content: center;
}

.product-fendi-button .in-stock {
    background-color: #444444;
    height: 58px;
    box-shadow: 0px 15px 15px 0px rgba(0, 0, 0, 0.1);
    background: linear-gradient(to top, #444444 0%, rgba(0, 0, 0, 0.6) 100%);
    cursor: pointer;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
}

.product-fendi-button .out-of-stock {
    height: 58px;
    border: 1px solid lightgray;
    color: gray;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
}

.product-fendi-button .in-stock:active {
    transform: translateY(4px);
    background: gray;
}

.product-fendi-description .product-fendi-name {
    font-family: "Abhaya Libre", serif;
    line-height: 47px;
    font-size: 28px;
    color: #373435;
}
.product-fendi-description .product-fendi-price {
    font-family: "Nunito Sans", sans-serif;
    font-size: 20px;
    color: #888888;
    margin-top: 10px;
    letter-spacing: 0.05em;
    margin-left: 5px;
}
.product-fendi-description .product-fendi-price-line {
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    color: #6e7075;
    margin-top: 10px;
    text-decoration: line-through;
}
.product-fendi-description .product-fendi-promoprice {
    font-family: "Nunito Sans", sans-serif;
    font-size: 15px;
    color: #373435;
    margin-top: 10px;
    letter-spacing: 0;
    margin-left: 15px;
}
.product-fendi-container-price {
    display: flex;
}
.product-grid-fendi-container-price {
    display: flex;
    align-items: left;
    justify-content: flex-start;
    flex-direction: column;
}
.product-fendi-description .product-fendi-details {
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    line-height: 21px;
    color: #373435;
    margin-top: 10px;
    letter-spacing: 0;
    word-break: break-word;
}

.product-fendi-recomendations {
    grid-area: product-fendi-recomendations;
    font-size: 14px;
}
.product-fendi-recomendations h3 {
    font-size: 14px;
    font-weight: bold;
}
.product-fendi-recomendations .images {
    display: flex;
    flex: 1 1;
    justify-content: space-between;
}
.product-fendi-recomendations img {
    width: 90px;
    height: 120px;
    object-fit: cover;
    margin: 2px;
    margin-bottom: 30px;
}
.blank-fendi {
    grid-area: blank-fendi;
    display: none;
    align-items: center;
    justify-content: center;
    background-color: rgb(222, 222, 222);
}

.blank-fendi span {
    font-weight: bold;
}

.blank-fendi p {
    font-family: "Nunito Sans", sans-serif;
    font-size: 11px;
    color: #6e7075;
    line-height: 2px;
}

.blank-fendi a {
    text-decoration: none;
}
.blank-fendi i {
    color: #6e7075;
    font-size: 20px;
    margin-right: 10px;
}

.blank-fendi-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-fendi {
    grid-area: header-fendi;
    display: flex;
    align-items: center;
    background-color: #f9fafc;
    position: fixed;
    height: 70px;
    width: 100%;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
    z-index: 100;
}
.container-header-fendi {
    display: flex;
    flex: 1 1;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-end;
}

.banner-fendi {
    grid-area: banner-fendi;
}

.product-grid-fendi {
    grid-area: product-grid-fendi;
    margin-bottom: 30px;
}

.line {
    grid-area: line;
}

.footer-fendi {
    grid-area: footer-fendi;
}

.copyright-fendi {
    grid-area: copyright-fendi;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #e3e3e3;
    padding: 10px;
    line-height: 10px;
}
.copyright-fendi img {
    width: 63px;
    height: 22px;
    margin-bottom: 5px;
}
.copyright-fendi span {
    color: #9b9b9b;
    font-size: 9px;
}

.title-fendi {
    width: 100px;
    font-size: 0px;
    color: transparent;
    background-size: contain;
    background-repeat: no-repeat;
    height: 55px;
    background-position: center;
    background-image: url("https://res.cloudinary.com/rockedevelopr/image/upload/v1637193773/monicabernardes%40gmail.com/logotipo_simbolo_media_kc7srf.png");
}
.menu-fendi img {
    width: 20px;
    height: 14px;
    margin-left: 21px;
}
.back-fendi img {
    width: 20px;
    height: 20px;
    margin-left: 21px;
}
.cart-fendi {
    margin-right: 21px;
    margin-bottom: 4px;
}
.cart-fendi img {
    width: 20px;
    height: 21px;
}

.selectall-fendi img {
    width: 22px;
    height: 18px;
    margin-right: 21px;
}

.product-grid-fendi {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    grid-column-gap: 13px;
}
.product-grid-container-fendi {
    position: relative;
    margin-top: 30px;
}
.product-grid-fendi img {
    width: 100%;
    height: 233px;
    object-fit: cover;
}

.product-grid-fendi-title {
    font-family: "Abhaya Libre", serif;
    line-height: 24px;
    font-size: 22px;
    align-items: center;
    color: #373435;
    margin-top: 15px;
    text-align: left;
    padding-left: 0px;
    padding-right: 10px;
    font-weight: 600;
}

.product-grid-fendi-price {
    font-family: "Nunito Sans", sans-serif;
    font-size: 13px;
    color: #999999;
    margin-top: 10px;
    text-align: center;
    letter-spacing: 0.05em;
}

.product-grid-fendi-promoprice {
    font-family: "Nunito Sans", sans-serif;
    font-size: 13px;
    color: #373435;
    margin-top: 5px;
    text-align: left;
    margin-left: 0px;
    letter-spacing: 0;
    font-weight: 700;
}

.product-grid-fendi-price-line {
    font-family: "Nunito Sans", sans-serif;
    font-size: 13px;
    color: #999999;
    margin-top: 10px;
    text-align: left;
    text-decoration: line-through;
}

.banner-container-fendi {
    position: relative;
}

.banner-container-fendi:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.banner-fendi-title {
    position: absolute;
    bottom: 65px;
    left: 21px;
    z-index: 1;
    color: white;
    font-family: "Abhaya Libre", serif;
    line-height: 30px;
    font-size: 28px;
    padding-right: 120px;
}

.banner-fendi-price {
    position: absolute;
    bottom: 30px;
    left: 25px;
    text-align: center;
    font-size: 30px;
    z-index: 1;
    color: white;
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    letter-spacing: 0.05em;
}

.banner-fendi-promoprice {
    position: absolute;
    bottom: 10px;
    left: 25px;
    text-align: center;

    z-index: 1;
    color: white;
    font-family: "Nunito Sans", sans-serif;
    font-size: 22px;
    letter-spacing: 0.05em;
}
.banner-fendi-price-line {
    position: absolute;
    bottom: 35px;
    left: 25px;
    text-align: center;
    font-size: 33px;
    z-index: 1;
    color: lightgray;
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    text-decoration: line-through;
}

.banner-fendi-add {
    position: absolute;
    width: 40px;
    height: 40px;
    bottom: 40px;
    right: 21px;
    background-color: #fff;
    color: #fff;
    border-radius: 20px;
    text-align: center;
    font-size: 30px;
    box-shadow: 0px 15px 5px 0px rgba(0, 0, 0, 0.1);
    z-index: 1;
}

.card-fendi {
    margin-top: 30px;
    width: 100%;
    height: 200px;
    background-color: #d4d4d4;
    box-shadow: 0px 15px 50px 0px rgba(0, 0, 0, 0.1);
}

.card-fendi-text {
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    color: #6e7075;
    padding: 30px;
}

.footer-fendi-title {
    margin-top: 30px;
    font-family: "Abhaya Libre", serif;
    font-size: 33px;
    color: #373435;
}
.footer-fendi-logo {
    display: flex;
    margin-top: 30px;
    font-family: "Nunito Sans", sans-serif;
    font-size: 13px;
    color: #6e7075;
    padding: 30px 15px;
}

.footer-fendi-logo img {
    width: 80px;
    height: 80px;
    border-radius: 40px;
}
.footer-fendi-logo li {
    list-style: none;
    margin-left: 30px;
}
.footer-fendi-logo li ul {
    margin: 0;
    padding: 0.4em;
    font-size: 14px;
    word-break: break-word;
}
.footer-fendi-logo li ul img {
    width: 15px;
    height: 15px;
}

.announcement-fendi {
    grid-area: announcement-fendi;
    background-color: #999999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.announcement-fendi .text {
    font-family: "Nunito Sans", sans-serif;
    font-size: 11px;
    color: #f9fafc;
    letter-spacing: 0.5em;
    text-align: center;
}

@media screen and (min-width: 480px) {
    #fendi-wrap {
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr 600px 1fr;
        grid-template-rows: 70px 20px -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content;
        grid-template-rows: 70px 20px min-content min-content min-content min-content min-content;
        grid-template-areas:
            ". header-fendi ."
            " announcement-fendi announcement-fendi announcement-fendi"
            ". free-shipping ."
            ". banner-fendi ."
            ". product-grid-fendi ."
            "load-more-button load-more-button load-more-button"
            ". rating-fendi ."
            ". line ."
            ". social-media ."
            ". footer-fendi ."
            "copyright-fendi copyright-fendi copyright-fendi";
    }
    #product-fendi-wrap {
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr 600px 1fr;
        grid-template-rows: -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content;
        grid-template-rows: min-content min-content min-content min-content min-content min-content;
        grid-template-areas:
            ". product-fendi-picture ."
            ". product-fendi-description ."
            ". product-variations ."
            ". product-fendi-button ."
            ". product-fendi-recomendations ."
            "copyright-fendi copyright-fendi copyright-fendi";
    }
    #cart-fendi-wrap {
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr 600px 1fr;
        grid-template-rows: 70px -webkit-min-content -webkit-min-content -webkit-min-content 200px -webkit-min-content;
        grid-template-rows: 70px min-content min-content min-content 200px min-content;
        grid-template-areas:
            "cart-header-fendi cart-header-fendi cart-header-fendi"
            ". cart-items-fendi ."
            ". cart-shipping-fendi ."
            ". cart-coupon-fendi ."
            ". cart-total-fendi ."
            ". cart-button-fendi ."
            ". cart-blank-fendi ."
            "copyright-fendi copyright-fendi copyright-fendi";
    }

    .cart-fendi {
        margin-right: 420px;
        margin-bottom: 4px;
    }

    .menu-fendi img {
        width: 20px;
        height: 14px;
        margin-left: 420px;
    }
    .back-fendi img {
        width: 20px;
        height: 20px;
        margin-left: 420px;
    }

    .selectall-fendi img {
        width: 22px;
        height: 18px;
        margin-right: 420px;
    }

    .cart-blank-fendi {
        grid-area: cart-blank-fendi;
    }

    .empty-cart-image {
        width: 30%;
    }
    .empty-cart-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

#support-wrap {
    height: 100vh;
    display: grid;
    grid-template-columns: 30px 1fr 1fr 30px;
    grid-template-rows: -webkit-min-content;
    grid-template-rows: min-content;
    grid-template-areas: ". support-content support-content .";
}
.support-content {
    grid-area: support-content;
}
.support-content li {
    padding: 10px;
}

@media screen and (min-width: 480px) {
    #support-wrap {
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr 320px 320px 1fr;
        grid-template-rows: -webkit-min-content;
        grid-template-rows: min-content;
        grid-template-areas: ". support-content support-content .";
    }
}

#promo-wrap {
    height: 100vh;
    background-color: gray;
    display: grid;
    grid-template-columns: 21px 1fr 1fr 21px;
    grid-template-rows: -webkit-min-content -webkit-min-content 400px;
    grid-template-rows: min-content min-content 400px;
    grid-template-areas:
        ". promo-header promo-header ."
        ". promo-content promo-content ."
        ". promo-footer promo-footer .";
}

#promo-wrap-payment {
    height: 100vh;
    display: grid;
    grid-template-columns: 10px 1fr 10px;
    grid-template-rows: -webkit-min-content -webkit-min-content;
    grid-template-rows: min-content min-content;
    grid-template-areas:
        ".  promo-header ."
        ". promo-content .";
}

.promo-review {
    grid-area: promo-review;
}

.promo-review-cellphone {
    grid-area: promo-review-cellphone;
}

a {
    text-decoration: none;
}

.promo-header {
    grid-area: promo-header;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.promo-header .logo {
    margin-top: 40px;
    width: 30%;
}

.promo-header .title {
    font-size: 50px;
    text-align: center;
    line-height: 40px;
    padding: 20px;
}

.promo-header .title-black {
    font-size: 50px;
    text-align: center;
    line-height: 40px;
    padding: 20px;
    background-color: black;
    color: white;
}

.promo-header .subtitle-black {
    margin-top: 5px;
    font-size: 20px;
    text-align: center;
    line-height: 20px;
    padding: 10px;
    background-color: black;
    color: white;
}

.promo-header .countdown {
    font-size: 20px;
    text-align: center;
    line-height: 20px;
    padding: 10px;
    padding-right: 150px;
    padding-left: 150px;
    border: 2px solid black;
    background-color: black;
    color: white;
    border-radius: 5px;
    margin-top: 10px;
}

.promo-header .subtitle {
}

.promo-content {
    grid-area: promo-content;
}

.promo-content .promo-15 {
    padding: 15px;
    margin: -10px;
    border-radius: 26px;

    border: 2px solid yellow;
    color: white;

    background-color: #2e3344;
}

.promo-content .promo-black {
    padding: 15px;
    margin: -10px;
    border-radius: 26px;

    border: 2px solid white;
    color: white;

    background-color: #2e3344;
}
.promo-content .off {
    background-color: yellow;
    width: 40px;
    border-radius: 60px;
    padding: 11px;
    text-align: center;
    z-index: 10;
    position: relative;
}

.promo-content .off-black {
    background-color: black;
    color: white;
    width: 40px;
    border-radius: 60px;
    padding: 11px;
    text-align: center;
    z-index: 10;
    position: relative;
}
.promo-content .buy {
    margin-bottom: 40px;
    background-color: black;
    color: white;
    border: 1px solid white;
    border-radius: 60px;
    padding: 11px;
    text-align: center;
    z-index: 10;
    position: relative;
    font-size: 20px;
    cursor: pointer;
}

.promo-content .button {
    margin-bottom: 40px;
    background-color: black;
    color: white;
    border: 1px solid white;
    border-radius: 60px;
    padding: 11px;
    text-align: center;
    z-index: 10;
    position: relative;
    font-size: 20px;
    cursor: pointer;
}

.promo-content .promo-25 {
    background-color: white;
}

.promo-footer {
    grid-area: promo-footer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2e3344;
}

.promo-footer .button {
    background-color: #2e3344;
    border: 2px #ffffff solid;
    border-radius: 10px;
    padding: 10px;
    font-size: 22px;
}

@media screen and (min-width: 480px) {
    #promo-wrap {
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr 600px 1fr;
        grid-template-rows: -webkit-min-content -webkit-min-content -webkit-min-content;
        grid-template-rows: min-content min-content min-content;
        grid-template-areas:
            ". promo-header ."
            ". promo-content ."
            ". promo-footer .";
    }
    #promo-wrap-payment {
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr 800px 1fr;
        grid-template-rows: -webkit-min-content;
        grid-template-rows: min-content;
        grid-template-areas:
            ". promo-header ."
            ". promo-content .";
    }
}

