@import url("https://fonts.googleapis.com/css?family=Playfair+Display");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap");
:root {
    --primary-color: 34, 34, 34;
    --dynamic-text-color: 255, 255, 255;
    --bg-color: 255, 255, 255;
}
body {
    background-color: rgba(var(--bg-color), 1);
}
.carousel-container {
    position: relative;
    max-width: 800px;
    margin: auto;
}
.carousel-position {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 18px;
    border-radius: 15px;
    font-size: 12px;
    border-color: rgb(41, 4, 4);
    color: #ffffff;
    margin-right: 10px;
    margin-top: 10px;
    background-color: rgba(197, 105, 25, 0.988);
    text-align: center;
    padding-top: 4px;
}

.badge-minimalist .MuiBadge-colorSecondary {
    background-color: rgba(var(--primary-color), 1);
    color: rgba(var(--dynamic-text-color), 1);
}
.carousel-dotgroup {
    text-align: center;
    margin-top: 10px;
}

.carousel__dot--selected {
    background-color: gray;
}
.carousel-dotgroup button {
    width: 10px;
    padding: 0px;
    height: 10px;
    margin-right: 5px;
    border-radius: 5px;
}

#minimalist-wrap {
    height: 100vh;
    display: grid;
    grid-template-columns: 21px 1fr 21px;
    grid-template-rows: 70px 70px min-content min-content min-content min-content min-content min-content min-content min-content min-content min-content;
    grid-template-areas:
        "header-minimalist header-minimalist header-minimalist"
        "blank blank blank"
        "free-shipping free-shipping free-shipping"
        "promo-banner promo-banner promo-banner"
        "banner-minimalist banner-minimalist banner-minimalist"
        "product-anchor-area product-anchor-area product-anchor-area"
        "search-bar search-bar search-bar"
        ". product-grid-minimalist ."
        "load-more-button load-more-button load-more-button"
        ". rating-minimalist ."
        ". line ."
        ". footer-minimalist ."
        ". social-media ."
        "copyright-minimalist copyright-minimalist copyright-minimalist";
}

#minimalist-categories-wrap {
    height: 100vh;
    display: grid;
    grid-template-columns: 21px 1fr 21px;
    grid-template-rows: 45px min-content min-content min-content min-content min-content min-content min-content min-content;
    grid-template-areas:
        "header-minimalist header-minimalist header-minimalist"
        "blank blank blank"
        "minimalist-categories-title minimalist-categories-title minimalist-categories-title"
        "promo-banner promo-banner promo-banner"
        ". search-bar ."
        ". product-grid-minimalist ."
        "load-more-button load-more-button load-more-button"
        ". footer-minimalist ."
        ". social-media .";
}
.payment-section {
    padding: 10px;
}
.payment-label {
    font-family: Proxima Nova, -apple-system, Helvetica Neue, Helvetica, Roboto, Arial,
        sans-serif;
    font-size: 12px;
    margin-top: 20px;
}
.payment-wrapper ul {
    list-style: disc;
    margin: 0 0 1em;
    padding: 0;
    display: inline-block;

    margin: 2px;
    overflow: hidden;
    text-indent: 100%;
    vertical-align: middle;
    white-space: nowrap;
    background-repeat: no-repeat;
    background-image: url("https://http2.mlstatic.com/secure/org-img/ui/payment-methods/1.8.0/br/payment-methods-large@2x.png");
    background-size: 100px;
}

.payment-visa {
    height: 45px;
    width: 60px;
    background-position: 0 -30px;
}
.payment-mastercard {
    height: 37px;
    width: 45px;
    background-position: 0 -82px;
}
.payment-hipercard {
    height: 42px;
    width: 60px;
    background-position: 0 -250px;
}
.payment-amex {
    height: 43px;
    width: 30px;
    background-position: 0 -120px;
}
.payment-diners {
    height: 43px;
    width: 90px;
    background-position: 0 -162px;
}
.payment-elo {
    height: 45px;
    width: 35px;
    background-position: 0 -290px;
}
.payment-melicard {
    height: 42px;
    width: 47px;
    background-position: 0 -335px;
}
.payment-bolbradesco {
    height: 40px;
    width: 60px;
    background-position: 0 -378px;
}

.product-anchor-area {
    grid-area: product-anchor-area;
}
.rating-minimalist {
    grid-area: rating-minimalist;
}

.rating-minimalist .rating-wrap {
    margin-top: 30px;
    font-family: "Playfair Display", serif;
}

.rating-minimalist .rating-content {
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 15px 50px 0px rgba(0, 0, 0, 0.1);
    font-size: 13px;
    color: #707070;
    padding-bottom: 20px;
    margin-top: 30px;
}

.rating-minimalist .rating-wrap .title {
    margin-top: 30px;
    font-size: 30px;
    color: #222222;
}

.rating-minimalist .first-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
}

.rating-minimalist .rating-user {
    font-style: italic;
    padding-left: 30px;
}

.rating-minimalist .rating-title {
    font-size: 15px;
}

.rating-minimalist .second-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.rating-minimalist .rating-review {
    margin-top: 15px;
    text-align: left;
    margin-bottom: 15px;
    padding-left: 30px;
    padding-right: 30px;
    text-align: left;
}

.rating-minimalist .separator {
    width: "100%";
    height: 0px;
    background-color: gray;
    margin-top: 40px;
}

.free-shipping {
    grid-area: free-shipping;
}

.free-shipping .minimalist {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Bebas Neue", cursive;
    font-size: 12px;
    color: #222222;
    background-color: #ffffff;
    padding: 6px;
}

.free-shipping .highlight {
    color: rgba(var(--primary-color), 1);
}

.free-shipping .space {
    margin-left: 3px;
    margin-right: 3px;
}

.load-more-button {
    grid-area: load-more-button;
    display: flex;
    text-align: center;
    padding: 10px;
    border: 1px solid;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    cursor: pointer;
}

.social-media {
    grid-area: social-media;
}

.social-media-container {
    display: flex;
    margin-top: 30px;
    align-items: center;
}

.social-media .title {
    font-family: "Playfair Display", serif;
    font-size: 20px;
    color: #222222;
    margin-right: 10px;
}

.social-media .icon {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: rgba(var(--primary-color), 1);
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    margin-right: 10px;
}

.social-media i {
    color: white;
    font-size: 20px;
}

.drawer-title {
    margin-left: 30px;
    font-family: "Bebas Neue", cursive;
    font-size: 20px;
    color: #222222;
}

.drawer-clean {
    margin-right: 20px;
    font-family: "Bebas Neue", cursive;
    font-size: 16px;
    color: #222222;
    border: solid 1px #222222;
    padding: 5px;
    cursor: pointer;
}

.drawer-items {
    margin-top: 30px;
    margin-left: 30px;
    font-family: "Playfair Display", serif;
    font-size: 28px;
    color: #888888;
    list-style-type: none;
}
.drawer-items li:not(:last-child) {
    margin-bottom: 10px;
}
.empty-cart-image {
    width: 40%;
    margin-top: 60px;
}

.empty-cart-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.empty-cart-container .title {
    font-family: "Montserrat", sans-serif;
    color: #f1456f;
    margin-top: 10px;
}
.empty-cart-container .subtitle {
    font-family: "Montserrat", sans-serif;
    color: gray;
    font-size: 12px;
}

#cart-minimalist-wrap {
    height: 100vh;
    display: grid;
    grid-template-columns: 21px 1fr 21px;
    grid-template-rows: 70px min-content min-content min-content min-content 1fr min-content;
    grid-template-areas:
        "cart-header-minimalist cart-header-minimalist cart-header-minimalist"
        ". cart-items-minimalist ."
        ". cart-shipping-minimalist ."
        ". cart-coupon ."
        ". cart-total-minimalist ."
        ". cart-button-minimalist ."
        "copyright-minimalist copyright-minimalist copyright-minimalist";
}
.cart-shipping-minimalist {
    grid-area: cart-shipping-minimalist;
}

.cart-shipping-minimalist .cart-shipping {
    display: flex;
    margin-top: 25px;
    border-radius: 10px;
    justify-content: space-between;
    display: flex;
    margin-top: 20px;
}

.cart-shipping-minimalist .cart-shipping .free-shipping {
    font-family: "Roboto", sans-serif;
    justify-content: center;
    font-size: 15px;
    color: rgb(66, 66, 66);
    flex: 1;
    display: flex;
    align-items: center;
}

.cart-shipping-minimalist .cart-shipping .apply-button {
    position: relative;
    border: solid #000000 1px;
    border-radius: 5px;
    margin: 0px;
    font-size: 0.6em;
    width: 50px;
    height: 19px;
    padding-top: 13px;
    justify-content: center;
    align-items: center;
    flex: 0.2 1;
    padding-bottom: 6px;
    margin-left: 5px;
    cursor: pointer;
    text-align: center;
}

.cart-shipping-minimalist .cart-shipping-results {
    display: flex;
    border: solid rgba(0, 0, 0, 0.23) 1px;
    padding: 5px;
    border-radius: 0 0 10px 10px;
    justify-content: space-between;
    display: flex;
    margin-top: 5px;
    margin-bottom: 10px;
}

.cart-shipping-minimalist .cart-shipping-results .MuiFormGroup-root {
    margin-left: 20px !important;
}

.cart-coupon {
    grid-area: cart-coupon;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
}

.cart-coupon .apply-button {
    border: solid #000000 1px;
    border-radius: 5px;
    margin: 0px;
    font-size: 0.6em;
    width: 50px;
    height: 19px;
    padding-top: 13px;
    justify-content: center;
    align-items: center;
    flex: 0.2 1;
    padding-bottom: 6px;
    margin-left: 5px;
    cursor: pointer;
    text-align: center;
}
.cart-header-minimalist {
    grid-area: cart-header-minimalist;
    display: flex;
    align-items: center;
    background-color: white;
    top: 0;
    position: fixed;
    height: 70px;
    width: 100%;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
    z-index: 100;
}
.cart-items-minimalist {
    grid-area: cart-items-minimalist;
}

.cart-items-minimalist .item {
    display: flex;
    margin-top: 25px;
}

.cart-items-minimalist .item .item-description {
    margin-left: 30px;
    margin-top: 30px;
}
.cart-items-minimalist .item-picture-image {
    width: 130px;
    height: 190px;
    object-fit: cover;
}

.cart-items-minimalist .item-name {
    font-family: "Playfair Display", serif;
    line-height: 25px;
    font-size: 22px;
    color: #222222;
}
.cart-items-minimalist .item-variation {
    font-family: "Playfair Display", serif;
    color: #222222;
}
.cart-items-minimalist .item-price {
    margin-top: 15px;
    margin-left: 3px;
    font-family: "Bebas Neue", cursive;
    font-size: 16px;
    color: #888888;
    letter-spacing: 0.05em;
}

.cart-items-minimalist .item-price-line {
    margin-top: 15px;
    margin-left: 3px;
    font-family: "Bebas Neue", cursive;
    font-size: 16px;
    color: #888888;

    text-decoration: line-through;
}

.cart-items-minimalist .item-promoprice {
    margin-top: 15px;
    margin-left: 10px;
    font-family: "Bebas Neue", cursive;
    font-size: 16px;
    color: #000;
    letter-spacing: 0.05em;
}

.cart-items-minimalist .item-quantity {
    display: flex;
    margin-top: 15px;
}

.cart-items-minimalist .item .item-quantity .minus {
    border: 1px solid #cccccc;
    width: 28px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: -1px;
    background-color: white;
    box-sizing: unset;
    padding: 0;
}

.cart-items-minimalist .item .item-quantity .qty {
    border: 1px solid #cccccc;
    width: 28px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}
.cart-items-minimalist .item .item-quantity .add {
    border: 1px solid #cccccc;
    width: 28px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -1px;
    background-color: white;
    box-sizing: unset;
    padding: 0;
}

.cart-total-minimalist {
    grid-area: cart-total-minimalist;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    font-family: "Playfair Display", serif;

    font-size: 20px;
    color: #222222;
}
.cart-button-minimalist {
    grid-area: cart-button-minimalist;
    margin-top: 40px;
    margin-bottom: 100px;
    background-color: rgba(var(--primary-color), 1);
    height: 58px;
    display: flex;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
    /*background: linear-gradient(to top, #444444 0%, rgba(0, 0, 0, 0.6) 100%);*/
    font-family: "Bebas Neue", cursive;
    font-size: 15px;
    color: rgba(var(--dynamic-text-color), 1);
    letter-spacing: 0.1em;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

#product-minimalist-wrap {
    height: 100vh;
    display: grid;
    grid-template-columns: 21px 1fr 21px;
    grid-template-rows: min-content min-content min-content min-content min-content min-content min-content;
    grid-template-areas:
        "product-minimalist-picture product-minimalist-picture product-minimalist-picture"
        ". product-minimalist-description ."
        ". product-variations ."
        ". product-minimalist-button ."
        ". product-minimalist-recomendations ."
        ". product-share ."
        "copyright-minimalist copyright-minimalist copyright-minimalist";
}

.product-minimalist-picture {
    grid-area: product-minimalist-picture;
}
.product-minimalist-description {
    grid-area: product-minimalist-description;
    margin-top: 35px;
}
.product-variations {
    grid-area: product-variations;
}

.product-share {
    grid-area: product-share;
}

.product-share .title {
    margin-top: 20px;
    font-size: 14px;
    font-weight: bold;
}

.product-share .share-buttons {
    display: flex;
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: flex-start;
    align-items: end;
    margin-bottom: 20px;
}
.product-share .share-button {
    text-align: center;
}

.product-share .share-button .button-title {
    font-size: 12px;
}

.product-share .share-button .copy-link {
    font-size: 30px;
    cursor: pointer;
}
.product-share .share-button .whatsapp-icon {
    font-size: 28px;
}

.product-variations .title {
    background-color: #e6e6e6;
    padding: 24px;
    margin-top: 20px;

    font-size: 20px;
    margin-bottom: 10px;
}

.product-variations .title-box {
    margin-top: 20px;
    font-size: 14px;
    color: rgb(17 24 39 / 1);
    font-weight: 500;
}

.product-variations .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Playfair Display";
    padding-top: 14px;
    padding-bottom: 10px;
    border-bottom: solid 1px;
    border-bottom-color: #e6e6e6;
}

.product-variations .list-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 2%;
}

.product-variations .list-box .description-on {
    border: solid 1px;
    background: black;
    color: white;
    padding: 10px;
    margin-bottom: 5px;
    margin-right: 5px;
}

.product-variations .list-box .box {
    margin-top: 10px;
    border: solid 1px;
    background-color: rgb(255 255 255 / 1);
    color: rgb(17 24 39 /1);
    text-transform: uppercase;
    font-weight: 500;
    font-size: 15px;
    line-height: 1.25rem;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    border-color: rgb(229 231 235 / 1);
    border-radius: 0.375rem;
    cursor: pointer;
}
.product-variations .list-box .box-disabled {
    margin-top: 10px;
    border: solid 1px;
    background-color: rgb(255 255 255 / 0.2);
    color: rgb(17 24 39 / 0.2);
    text-transform: uppercase;
    font-weight: 500;
    font-size: 15px;
    line-height: 1.25rem;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    border-color: rgb(229 231 235 /0.2);
    border-radius: 0.375rem;
    cursor: not-allowed;
}
.product-variations .list-box .box-selected {
    margin-top: 10px;
    border: solid 1px;
    background-color: #444444;
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    font-weight: 500;
    font-size: 15px;
    line-height: 1.25rem;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    border-color: rgb(229 231 235 / 1);
    border-radius: 0.375rem;
    cursor: pointer;
}

.product-variations .list-box .description-out-of-stock {
    border: solid 1px;
    background: lightgray;
    color: white;
    padding: 10px;
    margin-bottom: 5px;
    margin-right: 5px;
}

.product-variations .title-color-size {
    margin-top: 20px;
    font-size: 14px;

    font-weight: 500;
}

.product-variations .list-box .text-gray-900 {
    --tw-text-opacity: 1;
    color: rgb(17 24 39 /1);
}
.product-variations .list-box .uppercase {
    text-transform: uppercase;
}
.product-variations .list-box .font-medium {
    font-weight: 500;
}
.product-variations .list-box .text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.product-variations .list-box .py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}
.product-variations .list-box .px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}
.product-variations .list-box .bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / 1);
}
.product-variations .list-box .border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / 1);
}
.product-variations .list-box .border {
    border-width: 1px;
}
.product-variations .list-box .rounded-md {
    border-radius: 0.375rem;
}
.product-variations .list-box .justify-center {
    justify-content: center;
}
.product-variations .list-box .items-center {
    align-items: center;
}
.product-variations .list-box .cursor-pointer {
    cursor: pointer;
}
.product-variations .list-box .flex {
    display: flex;
}
.product-variations .list-box .description-off {
    border: solid 1px;
    background: white;
    padding: 10px;
    margin-bottom: 5px;
    margin-right: 5px;
}

.product-variations .item .description {
    padding-left: 23px;

    font-size: 16px;
}
.product-variations .quantity {
    display: flex;
    padding-right: 23px;
}

.product-variations .quantity .value {
    font-size: 22px;
    padding-right: 15px;
    padding-left: 15px;
}
.product-variations .item .button {
    font-size: 25px;
    color: red;
    font-weight: 600;
    cursor: pointer;
}

.product-variations .list-radio {
    margin-top: 20px;
    margin-left: 23px;
}

.product-variations .list-radio .label {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.product-variations .list-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.product-variations .list-radio .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
}

/* When the radio button is checked, add a blue background */
.product-variations .list-radio input:checked ~ .checkmark {
    background-color: black;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.product-variations .list-radio .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.product-variations .list-radio input:checked ~ .checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.product-variations .list-radio .checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}

.product-minimalist-button {
    grid-area: product-minimalist-button;
    display: flex;
    margin-top: 40px;
    margin-bottom: 40px;
    font-family: "Bebas Neue", cursive;
    font-size: 15px;
    color: rgba(var(--dynamic-text-color), 1);
    letter-spacing: 0.1em;
    align-items: center;
    justify-content: center;
}

.product-minimalist-button .in-stock {
    background-color: rgba(var(--primary-color), 1);
    height: 58px;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
    /*background: linear-gradient(to top, #444444 0%, rgba(0, 0, 0, 0.6) 100%);*/
    cursor: pointer;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
}

.product-minimalist-button .out-of-stock {
    height: 58px;
    border: 1px solid lightgray;
    color: gray;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
}

.product-minimalist-button .in-stock:active {
    transform: translateY(4px);
    background: gray;
}

.product-minimalist-description .product-minimalist-name {
    font-family: "Playfair Display", serif;
    line-height: 47px;
    font-size: 28px;
    color: #222222;
}
.product-minimalist-description .product-minimalist-price {
    font-family: "Bebas Neue", cursive;
    font-size: 20px;
    color: #888888;
    margin-top: 10px;
    letter-spacing: 0.05em;
    margin-left: 5px;
}
.product-minimalist-description .product-minimalist-price-line {
    font-family: "Bebas Neue", cursive;
    font-size: 20px;
    color: #888888;
    margin-top: 10px;

    text-decoration: line-through;
}
.product-minimalist-description .product-minimalist-promoprice {
    font-family: "Bebas Neue", cursive;
    font-size: 20px;
    color: rgba(var(--primary-color), 1);
    margin-top: 10px;
    letter-spacing: 0.05em;
    margin-left: 15px;
}
.product-minimalist-container-price {
    display: flex;
}
.product-grid-minimalist-container-price {
    display: flex;
    align-items: center;
    justify-content: center;
}
.product-minimalist-description .product-minimalist-details {
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
    line-height: 21px;
    color: #222222;
    margin-top: 10px;
    letter-spacing: 0.05em;
    word-break: break-word;
}

.product-minimalist-recomendations {
    grid-area: product-minimalist-recomendations;
    font-size: 14px;
}
.product-minimalist-recomendations h3 {
    font-size: 14px;
    font-weight: bold;
}
.product-minimalist-recomendations .images {
    display: flex;
    flex: 1;
    justify-content: space-between;
}
.product-minimalist-recomendations img {
    width: 90px;
    height: 120px;
    object-fit: contain;
    margin: 2px;
    margin-bottom: 30px;
}
.blank {
    grid-area: blank;
    display: flex;
    align-items: center;
    justify-content: center;
}

.blank span {
    font-weight: bold;
}

.blank p {
    font-family: "Montserrat", sans-serif;
    font-size: 11px;
    color: #707070;
    line-height: 2px;
}

.blank a {
    text-decoration: none;
}
.blank i {
    color: #707070;
    font-size: 20px;
    margin-right: 10px;
}

.blank-minimalist-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-minimalist {
    grid-area: header-minimalist;
    display: flex;
    align-items: center;
    background-color: white;
    position: fixed;
    height: 70px;
    width: 100%;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
    z-index: 100;
}
.container-header-minimalist {
    display: flex;
    flex: 1;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-end;
}

.banner-minimalist {
    grid-area: banner-minimalist;
}

.product-grid-minimalist {
    grid-area: product-grid-minimalist;
    margin-bottom: 30px;
}

.line {
    grid-area: line;
}

.footer-minimalist {
    grid-area: footer-minimalist;
}

.copyright-minimalist {
    grid-area: copyright-minimalist;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #e3e3e3;
    padding: 10px;
    line-height: 10px;
}
.copyright-minimalist img {
    width: 63px;
    height: 22px;
    margin-bottom: 5px;
}
.copyright-minimalist span {
    color: #9b9b9b;
    font-size: 9px;
}

.title-minimalist {
    font-family: "Bebas Neue", cursive;
    font-size: 18px;
    color: #222222;
}
.menu-minimalist img {
    width: 20px;
    height: 14px;
    margin-left: 21px;
}
.back-minimalist img {
    width: 20px;
    height: 20px;
    margin-left: 21px;
}
.cart-minimalist {
    margin-right: 21px;
    margin-bottom: 4px;
}
.cart-minimalist img {
    width: 20px;
    height: 21px;
}

.selectall-minimalist img {
    width: 22px;
    height: 18px;
    margin-right: 21px;
}

.product-grid-minimalist {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    grid-column-gap: 13px;
}
.product-grid-container-minimalist {
    position: relative;
    margin-top: 30px;
}
.product-grid-minimalist img {
    width: 100%;
    object-fit: cover;
}

.product-grid-minimalist-title {
    font-family: "Playfair Display", serif;
    line-height: 24px;
    font-size: 18px;
    align-items: center;
    color: rgba(34, 34, 34, 1);
    margin-top: 15px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
}

.product-grid-minimalist-price {
    font-family: "Bebas Neue", cursive;
    font-size: 13px;
    color: #999999;
    margin-top: 10px;
    text-align: center;
    letter-spacing: 0.05em;
}

.product-grid-minimalist-promoprice {
    font-family: "Bebas Neue", cursive;
    font-size: 13px;
    color: rgba(var(--primary-color), 1);
    margin-top: 10px;
    text-align: center;
    margin-left: 10px;
    letter-spacing: 0.05em;
}

.product-grid-minimalist-price-line {
    font-family: "Bebas Neue", cursive;
    font-size: 13px;
    color: #999999;
    margin-top: 10px;
    text-align: center;
    text-decoration: line-through;
}

.banner-container-minimalist {
    position: relative;
}

.banner-container-minimalist:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.6) 100%);
}

.banner-minimalist-title {
    position: absolute;
    bottom: 65px;
    left: 21px;
    z-index: 1;
    color: white;
    font-family: "Playfair Display", serif;
    line-height: 30px;
    font-size: 28px;
    padding-right: 120px;
}

.banner-minimalist-price {
    position: absolute;
    bottom: 30px;
    left: 25px;
    text-align: center;
    font-size: 30px;
    z-index: 1;
    color: white;
    font-family: "Bebas Neue", cursive;
    font-size: 16px;
    letter-spacing: 0.05em;
}

.banner-minimalist-promoprice {
    position: absolute;
    bottom: 10px;
    left: 25px;
    text-align: center;

    z-index: 1;
    color: white;
    font-family: "Bebas Neue", cursive;
    font-size: 22px;
    letter-spacing: 0.05em;
}
.banner-minimalist-price-line {
    position: absolute;
    bottom: 35px;
    left: 25px;
    text-align: center;
    font-size: 33px;
    z-index: 1;
    color: lightgray;
    font-family: "Bebas Neue", cursive;
    font-size: 16px;
    text-decoration: line-through;
}

.banner-minimalist-add {
    position: absolute;
    width: 40px;
    height: 40px;
    bottom: 40px;
    right: 21px;
    background-color: #fff;
    color: #fff;
    border-radius: 20px;
    text-align: center;
    font-size: 30px;
    box-shadow: 0px 15px 5px 0px rgba(0, 0, 0, 0.1);
    z-index: 1;
}

.card-minimalist {
    margin-top: 30px;
    width: 100%;
    height: 200px;
    background-color: #fff;
    box-shadow: 0px 15px 50px 0px rgba(0, 0, 0, 0.1);
}

.card-minimalist-text {
    font-family: "Playfair Display", serif;
    font-size: 13px;
    color: #707070;
    padding: 30px;
}

.footer-minimalist-title {
    margin-top: 30px;
    font-family: "Playfair Display", serif;
    font-size: 33px;
    color: #222222;
}
.footer-minimalist-logo {
    display: flex;
    margin-top: 30px;
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
    color: #707070;
    padding: 30px 15px;
}

.footer-minimalist-logo img {
    width: 80px;
    height: 80px;
    border-radius: 40px;
}
.footer-minimalist-logo li {
    list-style: none;
    margin-left: 30px;
}
.footer-minimalist-logo li ul {
    margin: 0;
    padding: 0.4em;
    font-size: 12px;
    word-break: break-word;
}
.footer-minimalist-logo li ul img {
    width: 15px;
    height: 15px;
}

@media screen and (min-width: 480px) {
    #minimalist-wrap {
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr 600px 1fr;
        grid-template-rows: 70px 70px min-content min-content min-content min-content min-content min-content min-content;
        grid-template-areas:
            ". header-minimalist ."
            "blank blank blank"
            ". free-shipping ."
            ". promo-banner ."
            ". banner-minimalist ."
            ". product-anchor-area ."
            ". search-bar ."
            ". product-grid-minimalist ."
            "load-more-button load-more-button load-more-button"
            ". rating-minimalist ."
            ". line ."
            ". social-media ."
            ". footer-minimalist ."
            "copyright-minimalist copyright-minimalist copyright-minimalist";
    }
    #minimalist-categories-wrap {
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr 600px 1fr;
        grid-template-rows: 45px min-content min-content min-content min-content min-content min-content min-content min-content;
        grid-template-areas:
            ". header-minimalist ."
            ". blank ."
            ". promo-banner ."
            "minimalist-categories-title minimalist-categories-title minimalist-categories-title"
            ". search-bar ."
            ". product-grid-minimalist ."
            ". load-more-button ."
            ". footer-minimalist ."
            ". social-media .";
    }
    #product-minimalist-wrap {
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr 600px 1fr;
        grid-template-rows: min-content min-content min-content min-content min-content min-content min-content;
        grid-template-areas:
            ". product-minimalist-picture ."
            ". product-minimalist-description ."
            ". product-variations ."
            ". product-minimalist-button ."
            ". product-minimalist-recomendations ."
            ". product-share ."
            "copyright-minimalist copyright-minimalist copyright-minimalist";
    }
    #cart-minimalist-wrap {
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr 600px 1fr;
        grid-template-rows: 70px min-content min-content min-content 200px min-content;
        grid-template-areas:
            "cart-header-minimalist cart-header-minimalist cart-header-minimalist"
            ". cart-items-minimalist ."
            ". cart-shipping-minimalist ."
            ". cart-coupon ."
            ". cart-total-minimalist ."
            ". cart-button-minimalist ."
            ". cart-blank-minimalist ."
            "copyright-minimalist copyright-minimalist copyright-minimalist";
    }

    .cart-minimalist {
        margin-right: 420px;
        margin-bottom: 4px;
    }

    .menu-minimalist img {
        width: 20px;
        height: 14px;
        margin-left: 420px;
    }
    .back-minimalist img {
        width: 20px;
        height: 20px;
        margin-left: 420px;
    }

    .selectall-minimalist img {
        width: 22px;
        height: 18px;
        margin-right: 420px;
    }

    .cart-blank-minimalist {
        grid-area: cart-blank-minimalist;
    }

    .empty-cart-image {
        width: 30%;
    }
    .empty-cart-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .product-minimalist-recomendations img {
        width: 150px;
        object-fit: contain;
        margin: 2px;
        margin-bottom: 30px;
        height: 100%;
    }
}
