#review-wrap {
  height: 100vh;
  display: grid;
  grid-template-columns: 30px 1fr 1fr 30px;
  grid-template-rows: min-content min-content;
  grid-template-areas:
    ". review-header review-header ."
    ". review-data review-data .";
  margin-top: 30px;
}

.review-header {
  grid-area: review-header;
}
.review-data {
  grid-area: review-data;
}

.review-data
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: gray;
  border-width: 2px;
}
.review-success {
  margin-top: 30px;
  text-align: center;
}
.review-title {
  margin-top: 10px;
}

.review-title .label {
  margin-bottom: 15px;
}
.review-stars {
  text-align: center;
  margin-top: 0px;
}

.review-stars .label {
  margin-top: 30px;
  margin-bottom: 10px;
}

.review-content .label {
  margin-bottom: 15px;
}

.review-button {
  margin-top: 40px;
  margin-bottom: 100px;
  background-color: #444444;
  height: 58px;
  display: flex;
  box-shadow: 0px 15px 15px 0px rgba(0, 0, 0, 0.1);
  background: linear-gradient(to top, #444444 0%, rgba(0, 0, 0, 0.6) 100%);
  font-family: "Bebas Neue", cursive;
  font-size: 15px;
  color: #fff;
  letter-spacing: 0.1em;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
}

.review-header {
  text-align: center;
}

.review-header .store-name {
  font-size: 25px;
}

.review-logo {
  width: 50px;
  height: 50px;
}

/*================================== */
/* DESKTOP
/*================================== */
@media screen and (min-width: 480px) {
  #review-wrap {
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr 320px 320px 1fr;
    grid-template-rows: min-content min-content;
    grid-template-areas:
      ". review-header review-header ."
      ". review-data review-data .";
    margin-top: 30px;
  }
}
