#support-wrap {
    height: 100vh;
    display: grid;
    grid-template-columns: 30px 1fr 1fr 30px;
    grid-template-rows: min-content;
    grid-template-areas: ". support-content support-content .";
}
.support-content {
    grid-area: support-content;
}
.support-content li {
    padding: 10px;
}

@media screen and (min-width: 480px) {
    #support-wrap {
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr 320px 320px 1fr;
        grid-template-rows: min-content;
        grid-template-areas: ". support-content support-content .";
    }
}
