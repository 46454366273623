@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dosis&display=swap");
@import "~react-image-gallery/styles/css/image-gallery.css";

#dark-wrap {
    height: 100vh;
    display: grid;
    grid-template-columns: 21px 1fr 21px;
    grid-template-rows: 40px min-content min-content min-content min-content min-content min-content min-content;
    grid-template-areas:
        "header-dark header-dark header-dark"
        "blank blank blank"
        "promo-banner promo-banner promo-banner"
        "featured-dark featured-dark featured-dark"
        ". grid-categories ."
        "top-sellers top-sellers top-sellers"
        "rating-dark rating-dark rating-dark"
        "footer-dark footer-dark footer-dark"
        "social-media-dark social-media-dark social-media-dark";
}

#dark-categories-wrap {
    height: 100vh;
    display: grid;
    grid-template-columns: 21px 1fr 21px;
    grid-template-rows: 40px min-content min-content min-content min-content min-content min-content min-content min-content;
    grid-template-areas:
        "header-dark header-dark header-dark"
        "blank blank blank"
        "dark-categories-title dark-categories-title dark-categories-title"
        "promo-banner promo-banner promo-banner"
        "search-bar search-bar search-bar"
        "product-grid-dark product-grid-dark product-grid-dark"
        "load-more-button-dark load-more-button-dark load-more-button-dark"
        "footer-dark footer-dark footer-dark"
        "social-media-dark social-media-dark social-media-dark";
}

.promo-banner {
    grid-area: promo-banner;
    margin-bottom: 10px;
}

.loading-area {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
}

.search-bar {
    grid-area: search-bar;
}

.search-input {
    margin-top: 20px;
    height: 52px;
    position: relative;
}

.search-input::before {
    content: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAAAXNSR0IArs4c6QAAAQJJREFUKBWVkr2uQUEUhf3ET6GRaC5aFRoJKrf1BKpb8SwqovYGXkCj00k0QnRKEYkILYobvpUYmeMMyVnJl7P3mjN7Zu9zwiGv2qRFyMMSRrAFp6JPN8XzBj+wgDkUYAg7WINTYdwpDECxrRLJHeq2accdkgm8bzTvNAg2EDOGeUYI1KNO1gkuzTA1g8T7ojbn4ONQWPuHPWgeHmnzCqoe15tkSNPgPEAn68oVcOmA2XMtGK9FoE/VhOTTVNExqLCGZnxCv2pYauEC6lF0oQxX6IOvb7yX9NPEQafan+aPXDdQC18LsO6Tip5BBY6gIQaSbnMCFRCBZRcIvFkbsvCr4AFGOCxQy+JdGQAAAABJRU5ErkJggg==");
    display: block;
    position: absolute;
    width: 15px;
    z-index: 3;
    height: 15px;
    font-size: 0px;
    top: 9px;
    left: 7px;
    line-height: 32px;
    opacity: 0.6;
}

.search-input > input {
    width: 100%;
    font-size: 18px;
    border: none;
    line-height: 22px;
    padding: 5px 10px 5px 25px;
    height: 32px;
    position: relative;
    border: solid 1px rgb(242, 242, 242);
}

.search-input > input:focus {
    outline: none;
}

.rating-dark {
    grid-area: rating-dark;
}

.rating-dark .rating-wrap {
    color: #ffffff;
    margin-top: 10px;
    font-family: "Roboto", sans-serif;
    font-size: 15px;

    padding-left: 30px;
    padding-right: 30px;
}

.rating-dark .rating-wrap .title {
    margin-top: 30px;
    font-size: 19px;
    margin-bottom: 20px;
}

.rating-dark .first-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.rating-dark .rating-user {
    font-style: italic;
}

.rating-dark .rating-title {
    font-size: 18px;
}

.rating-dark .second-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.rating-dark .rating-review {
    margin-top: 15px;
    text-align: justify;
    margin-bottom: 15px;
}

.rating-dark .separator {
    width: "100%";
    height: 2px;
    background-color: gray;
    margin-top: 40px;
}

.back-dark i {
    font-size: 18px;
    margin-left: 21px;
    color: white;
}
.back-group i {
    font-size: 18px;
    margin-left: 21px;
    color: white;
}
.dark-categories-title {
    grid-area: dark-categories-title;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 0px;
    font-weight: 200;
    color: white;
    font-size: 30px;
    font-family: "Dosis", sans-serif;
}

.load-more-button-dark {
    grid-area: load-more-button-dark;
    display: flex;
    text-align: center;
    padding: 10px;
    border: 1px solid white;
    color: white;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    cursor: pointer;
}

.grid-categories {
    grid-area: grid-categories;
}

.grid-categories .category {
    text-align: center;
    margin-top: 15px;
    color: white;
    font-family: "Dosis", sans-serif;
}

.grid-categories .title {
    text-align: center;

    margin-bottom: 40px;
    font-weight: 200;
    color: white;
    font-size: 30px;
    font-family: "Dosis", sans-serif;
}

.grid-categories .title-group {
    text-align: center;
    font-weight: 200;
    color: white;
    font-size: 30px;
    font-family: "Dosis", sans-serif;
}

.featured-dark {
    grid-area: featured-dark;
}

.promo-banner-container {
    cursor: pointer;
}

.image-dark-button {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: white;
    color: black;
    border: 1px solid #999999;
    font-size: 16px;
    padding: 12px 24px;

    cursor: pointer;
    font-family: "Dosis", sans-serif;
}

.social-media-dark {
    grid-area: social-media-dark;
}

.social-media-container {
    display: flex;
    margin-top: 20px;
    align-items: center;
    padding-left: 30px;
    margin-bottom: 30px;
}

.social-media-dark .title {
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    color: #424242;
    margin-right: 10px;
}

.social-media-dark .icon {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    margin-right: 10px;
}

.social-media-dark i {
    color: #424242;
    font-size: 20px;
}

#product-dark-wrap {
    height: 100vh;
    display: grid;
    grid-template-columns: 21px 1fr 21px;
    grid-template-rows: min-content min-content min-content 200px;
    grid-template-areas:
        "product-dark-picture product-dark-picture product-dark-picture"
        ". product-dark-variations ."
        ". product-dark-description ."
        "product-dark-button product-dark-button product-dark-button";
}

.product-dark-picture {
    grid-area: product-dark-picture;
    margin-top: 60px;
}
.product-dark-description {
    grid-area: product-dark-description;
    margin-top: 35px;
}

.product-dark-variations {
    grid-area: product-dark-variations;
}

.product-dark-variations .product-variations .quantity .value {
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    padding-right: 15px;
    padding-left: 15px;
    color: #ffffff;
    padding-top: 5px;
}
.product-dark-variations .product-variations .title {
    background-color: #888888;
    color: #ffffff;
    font-size: 15px;
    padding: 20px;
}

.product-dark-variations .title-color-size {
    color: #ffffff;
    margin-top: 20px;
    font-size: 14px;
    font-weight: 500;
}

.product-dark-variations .product-variations .list-radio .label {
    display: block;
    color: #ffffff;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.product-dark-variations .product-variations .list-radio .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #eee;
    border-radius: 50%;
}

.product-dark-variations .product-variations .list-box .box-selected {
    margin-top: 10px;
    border: solid 1px;
    background-color: #000000;
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    font-weight: 500;
    font-size: 15px;
    line-height: 1.25rem;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    border-color: rgb(229 231 235 / 1);
    border-radius: 0.375rem;
    cursor: pointer;
}

.product-dark-variations .product-variations .list-box .box-disabled {
    margin-top: 10px;
    border: solid 1px;
    background-color: rgb(66, 66, 66);
    color: gray;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 15px;
    line-height: 1.25rem;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    border-color: gray;
    border-radius: 0.375rem;
    cursor: not-allowed;
}

.product-dark-variations .product-variations .list-radio .checkmark:after {
    top: 3px;
    left: 2.8px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: white;
}

.product-dark-variations .product-variations .item .description {
    padding-left: 23px;
    color: #ffffff;
    font-size: 15px;
    font-family: "Roboto", sans-serif;
}

.product-dark-variations .product-variations .item .button {
    font-size: 25px;
    color: #18dbbe;
    font-weight: 600;
    cursor: pointer;
}

.product-dark-button {
    grid-area: product-dark-button;
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    font-family: "Dosis", sans-serif;
    font-size: 15px;
    color: rgba(var(--dynamic-text-color), 1);
    letter-spacing: 0.1em;
    align-items: center;
    justify-content: center;
}
.product-dark-button .in-stock {
    background-color: rgba(var(--primary-color), 1);
    height: 58px;
    cursor: pointer;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
}

.product-dark-button .out-of-stock {
    height: 58px;
    border: 1px solid #fff;
    color: #fff;
    width: 80%;
    align-items: center;
    justify-content: center;
    display: flex;
}

.product-dark-button .in-stock:active {
    transform: translateY(4px);
    background: gray;
}

.product-dark-description .product-dark-name {
    font-family: "Roboto", sans-serif;
    line-height: 47px;
    font-size: 20px;
    color: #fff;
}
.product-dark-description .product-dark-price {
    font-family: "Oswald", sans-serif;
    font-size: 14px;
    color: #fff;
    margin-top: 10px;
    letter-spacing: 0.05em;
    margin-left: 5px;
}
.product-dark-description .product-dark-price-line {
    font-family: "Oswald", sans-serif;
    font-size: 14px;
    color: #888888;
    margin-top: 10px;

    text-decoration: line-through;
}
.product-dark-description .product-dark-promoprice {
    font-family: "Oswald", sans-serif;
    font-size: 14px;
    color: rgba(var(--primary-color), 1);
    margin-top: 10px;
    letter-spacing: 0.05em;
    margin-left: 15px;
}
.product-dark-container-price {
    display: flex;
}
.product-grid-dark-container-price {
    display: flex;
    align-items: center;
    justify-content: center;
}
.product-dark-description .product-dark-details {
    font-family: "Roboto", sans-serif;
    font-size: 13px;
    line-height: 15px;

    color: #ffffff;
    margin-top: 10px;
    letter-spacing: 0.05em;
    word-break: break-word;
}

.product-dark-description .product-dark-stock {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: #ffffff;
    margin-top: 10px;
}

.grid-dark-categories {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-column-gap: 21px;
    row-gap: 21px;
}

.grid-dark-categories .title {
    text-align: center;
    margin-top: 15px;
    color: white;
    font-family: "Dosis", sans-serif;
}

.product-dark-description .product-dark-title-details {
    font-family: "Roboto", sans-serif;
    font-size: 13px;
    line-height: 15px;

    color: #888888;
    margin-top: 30px;
}

.product-dark-recomendations {
    grid-area: product-dark-recomendations;
    overflow: auto;
    white-space: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
    margin-left: 20px;
}

.product-dark-recomendations h3 {
    font-size: 14px;
    font-weight: bold;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
    vertical-align: middle;
    width: 100%;
    line-height: 0;
    height: 80px !important;
    object-fit: cover !important;
}

.dark-back {
    font-size: 20px;
    color: white;
    text-shadow: 1px 2px 4px #000000;
    cursor: pointer;
}

.product-dark-recomendations img {
    width: 90px;
    height: 120px;
    object-fit: contain;
    margin: 2px;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}
.top-sellers {
    grid-area: top-sellers;
    margin-bottom: 80px;
}

.top-sellers .title {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
    font-weight: 200;
    color: white;
    font-size: 30px;
    font-family: "Dosis", sans-serif;
}

.header-dark {
    grid-area: header-dark;
    display: flex;
    align-items: center;
    position: fixed;
    height: 60px;
    width: 100%;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
    z-index: 100;
    justify-content: center;
    background-color: #424242;
}

.container-header-dark {
    display: flex;
    flex: 1;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    background-color: #424242;
}

.container-header-dark .zap {
    margin-left: 21px;
    font-size: 24px;
    cursor: pointer;
}

.container-header-dark .zap .link {
    color: white;
    text-decoration: none;
}

.product-grid-dark {
    grid-area: product-grid-dark;
    margin-bottom: 30px;
}

.line {
    grid-area: line;
}

.footer-dark {
    grid-area: footer-dark;
}

.footer-dark .separator {
    width: "100%";

    height: 2px;
    background-color: gray;
    margin-top: 40px;
}

.title-dark {
    font-family: "Dosis", sans-serif;
    font-size: 18px;
    color: #ffffff;

    text-transform: uppercase;
    word-wrap: break-word;
    text-align: center;
}

.cart-dark {
    margin-right: 21px;
    margin-bottom: 4px;
}
.cart-dark img {
    width: 18px;
    height: 17px;
}

.product-grid-dark {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    grid-column-gap: 13px;
}
.product-grid-container-dark {
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;
}

.product-grid-dark img {
    width: 100%;
    object-fit: cover;
    margin-top: 10px;
}

.product-grid-dark-title {
    font-family: "Roboto", sans-serif;
    line-height: 24px;
    font-size: 14px;
    align-items: center;
    color: #ffffff;
    margin-top: 15px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    text-transform: uppercase;
}

.product-grid-dark-sale {
    position: absolute;
    color: white;
    border: 1px solid white;
    top: 10px;
    right: 0;
    background-color: gray;
    padding: 5px;
    font-size: 13px;
}

.badge-dark {
    margin-right: 10px;
}

.badge-dark .MuiBadge-colorSecondary {
    background-color: rgba(var(--primary-color), 1);
    color: rgba(var(--dynamic-text-color), 1);
}

.badge-dark .icon {
    background-color: "white";
    color: white;
    font-size: 20px;
}

.product-grid-dark-price {
    font-family: "Oswald", sans-serif;
    font-size: 13px;
    color: #ffffff;
    margin-top: 5px;
    text-align: center;
}

.product-grid-dark-promoprice {
    font-family: "Oswald", sans-serif;
    font-size: 13px;
    color: rgba(var(--primary-color), 1);
    margin-top: 5px;
    text-align: center;
    margin-left: 10px;
    letter-spacing: 0.05em;
}

.product-grid-dark-price-line {
    font-family: "Oswald", sans-serif;
    font-size: 13px;
    color: #999999;
    margin-top: 5px;
    text-align: center;
    text-decoration: line-through;
}

.footer-dark-about-title {
    margin-top: 30px;
    font-family: "Roboto", sans-serif;
    font-size: 19px;
    color: white;
    padding-left: 30px;
    padding-right: 30px;
}

.footer-dark-about-text {
    margin-top: 10px;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    color: #ffffff;
    padding-left: 30px;
    padding-right: 30px;
}

.empty-cart-image {
    width: 40%;
    margin-top: 60px;
}

.empty-cart-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.empty-cart-container .title {
    font-family: "Montserrat", sans-serif;
    color: #f1456f;
    margin-top: 10px;
}
.empty-cart-container .subtitle {
    font-family: "Montserrat", sans-serif;
    color: gray;
    font-size: 12px;
}

#cart-dark-wrap {
    height: 100vh;
    display: grid;
    grid-template-columns: 21px 1fr 21px;
    grid-template-rows: 70px min-content min-content min-content min-content 1fr min-content;
    grid-template-areas:
        "cart-header-dark cart-header-dark cart-header-dark"
        ". cart-items-dark ."
        ". cart-shipping-dark ."
        ". cart-coupon-dark ."
        ". cart-total-dark ."
        ". cart-button-dark .";
}

.cart-shipping-dark {
    grid-area: cart-shipping-dark;
}

.cart-shipping-dark .cart-shipping {
    display: flex;
    margin-top: 25px;
    background-color: white;
    padding: 15px;
    border-radius: 10px;
    justify-content: space-between;
    display: flex;
    margin-top: 20px;
}

.cart-shipping-dark .cart-shipping .free-shipping {
    font-family: "Roboto", sans-serif;
    justify-content: center;
    font-size: 12px;
    color: rgb(66, 66, 66);
    flex: 1;
    display: flex;
    align-items: center;
}

.cart-shipping-dark .cart-shipping .MuiFormLabel-root.Mui-focused {
    color: #18dbbe !important;
}

.cart-shipping-dark
    .cart-shipping
    .MuiOutlinedInput-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border-color: #18dbbe !important;
    border-width: 2px;
}

.cart-shipping .apply-button {
    position: relative;
    border: solid #000000 1px;
    border-radius: 5px;
    margin: 0px;
    font-size: 0.1em;
    width: 50px;
    height: 19px;
    padding-top: 13px;
    justify-content: center;
    align-items: center;
    flex: 0.2 1;
    padding-bottom: 6px;
    margin-left: 5px;
    cursor: pointer;
    text-align: center;
}

.cart-shipping-dark .cart-shipping-results {
    display: flex;
    background-color: white;
    padding: 5px;
    border-radius: 0 0 10px 10px;
    justify-content: space-between;
    display: flex;
    margin-top: -10px;
    margin-bottom: 10px;
}

.cart-shipping-dark .cart-shipping-results .MuiRadio-colorSecondary.Mui-checked {
    color: #18dbbe !important;
}
.cart-shipping-dark .cart-shipping-results .MuiFormGroup-root {
    margin-left: 20px !important;
}

.cart-coupon-dark {
    grid-area: cart-coupon-dark;
}

.cart-coupon-dark .cart-coupon {
    display: flex;
    margin-top: 25px;
    background-color: white;
    padding: 15px;
    border-radius: 10px;
    justify-content: space-between;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
}

.cart-coupon-dark .cart-coupon .MuiFormLabel-root.Mui-focused {
    color: #18dbbe !important;
}

.cart-coupon-dark
    .cart-coupon
    .MuiOutlinedInput-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border-color: #18dbbe !important;
    border-width: 2px;
}

.cart-coupon-dark .cart-coupon .apply-button {
    border: solid #000000 1px;
    border-radius: 5px;
    margin: 0px;
    font-size: 0.1em;
    width: 50px;
    height: 19px;
    padding-top: 13px;
    justify-content: center;
    align-items: center;
    flex: 0.2 1;
    padding-bottom: 6px;
    margin-left: 5px;
    cursor: pointer;
    text-align: center;
}
.cart-header-dark {
    grid-area: cart-header-dark;
    display: flex;
    align-items: center;
    background-color: white;
    top: 0;
    position: fixed;
    height: 70px;
    width: 100%;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
    z-index: 100;
}
.cart-items-dark {
    grid-area: cart-items-dark;
}

.cart-items-dark .item {
    display: flex;
    margin-top: 25px;
    background-color: white;
    padding: 15px;
    border-radius: 10px;
    justify-content: space-between;
}

.cart-items-dark .item .item-dark {
    margin-left: 30px;
    margin-top: 30px;
}

.cart-items-dark .item-description {
    width: 50%;
    margin-left: 30px;
}
.cart-items-dark .item-picture {
    width: 30%;
}

.cart-items-dark .item-picture-image {
    width: 90px;
    height: 90px;
    object-fit: cover;
}

.cart-items-dark .item-name {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: #222222;
}
.cart-items-dark .item-variation {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    color: #888888;
}

.cart-items-dark .item-price {
    margin-top: 15px;
    margin-left: 3px;
    font-family: "Oswald", sans-serif;
    font-size: 18px;
    color: #888888;
    letter-spacing: 0.05em;
}

.cart-items-dark .item-price-line {
    margin-top: 15px;
    margin-left: 3px;
    font-family: "Oswald", sans-serif;
    font-size: 18px;
    color: #888888;

    text-decoration: line-through;
}

.cart-items-dark .item-promoprice {
    margin-top: 5px;
    font-family: "Oswald", sans-serif;
    font-size: 18px;
    color: black;
    letter-spacing: 0.05em;
}

.cart-items-dark .item-quantity {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    width: 20%;
    align-items: center;
}

.cart-items-dark .item .item-quantity .minus {
    font-size: 40px;
    color: lightgray;
    display: flex; /* or inline-flex */
    align-items: center;
    justify-content: center;
    font-family: "Dosis", sans-serif;
    height: 0px;
    cursor: pointer;
}

.cart-items-dark .item .item-quantity .qty {
    border: 1px solid rgba(var(--primary-color), 1);
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: rgba(var(--primary-color), 1);
    color: rgba(var(--dynamic-text-color), 1);
    display: flex; /* or inline-flex */
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
}
.cart-items-dark .item .item-quantity .add {
    font-size: 40px;
    color: lightgray;
    display: flex; /* or inline-flex */
    align-items: center;
    justify-content: center;
    font-family: "Dosis", sans-serif;
    height: 15px;
    cursor: pointer;
    margin-bottom: 5px;
}

.cart-total-dark {
    grid-area: cart-total-dark;
    display: flex;
    margin-top: 25px;
    background-color: white;
    padding: 15px;
    border-radius: 10px;
    justify-content: space-between;
    font-family: "Roboto", sans-serif;
}
.cart-button-dark {
    grid-area: cart-button-dark;
    margin-top: 40px;
    margin-bottom: 40px;
    background-color: rgba(var(--primary-color), 1);
    height: 58px;
    display: flex;
    cursor: pointer;
}

.cart-button-dark {
    font-family: "Dosis", sans-serif;
    font-size: 15px;
    color: rgba(var(--dynamic-text-color), 1);
    letter-spacing: 0.1em;
    align-items: center;
    justify-content: center;
}

@media screen and (min-width: 480px) {
    #dark-wrap {
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr 600px 1fr;
        grid-template-rows: 40px min-content min-content min-content min-content min-content min-content min-content min-content;
        grid-template-areas:
            ". header-dark ."
            ". blank ."
            ". promo-banner ."
            ". featured-dark ."
            ". grid-categories ."
            ". top-sellers ."
            ". rating-dark ."
            ". footer-dark ."
            ". social-media-dark .";
    }

    #dark-categories-wrap {
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr 600px 1fr;
        grid-template-rows: 40px min-content min-content min-content min-content min-content min-content min-content min-content;
        grid-template-areas:
            ". header-dark ."
            ". blank ."
            ". promo-banner ."
            "dark-categories-title dark-categories-title dark-categories-title"
            ". search-bar ."
            ". product-grid-dark ."
            ". load-more-button-dark ."
            ". footer-dark ."
            ". social-media-dark .";
    }

    #product-dark-wrap {
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr 600px 1fr;
        grid-template-rows: min-content min-content min-content 200px;
        grid-template-areas:
            ". product-dark-picture ."
            ". product-dark-variations ."
            ". product-dark-description ."
            ". product-dark-button .";
    }

    .product-dark-button .out-of-stock {
        height: 58px;
        border: 1px solid #fff;
        color: #fff;
        width: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    #cart-dark-wrap {
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr 600px 1fr;
        grid-template-rows: 70px min-content min-content min-content min-content 1fr min-content;
        grid-template-areas:
            ". cart-header-dark ."
            ". cart-items-dark ."
            ". cart-shipping-dark ."
            ". cart-coupon-dark ."
            ". cart-total-dark ."
            ". cart-button-dark .";
    }

    .container-header-dark .zap .link {
        width: 20px;
        height: 14px;
        margin-left: 420px;
    }

    .dark-back {
        display: none;
    }
    .selectall-dark {
        margin-right: 420px;
    }

    .container-header-dark .badge-dark {
        margin-right: 420px;
    }

    .back-dark {
        margin-left: 420px;
    }

    .cart-shipping-dark .cart-shipping .free-shipping {
        font-family: "Roboto", sans-serif;
        justify-content: center;
        font-size: 15px;
        color: rgb(66, 66, 66);
        flex: 1;
        display: flex;
        align-items: center;
    }
}
