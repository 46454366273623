/*================================== */
/* CHECKOUT
/*================================== */
#checkout-wrap {
    height: 100vh;
    display: grid;
    grid-template-columns: 30px 1fr 1fr 30px;
    grid-template-rows: min-content min-content min-content min-content min-content min-content min-content min-content min-content;
    grid-template-areas:
        "checkout-header checkout-header checkout-header checkout-header"
        ". checkout-header-2 checkout-header-2 ."
        ". checkout-client-data checkout-client-data ."
        ". checkout-delivery checkout-delivery ."
        ". checkout-sumary checkout-sumary ."
        ". checkout-payment checkout-payment ."
        ". checkout-note checkout-note ."
        ". checkout-card checkout-card ."
        ". checkout-buttons checkout-buttons ."
        "loading loading loading loading";
}

/*================================== */
/* HEADER
/*================================== */

.checkout-card {
    grid-area: checkout-card;
    margin-top: 40px;
    margin-bottom: 20px;
}

.checkout-card .title {
    color: rgba(var(--primary-color), 1);
    font-size: 14px;
    font-weight: 500;
}

.checkout-card .title-slip {
    color: rgba(var(--primary-color), 1);
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 20px;
}

.checkout-header-2 {
    grid-area: checkout-header-2;
}

.checkout-header {
    grid-area: checkout-header;
}

.checkout-header-2 .checkout-header-food img {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.checkout-header-2 .checkout-header-food h1 {
    color: #707070;
    font-size: 34px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    line-height: 31px;
}

.checkout-header .checkout-header-minimalist .title {
    font-family: "Bebas Neue", cursive;
    font-size: 18px;
    color: #222222;
}

.checkout-header .checkout-header-minimalist .checkout-icon img {
    width: 35px;
    height: 35px;
}

.checkout-header .checkout-header-minimalist {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    top: 0;
    position: fixed;
    height: 70px;
    width: 100%;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
    z-index: 100;
}

.checkout-header .checkout-header-fendi .title {
    font-family: "Bebas Neue", cursive;
    font-size: 18px;
    color: #222222;
}

.checkout-header .checkout-header-fendi .checkout-icon img {
    width: 35px;
    height: 35px;
}

.checkout-header .checkout-header-fendi {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    top: 0;
    position: fixed;
    height: 70px;
    width: 100%;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
    z-index: 100;
}

/*================================== */
/* DELIVERY
/*================================== */

.checkout-delivery {
    grid-area: checkout-delivery;
}

.checkout-client-data .marginTopFood {
    margin-top: 40px;
}

.checkout-client-data .marginTopMinimalist {
    margin-top: 110px;
}

.checkout-delivery .title {
    color: rgba(var(--primary-color), 1);
    font-size: 14px;
    font-weight: 500;
}

.checkout-delivery .options {
    display: grid;
    padding-left: 23px;
    margin-top: 20px;
}

.mdl-radio__label {
    cursor: pointer;
    padding-left: 12px;
    font-size: 14px;
}

.mdl-radio.is-checked .mdl-radio__outer-circle {
    border: 2px solid rgba(var(--primary-color), 1) !important;
}

.mdl-radio__inner-circle {
    background: rgba(var(--primary-color), 1) !important;
}

.mdl-textfield__label {
    font-size: 14px !important;
}

/*================================== */
/* CLIENT DATA
/*================================== */

.checkout-client-data {
    grid-area: checkout-client-data;
    margin-top: 20px;
}
.checkout-client-data .title {
    color: rgba(var(--primary-color), 1);
    font-size: 14px;
    font-weight: 500;
}

.checkout-client-data .data {
    margin-top: 20px;
}

/*================================== */
/* SUMARY
/*================================== */

.checkout-sumary {
    grid-area: checkout-sumary;
    margin-top: 20px;
}
.checkout-sumary .title {
    color: rgba(var(--primary-color), 1);
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 20px;
}

/*================================== */
/* PAYMENT
/*================================== */

.checkout-payment {
    grid-area: checkout-payment;
}
.checkout-payment .title {
    color: rgba(var(--primary-color), 1);
    font-size: 14px;
    font-weight: 500;
}
.checkout-payment .options {
    display: grid;
    padding-left: 23px;
    margin-top: 20px;
}

/*================================== */
/* NOTE
/*================================== */

.checkout-note {
    grid-area: checkout-note;
    margin-top: 30px;
}
.checkout-note .title {
    color: rgba(var(--primary-color), 1);
    font-size: 14px;
    font-weight: 500;
}

.checkout-note .data {
    margin-top: 20px;
}

/*================================== */
/* BUTTONS
/*================================== */

.checkout-buttons {
    grid-area: checkout-buttons;
}

.checkout-buttons .checkout-button-food {
    margin-top: 60px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-around;
}
.checkout-buttons .checkout-button-food .secondary {
    font-size: 14px;
    border: 2px solid rgba(var(--primary-color), 1);
    border-radius: 20px;
    padding: 15px 33px;
    color: rgba(var(--primary-color), 1);
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
}

.checkout-buttons .checkout-button-food .disabled {
    font-size: 14px;
    border: 2px solid rgba(0, 0, 0, 0.23);

    border-radius: 20px;
    padding: 15px 8px;
    color: white;
    font-weight: 600;
    text-decoration: none;
}

.checkout-buttons .checkout-button-food .primary {
    font-size: 14px;
    border: 2px solid rgba(var(--primary-color), 1);
    background-color: rgba(var(--primary-color), 1);
    border-radius: 20px;
    padding: 15px 8px;
    color: white;
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
}

.container-header-dark {
    display: flex;
    flex: 1;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    background-color: #424242;
}

.checkout-buttons .checkout-button-dark .enabled {
    margin-top: 40px;
    margin-bottom: 40px;
    background-color: rgba(var(--primary-color), 1);
    height: 58px;
    display: flex;
    cursor: pointer;
    font-family: "Dosis", sans-serif;
    font-size: 15px;
    color: rgba(var(--dynamic-text-color), 1);
    letter-spacing: 0.1em;
    align-items: center;
    justify-content: center;
}

.checkout-buttons .checkout-button-dark .disabled {
    margin-top: 40px;
    margin-bottom: 100px;
    height: 58px;
    display: flex;

    font-family: "Dosis", sans-serif;
    font-size: 15px;

    border: 1px solid rgba(0, 0, 0, 0.23);
    color: #e0e0e0;
    letter-spacing: 0.1em;
    align-items: center;
    justify-content: center;
}

.checkout-buttons .checkout-button-minimalist .enabled {
    margin-top: 40px;
    margin-bottom: 100px;
    background-color: rgba(var(--primary-color), 1);
    height: 58px;
    display: flex;
    box-shadow: 0px 15px 15px 0px rgba(0, 0, 0, 0.1);
    /*background: linear-gradient(to top, #444444 0%, rgba(0, 0, 0, 0.6) 100%);*/
    font-family: "Bebas Neue", cursive;
    font-size: 15px;
    color: rgba(var(--dynamic-text-color), 1);
    letter-spacing: 0.1em;
    align-items: center;
    justify-content: center;
    width: 100%;
    cursor: pointer;
}

.checkout-buttons .checkout-button-minimalist .disabled {
    margin-top: 40px;
    margin-bottom: 100px;
    height: 58px;
    display: flex;

    font-family: "Bebas Neue", cursive;
    font-size: 15px;

    border: 1px solid rgba(0, 0, 0, 0.23);
    color: #e0e0e0;
    letter-spacing: 0.1em;
    align-items: center;
    justify-content: center;
}

/*================================== */
/* LOADING
/*================================== */

.loading {
    grid-area: loading;
}

/*================================== */
/* TABLE
/*================================== */
.Rtable {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 2em 0;
    padding: 0;
}
.Rtable-cell {
    box-sizing: border-box;
    flex-grow: 1;
    width: 100%;
    border-bottom: 1px solid #e5e5e5;
    overflow: hidden;
    padding: 8px;
    list-style: none;
}
.Rtable-cell > h1,
.Rtable-cell > h2,
.Rtable-cell > h3,
.Rtable-cell > h4,
.Rtable-cell > h5,
.Rtable-cell > h6 {
    margin: 0;
}
.Rtable-footer {
    box-sizing: border-box;
    flex-grow: 1;
    width: 100%;
    overflow: hidden;
    list-style: none;
    padding: 8px;
}
.Rtable-footer > h1,
.Rtable-footer > h2,
.Rtable-footer > h3,
.Rtable-footer > h4,
.Rtable-footer > h5,
.Rtable-footer > h6 {
    margin: 0;
}
.Rtable-header {
    font-size: 13px;
    color: #9b9b9b;
}
.Rtable-content {
    font-size: 14px;
    color: #707070;
}
.Rtable-total {
    font-size: 16px;
    color: rgba(var(--primary-color), 1);
}
/* Table column sizing
================================== */
.Rtable--2cols > .Rtable-cell {
    width: 50%;
}
.Rtable--3cols > .Rtable-cell {
    width: 33.33%;
}
.Rtable--3cols > .Rtable-footer {
    width: 33.33%;
}
.Rtable--4cols > .Rtable-cell {
    width: 25%;
}
.Rtable--4cols > .Rtable-footer {
    width: 25%;
}
.Rtable--5cols > .Rtable-cell {
    width: 20%;
}
.Rtable--6cols > .Rtable-cell {
    width: 16.6%;
}

.MuiGridListTileBar-root {
    left: 0;
    right: 0;
    height: 22px !important;
    display: flex;
    position: absolute;
    background: none !important;
    align-items: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.MuiGridListTileBar-titleWrap {
    color: black !important;
    overflow: hidden;
    flex-grow: 0 !important;
    margin-left: 5px !important;
    margin-right: 16px;
    margin-bottom: 5px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 2px;
}

.MuiGridListTileBar-title {
    overflow: hidden;
    font-size: 11px !important;
    line-height: 12px !important;
    white-space: nowrap;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 5px;
    text-overflow: ellipsis;
    padding-right: 5px;
}
.secureImage {
    width: 100%;
}

/*================================== */
/* DESKTOP
/*================================== */
@media screen and (min-width: 480px) {
    #checkout-wrap {
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr 320px 320px 1fr;
        grid-template-rows: min-content min-content min-content min-content min-content min-content min-content min-content min-content;
        grid-template-areas:
            "checkout-header checkout-header checkout-header checkout-header"
            ". checkout-header-2 checkout-header-2 ."
            ". checkout-client-data checkout-client-data ."
            ". checkout-delivery checkout-delivery ."
            ". checkout-sumary checkout-sumary ."
            ". checkout-payment checkout-payment ."
            ". checkout-note checkout-note ."
            ". checkout-card checkout-card ."
            ". checkout-buttons checkout-buttons ."
            "loading loading loading loading";
    }

    .buttons {
        justify-content: center;
    }

    .buttons .primary {
        margin-left: 30px;
    }

    .buttons .disabled {
        margin-left: 30px;
    }

    .buttons .disabled-long {
        margin-left: 30px;
    }

    .buttons .primary-long {
        margin-left: 30px;
    }
    .secureImage {
        width: 50%;
    }
}
