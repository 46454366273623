@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

#promo-wrap {
    height: 100vh;
    background-color: gray;
    display: grid;
    grid-template-columns: 21px 1fr 1fr 21px;
    grid-template-rows: min-content min-content 400px;
    grid-template-areas:
        ". promo-header promo-header ."
        ". promo-content promo-content ."
        ". promo-footer promo-footer .";
}

#promo-wrap-payment {
    height: 100vh;
    display: grid;
    grid-template-columns: 10px 1fr 10px;
    grid-template-rows: min-content min-content;
    grid-template-areas:
        ".  promo-header ."
        ". promo-content .";
}

.promo-review {
    grid-area: promo-review;
}

.promo-review-cellphone {
    grid-area: promo-review-cellphone;
}

a {
    text-decoration: none;
}

.promo-header {
    grid-area: promo-header;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.promo-header .logo {
    margin-top: 40px;
    width: 30%;
}

.promo-header .title {
    font-size: 50px;
    text-align: center;
    line-height: 40px;
    padding: 20px;
}

.promo-header .title-black {
    font-size: 50px;
    text-align: center;
    line-height: 40px;
    padding: 20px;
    background-color: black;
    color: white;
}

.promo-header .subtitle-black {
    margin-top: 5px;
    font-size: 20px;
    text-align: center;
    line-height: 20px;
    padding: 10px;
    background-color: black;
    color: white;
}

.promo-header .countdown {
    font-size: 20px;
    text-align: center;
    line-height: 20px;
    padding: 10px;
    padding-right: 150px;
    padding-left: 150px;
    border: 2px solid black;
    background-color: black;
    color: white;
    border-radius: 5px;
    margin-top: 10px;
}

.promo-header .subtitle {
}

.promo-content {
    grid-area: promo-content;
}

.promo-content .promo-15 {
    padding: 15px;
    margin: -10px;
    border-radius: 26px;

    border: 2px solid yellow;
    color: white;

    background-color: #2e3344;
}

.promo-content .promo-black {
    padding: 15px;
    margin: -10px;
    border-radius: 26px;

    border: 2px solid white;
    color: white;

    background-color: #2e3344;
}
.promo-content .off {
    background-color: yellow;
    width: 40px;
    border-radius: 60px;
    padding: 11px;
    text-align: center;
    z-index: 10;
    position: relative;
}

.promo-content .off-black {
    background-color: black;
    color: white;
    width: 40px;
    border-radius: 60px;
    padding: 11px;
    text-align: center;
    z-index: 10;
    position: relative;
}
.promo-content .buy {
    margin-bottom: 40px;
    background-color: black;
    color: white;
    border: 1px solid white;
    border-radius: 60px;
    padding: 11px;
    text-align: center;
    z-index: 10;
    position: relative;
    font-size: 20px;
    cursor: pointer;
}

.promo-content .button {
    margin-bottom: 40px;
    background-color: black;
    color: white;
    border: 1px solid white;
    border-radius: 60px;
    padding: 11px;
    text-align: center;
    z-index: 10;
    position: relative;
    font-size: 20px;
    cursor: pointer;
}

.promo-content .promo-25 {
    background-color: white;
}

.promo-footer {
    grid-area: promo-footer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2e3344;
}

.promo-footer .button {
    background-color: #2e3344;
    border: 2px #ffffff solid;
    border-radius: 10px;
    padding: 10px;
    font-size: 22px;
}

@media screen and (min-width: 480px) {
    #promo-wrap {
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr 600px 1fr;
        grid-template-rows: min-content min-content min-content;
        grid-template-areas:
            ". promo-header ."
            ". promo-content ."
            ". promo-footer .";
    }
    #promo-wrap-payment {
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr 800px 1fr;
        grid-template-rows: min-content;
        grid-template-areas:
            ". promo-header ."
            ". promo-content .";
    }
}
