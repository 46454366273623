body {
    margin: 0;
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;
    overscroll-behavior-y: none;
}

.float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 20px;
    right: 20px;
    background-color: #25d366;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}

.my-float {
    margin-top: 16px;
}

#site-wrap {
    height: 100vh;
    display: grid;
    grid-template-columns: 30px 1fr 1fr 30px;
    grid-template-rows: min-content min-content min-content min-content min-content min-content min-content min-content min-content min-content min-content min-content min-content;
    grid-template-areas:
        ". header header ."
        ". social-media social-media ."
        ". header-title header-title ."
        "free-shipping free-shipping free-shipping free-shipping"
        "promo-banner promo-banner promo-banner promo-banner"
        "content-filter content-filter content-filter content-filter"
        "content-view content-view content-view content-view"
        ". search-bar search-bar ."
        "banner banner banner banner"
        "load-more-button load-more-button load-more-button load-more-button"
        ". rating rating ."
        "line line line line"
        ". footer footer .";
}

#categories-wrap {
    height: 100vh;
    display: grid;
    grid-template-columns: 30px 1fr 1fr 30px;
    grid-template-rows: min-content min-content min-content min-content min-content min-content min-content min-content;
    grid-template-areas:
        "promo-banner promo-banner promo-banner promo-banner"
        ". search-bar search-bar ."
        "banner banner banner banner"
        "load-more-button load-more-button load-more-button  load-more-button"
        ". buttons buttons .";
}

.rating {
    grid-area: rating;
}

.rating .rating-wrap {
    margin-top: 30px;
}

.rating .rating-content {
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 15px 50px 0px rgba(0, 0, 0, 0.1);
    font-size: 13px;
    color: #707070;
    padding-bottom: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.rating .rating-wrap .title {
    margin-top: 30px;
    font-size: 22px;
    color: #707070;
    padding-left: 20px;
}

.rating .first-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
}

.rating .rating-user {
    font-style: italic;
    padding-left: 30px;
}

.rating .rating-title {
    font-size: 15px;
}

.rating .second-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.rating .rating-review {
    margin-top: 15px;
    text-align: left;
    margin-bottom: 15px;
    padding-left: 30px;
    padding-right: 30px;
    text-align: left;
}

.rating .separator {
    width: "100%";
    height: 0px;
    background-color: gray;
    margin-top: 40px;
}

.free-shipping .food {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 10px;
    color: #ffff;
    background-color: #999999;
    padding: 6px;
}

.free-shipping .food .highlight {
    color: #ffffff;
    text-decoration: underline;
}

.social-media-container-food {
    display: flex;

    align-items: center;
}
.header {
    grid-area: header;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 12px;
}

.header img {
    margin-top: -5px;
}

.header .cart {
    border: 2px solid rgba(var(--primary-color), 1);
    border-color: rgba(var(--primary-color), 1);
    border-radius: 10px;
    padding: 4px;
    color: #fff;
}

.header .cart img {
    width: 24px;
    height: 24px;
}

.badge-food .MuiBadge-colorSecondary {
    background-color: #fff;
    border: 2px solid rgba(var(--primary-color), 1);
    color: rgba(var(--dynamic-text-color), 1);
}

.header-title {
    grid-area: header-title;
    margin-top: 23px;
}

.header-title .title {
    color: #707070;
    font-size: 34px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    margin-block-start: 0;
    margin-block-end: 0;
    line-height: 31px;
    letter-spacing: 0;
}

.header-title p {
    font-size: 12px;
    line-height: 20px;
    margin-block-start: 30px;
    margin-block-end: 10px;
}
.content-filter {
    grid-area: content-filter;
    margin-bottom: 20px;
    margin-top: 10px;
}
.content-filter hr {
    border: none;
    height: 1px;
    color: #707070;
    background-color: #707070;
}

.content-view {
    grid-area: content-view;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.copyright {
    grid-area: copyright;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #e3e3e3;
    padding: 10px;
    line-height: 10px;
}

.banner {
    grid-area: banner;
    margin-bottom: 30px;
}
.content {
    background-color: #e3e3e3;
    grid-area: content;
}
.line {
    grid-area: line;
}
.footer {
    margin-top: 20px;
    margin-bottom: 30px;
    grid-area: footer;
    display: flex;
}
.footer .logo {
    width: 90px;
    height: 90px;
}
.footer li {
    list-style: none;
    margin-left: 30px;
}
.footer li ul {
    margin: 0;
    padding: 0.4em;
    font-size: 12px;
    word-break: break-word;
}
.footer li ul img {
    width: 15px;
    height: 15px;
}
.copyright {
    grid-area: copyright;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.copyright img {
    width: 63px;
    height: 22px;
    margin-bottom: 5px;
}
.copyright span {
    color: #9b9b9b;
    font-size: 9px;
}
.line {
    margin-top: 10px;
    border: none;
    height: 1px;
    color: #e5e5e5;
    background-color: #e5e5e5;
    width: "100%";
}

.list-view {
    display: flex;
    flex: 1;
    margin-right: 20px;
    margin-top: 20px;
}
.list-view .image {
    width: 150px;
    height: 150px;
    margin-left: 20px;
    margin-top: 0;
}
.list-view .list-details {
    margin-left: 15px;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
}

.list-view .list-details .item-title {
    margin-top: 3px;
    font-size: 20px;
    font-weight: bold;
    color: #707070;
}

.list-view .list-details .item-description {
    font-size: 12px;
    line-height: 15px;
    color: #9b9b9b;
}

.list-view .list-details .item-price {
    font-size: 22px;
    margin-bottom: 3px;
    color: rgba(var(--primary-color), 1);
}

#product-wrap {
    height: 100vh;
    display: grid;
    grid-template-columns: 35px 1fr 1fr 35px;
    grid-template-rows: min-content min-content min-content min-content min-content;
    grid-template-areas:
        "product-picture product-picture product-picture product-picture"
        ". product-description product-description ."
        ". product-variations product-variations ."
        ". buttons buttons ."
        ". product-recomendations product-recomendations ."
        "copyright copyright copyright copyright";
}

.product-picture {
    grid-area: product-picture;
}

.product-description {
    grid-area: product-description;
}
.product-description h1 {
    font-size: 32px;
    color: #707070;
    font-weight: bold;
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.product-description .description {
    font-size: 16px;
    color: #000000;
    margin-bottom: 20px;
}

.product-description h2 {
    font-size: 31px;
    color: rgba(var(--primary-color), 1);
    font-weight: bold;
}

.buttons {
    grid-area: buttons;
    margin-top: 60px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-around;
}
.buttons .secondary {
    font-size: 14px;
    border: 2px solid rgba(var(--primary-color), 1);
    border-radius: 20px;
    padding: 15px 33px;
    color: rgba(var(--primary-color), 1);
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.buttons .primary {
    font-size: 14px;
    border: 2px solid rgba(var(--primary-color), 1);
    background-color: rgba(var(--primary-color), 1);
    border-radius: 20px;
    padding: 15px 33px;
    color: white;
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
}
.buttons .disabled {
    font-size: 14px;
    border: 2px solid lightgray;
    background-color: lightgray;
    border-radius: 20px;
    padding: 15px 33px;
    color: white;
    font-weight: 600;
    text-decoration: none;
}
.buttons .disabled-long {
    font-size: 14px;
    border: 2px solid lightgray;
    background-color: lightgray;
    border-radius: 20px;
    padding: 15px 8px;
    color: white;
    font-weight: 600;
    text-decoration: none;
}

.buttons .primary-long {
    font-size: 14px;
    border: 2px solid rgba(var(--primary-color), 1);
    background-color: rgba(var(--primary-color), 1);
    border-radius: 20px;
    padding: 15px 8px;
    color: white;
    font-weight: 600;
    text-decoration: none;
}

.product-recomendations {
    grid-area: product-recomendations;
    font-size: 14px;
}
.product-recomendations h3 {
    grid-area: product-recomendations;
    font-size: 14px;
    font-weight: bold;
}
.product-recomendations .images {
    display: flex;
    flex: 1;
    justify-content: space-between;
}
.product-recomendations img {
    width: 90px;
    height: 120px;
    object-fit: contain;
    margin: 2px;
    margin-bottom: 30px;
}

#cart-wrap {
    height: 100vh;
    display: grid;
    grid-template-columns: 30px 1fr 1fr 30px;
    grid-template-rows: min-content min-content min-content min-content min-content min-content min-content 50px;
    grid-template-areas:
        ". cart-header cart-header ."
        ". cart-header-title cart-header-title ."
        ". cart-items cart-items ."
        ". cart-shipping-food cart-shipping-food ."
        ". cart-coupon cart-coupon ."
        ". item-footer item-footer ."
        ". buttons buttons ."
        ". blank blank .";
}

.cart-shipping-food {
    grid-area: cart-shipping-food;
}

.cart-shipping-food .cart-shipping {
    display: flex;
    margin-top: 25px;
    border-radius: 10px;
    justify-content: space-between;
    display: flex;
    margin-top: 20px;
}

.cart-shipping-food .cart-shipping .free-shipping {
    font-family: "Roboto", sans-serif;
    justify-content: center;
    font-size: 15px;
    color: rgb(66, 66, 66);
    flex: 1;
    display: flex;
    align-items: center;
}

.cart-shipping-food .cart-shipping .apply-button {
    position: relative;
    border: solid #000000 1px;
    border-radius: 5px;
    margin: 0px;
    font-size: 0.1em;
    width: 50px;
    height: 19px;
    padding-top: 13px;
    justify-content: center;
    align-items: center;
    flex: 0.2 1;
    padding-bottom: 6px;
    margin-left: 5px;
    cursor: pointer;
    text-align: center;
}

.cart-shipping-food .cart-shipping-results {
    display: flex;
    border: solid rgba(0, 0, 0, 0.23) 1px;
    padding: 5px;
    border-radius: 0 0 10px 10px;
    justify-content: space-between;
    display: flex;
    margin-top: 5px;
    margin-bottom: 10px;
}

.cart-shipping-food .cart-shipping-results .MuiFormGroup-root {
    margin-left: 20px !important;
}

.line {
    grid-area: line;
}

.blank {
    grid-area: blank;
}
.item-footer {
    grid-area: item-footer;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    font-size: 20px;
    color: #707070;
}
.cart-header {
    grid-area: cart-header;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.cart-header .cart-icon img {
    width: 35px;
    height: 35px;
}

.cart-header-title {
    grid-area: cart-header-title;
}

.cart-header-title .title {
    color: #707070;
    font-size: 34px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    margin-block-start: 0;
    margin-block-end: 0;
    line-height: 31px;
    letter-spacing: 0;
}

.cart-items {
    grid-area: cart-items;
    margin-top: 40px;
}

.cart-items ul {
    text-decoration: none;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    justify-content: flex-start;
}
.cart-items li {
    font-size: 13px;
    color: #9b9b9b;
    margin-right: 40px;
    margin-bottom: 10px;
}

.cart-items .item {
    display: flex;
    flex: 1;
    margin-top: 20px;
}
.cart-items .item img {
    width: 90px;
    height: 120px;
}
.cart-items .item-variation {
    font-size: 13px;
    color: #222222;
}

.cart-items .item .item-picture {
    flex: 0.4;
    margin-left: 10px;
}
.cart-items .item .item-quantity {
    flex: 0.55;
    display: flex;
    align-items: center;
}
.cart-items .item .item-quantity .minus {
    border: 1px solid #cccccc;
    width: 28px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: -1px;
    background-color: white;
    box-sizing: unset;
    padding: 0;
}

.cart-items .item .item-quantity .qty {
    border: 1px solid #cccccc;
    width: 28px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cart-items .item .item-quantity .add {
    border: 1px solid #cccccc;
    width: 28px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -1px;
    background-color: white;
    box-sizing: unset;
    padding: 0;
}
.cart-items .item .item-desc {
    flex: 0.4;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.cart-items .item .item-desc .item-name {
    color: #707070;
    font-size: 14px;
    line-height: 13px;
}
.cart-items .item .item-desc .item-price {
    color: #707070;
    font-size: 18px;
}
.cart-items span {
    font-size: 14px;
}
.total {
    display: flex;
    justify-content: flex-end;
    font-size: 25px;
    color: rgba(var(--primary-color), 1);
    margin-right: 14px;
    margin-top: 10px;
    margin-bottom: 20px;
}
.MuiButton-textPrimary {
    color: slategray;
}

@media screen and (min-width: 480px) {
    #site-wrap {
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr 320px 320px 1fr;
        grid-template-rows: min-content min-content min-content min-content min-content min-content min-content min-content min-content min-content min-content 170px;
        grid-template-areas:
            ". header header ."
            ". social-media social-media ."
            ". header-title header-title ."
            ". free-shipping free-shipping ."
            ". promo-banner promo-banner ."
            "content-filter content-filter content-filter content-filter"
            "content-view content-view content-view content-view"
            ". search-bar search-bar ."
            ". banner banner ."
            "load-more-button load-more-button load-more-button load-more-button"
            ". rating rating ."
            "line line line line"
            ". footer footer .";
    }
    #categories-wrap {
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr 320px 320px 1fr;
        grid-template-rows: min-content min-content min-content min-content min-content min-content min-content min-content;
        grid-template-areas:
            ". promo-banner promo-banner ."
            ". search-bar search-bar ."
            ". banner banner ."
            "load-more-button load-more-button load-more-button  load-more-button"
            ". buttons buttons .";
    }
    #product-wrap {
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr 320px 320px 1fr;
        grid-template-rows: min-content min-content min-content min-content min-content;
        grid-template-areas:
            ". product-picture product-picture ."
            ". product-description product-description ."
            ". product-variations product-variations ."
            ". buttons buttons ."
            ". product-recomendations product-recomendations ."
            "copyright copyright copyright copyright";
    }
    #cart-wrap {
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr 320px 320px 1fr;
        grid-template-rows: min-content min-content min-content min-content min-content min-content min-content 50px;
        grid-template-areas:
            ". cart-header cart-header ."
            ". cart-header-title cart-header-title ."
            ". cart-items cart-items ."
            ". cart-shipping-food cart-shipping-food ."
            ". cart-coupon cart-coupon ."
            ". item-footer item-footer ."
            ". buttons buttons ."
            ". blank blank .";
    }

    .product-recomendations .images {
        justify-content: space-evenly;
    }

    .buttons {
        justify-content: center;
    }

    .buttons .primary {
        margin-left: 30px;
    }

    .buttons .disabled {
        margin-left: 30px;
    }

    .buttons .disabled-long {
        margin-left: 30px;
    }

    .buttons .primary-long {
        margin-left: 30px;
    }

    .float {
        bottom: 40px;
        right: 40px;
    }

    .product-recomendations img {
        width: 150px;
        height: 100%;
        object-fit: contain;
        margin: 2px;
        margin-bottom: 30px;
    }
}
