#success-wrap {
    height: 100vh;
    display: grid;
    grid-template-columns: 30px 1fr 1fr 30px;
    grid-template-rows: min-content min-content min-content min-content min-content min-content min-content min-content min-content min-content min-content min-content;
    grid-template-areas:
        ". success-header success-header ."
        ". success-header-title success-header-title ."
        "success-order success-order success-order success-order"
        ". success-sumary success-sumary ."
        ". success-details success-details ."
        ". success-status success-status ."
        ". success-delivery success-delivery ."
        ". success-shipping success-shipping ."
        ". success-payment success-payment ."
        ". success-note success-note ."
        ". success-buttons success-buttons ."
        "copyright copyright copyright copyright";
}
.success-header {
    grid-area: success-header;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.success-header .success-icon img {
    width: 35px;
    height: 35px;
}

.success-header-title {
    grid-area: success-header-title;
}

.success-header-title h1 {
    color: #707070;
    font-size: 34px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    line-height: 31px;
}

.success-order {
    grid-area: success-order;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #e3e3e3;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 40px;
    margin-bottom: 10px;
}

.success-order h1 {
    font-size: 23px;
    color: #707070;
    margin-block-start: 0;
    margin-block-end: 0;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 5px;
    margin-block-start: 0;
    margin-block-end: 0;
    line-height: 31px;
}

.success-order p {
    font-size: 14px;
    color: #707070;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-left: 22px;
    margin-right: 22px;
    margin-top: 5px;
}

.success-order h3 {
    font-size: 14px;
    color: #707070;
    margin-block-start: 0;
    margin-block-end: 0;
    line-height: 15px;
}

.success-order span {
    margin-top: 10px;

    font-size: 23px;
    color: rgba(var(--primary-color), 1);
    margin-block-start: 0;
}

.success-shipping {
    grid-area: success-shipping;
    margin-top: 20px;
}
.success-shipping .title {
    color: rgba(var(--primary-color), 1);
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
}

.success-shipping p {
    font-size: 15px;
    color: #707070;
    margin-block-start: 0;
    margin-block-end: 0;
}

.success-delivery {
    grid-area: success-delivery;
    margin-top: 20px;
}
.success-delivery .title {
    color: rgba(var(--primary-color), 1);
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
}

.success-delivery p {
    font-size: 15px;
    color: #707070;
    margin-block-start: 0;
    margin-block-end: 0;
}
.success-sumary {
    grid-area: success-sumary;
    margin-top: 20px;
}
.success-sumary .title {
    color: rgba(var(--primary-color), 1);
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 20px;
}

.success-details {
    grid-area: success-details;
}

.success-status {
    grid-area: success-status;
    margin-top: 20px;
}

.success-status .title {
    color: rgba(var(--primary-color), 1);
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
}
.success-status p {
    font-size: 15px;
    color: #707070;
    margin-block-start: 0;
    margin-block-end: 0;
}

.success-details .title {
    color: rgba(var(--primary-color), 1);
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
}
.success-details p {
    font-size: 15px;
    color: #707070;
    margin-block-start: 0;
    margin-block-end: 0;
}

.success-note {
    grid-area: success-note;
}
.success-note .title {
    color: rgba(var(--primary-color), 1);
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 20px;
}

.success-note p {
    font-size: 15px;
    color: #707070;
    margin-block-start: 0;
    margin-block-end: 0;
}

.success-payment {
    grid-area: success-payment;
}
.success-payment .title {
    color: rgba(var(--primary-color), 1);
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 20px;
}
.success-payment p {
    font-size: 15px;
    color: #707070;

    margin-block-start: 0;
    margin-block-end: 0;
}

.success-buttons {
    grid-area: success-buttons;
}

.success-buttons .success-button-minimalist {
    margin-top: 40px;
    margin-bottom: 100px;
    background-color: #444444;
    height: 58px;
    display: flex;
    box-shadow: 0px 15px 15px 0px rgba(0, 0, 0, 0.1);
    background: linear-gradient(to top, #444444 0%, rgba(0, 0, 0, 0.6) 100%);
    font-family: "Bebas Neue", cursive;
    font-size: 15px;
    color: #fff;
    letter-spacing: 0.1em;
    align-items: center;
    justify-content: center;
    width: 100%;
    cursor: pointer;
}
.slip-button {
    margin-top: 40px;
    background-color: #444444;
    height: 58px;
    display: flex;
    box-shadow: 0px 15px 15px 0px rgba(0, 0, 0, 0.1);
    background: linear-gradient(to top, #444444 0%, rgba(0, 0, 0, 0.6) 100%);
    font-size: 15px;
    color: #fff;
    letter-spacing: 0.1em;
    align-items: center;
    justify-content: center;
    width: 100%;
    cursor: pointer;
}
.success-buttons .success-button-food {
    display: flex;
    justify-content: center;
}
.success-buttons .success-button-food .start {
    margin-top: 60px;
    margin-bottom: 30px;

    font-size: 14px;
    border: 2px solid rgba(var(--primary-color), 1);
    border-radius: 20px;
    padding: 15px 33px;
    color: rgba(var(--primary-color), 1);
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
}

@media screen and (min-width: 480px) {
    #success-wrap {
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr 320px 320px 1fr;
        grid-template-rows: min-content min-content min-content min-content min-content min-content min-content min-content min-content min-content min-content min-content;
        grid-template-areas:
            ". success-header success-header ."
            ". success-header-title success-header-title ."
            "success-order success-order success-order success-order"
            ". success-sumary success-sumary ."
            ". success-details success-details ."
            ". success-status success-status ."
            ". success-delivery success-delivery ."
            ". success-shipping success-shipping ."
            ". success-payment success-payment ."
            ". success-note success-note ."
            ". success-buttons success-buttons ."
            "copyright copyright copyright copyright";
    }
}
