@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@600;700;800&family=Nunito+Sans:wght@400;600;700;800&display=swap");

.cart-fendi .MuiBadge-colorSecondary {
    background-color: #6e7075;
    color: #f9fafc;
}

.carousel-container {
    position: relative;
    max-width: 800px;
    margin: auto;
}
.carousel-position {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 18px;
    border-radius: 15px;
    font-size: 12px;
    border-color: gray;
    color: #ffffff;
    margin-right: 10px;
    margin-top: 10px;
    background-color: #504a4afc;
    text-align: center;
    padding-top: 4px;
}

.carousel-dotgroup {
    text-align: center;
    margin-top: 10px;
}

.carousel__dot--selected {
    background-color: gray;
}
.carousel-dotgroup button {
    width: 10px;
    padding: 0px;
    height: 10px;
    margin-right: 5px;
    border-radius: 5px;
}

#fendi-wrap {
    height: 100vh;
    display: grid;
    grid-template-columns: 21px 1fr 21px;
    grid-template-rows: 70px 30px min-content min-content min-content min-content min-content min-content min-content min-content min-content;
    grid-template-areas:
        "header-fendi header-fendi header-fendi"
        "announcement-fendi announcement-fendi announcement-fendi"
        "free-shipping free-shipping free-shipping"
        "banner-fendi banner-fendi banner-fendi"
        ". product-grid-fendi ."
        "load-more-button load-more-button load-more-button"
        ". rating-fendi ."
        ". line ."
        ". footer-fendi ."
        ". social-media ."
        "copyright-fendi copyright-fendi copyright-fendi";
}

.rating-fendi {
    grid-area: rating-fendi;
}

.rating-fendi .rating-wrap {
    margin-top: 30px;
    font-family: "Abhaya Libre", serif;
}

.rating-fendi .rating-content {
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 15px 50px 0px rgba(0, 0, 0, 0.1);
    font-size: 13px;
    color: #6e7075;
    padding-bottom: 20px;
    margin-top: 30px;
}

.rating-fendi .rating-wrap .title {
    margin-top: 30px;
    font-size: 30px;
    color: #373435;
}

.rating-fendi .first-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
}

.rating-fendi .rating-user {
    font-style: italic;
    padding-left: 30px;
}

.rating-fendi .rating-title {
    font-size: 15px;
}

.rating-fendi .second-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.rating-fendi .rating-review {
    margin-top: 15px;
    text-align: left;
    margin-bottom: 15px;
    padding-left: 30px;
    padding-right: 30px;
    text-align: left;
}

.rating-fendi .separator {
    width: "100%";
    height: 0px;
    background-color: gray;
    margin-top: 40px;
}

.free-shipping {
    grid-area: free-shipping;
}

.free-shipping .fendi {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Nunito Sans", sans-serif;
    font-size: 12px;
    color: #373435;
    background-color: #ffffff;
    padding: 6px;
}

.free-shipping .highlight {
    color: #6e7075;
}

.free-shipping .space {
    margin-left: 3px;
    margin-right: 3px;
}

.load-more-button {
    grid-area: load-more-button;
    display: flex;
    text-align: center;
    padding: 10px;
    border: 1px solid;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    cursor: pointer;
}

.social-media {
    grid-area: social-media;
}

.social-media-container {
    display: flex;
    margin-top: 30px;
    align-items: center;
}

.social-media .title {
    font-family: "Abhaya Libre", serif;
    font-size: 20px;
    color: #373435;
    margin-right: 10px;
}

.social-media .icon {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: #6e7075;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    margin-right: 10px;
}

.social-media i {
    color: white;
    font-size: 20px;
}

.drawer-title {
    margin-left: 30px;
    font-family: "Nunito Sans", sans-serif;
    font-size: 20px;
    color: #373435;
}

.drawer-items {
    margin-top: 30px;
    margin-left: 30px;
    font-family: "Abhaya Libre", serif;
    font-size: 28px;
    color: #888888;
    list-style-type: none;
}
.drawer-items li:not(:last-child) {
    margin-bottom: 10px;
}
.empty-cart-image {
    width: 40%;
    margin-top: 60px;
}

.empty-cart-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.empty-cart-container .title {
    font-family: "Nunito Sans", sans-serif;
    color: #f1456f;
    margin-top: 10px;
}
.empty-cart-container .subtitle {
    font-family: "Nunito Sans", sans-serif;
    color: gray;
    font-size: 12px;
}

#cart-fendi-wrap {
    height: 100vh;
    display: grid;
    grid-template-columns: 21px 1fr 21px;
    grid-template-rows: 70px min-content min-content min-content min-content 1fr min-content;
    grid-template-areas:
        "cart-header-fendi cart-header-fendi cart-header-fendi"
        ". cart-items-fendi ."
        ". cart-shipping-fendi ."
        ". cart-coupon-fendi ."
        ". cart-total-fendi ."
        ". cart-button-fendi ."
        "copyright-fendi copyright-fendi copyright-fendi";
}
.cart-shipping-fendi {
    grid-area: cart-shipping-fendi;
}

.cart-shipping-fendi .cart-shipping {
    display: flex;
    margin-top: 25px;
    border-radius: 10px;
    justify-content: space-between;
    display: flex;
    margin-top: 20px;
}

.cart-shipping-fendi .cart-shipping .free-shipping {
    font-family: "Roboto", sans-serif;
    justify-content: center;
    font-size: 15px;
    color: rgb(66, 66, 66);
    flex: 1;
    display: flex;
    align-items: center;
}

.cart-shipping-fendi .cart-shipping .apply-button {
    position: relative;
    border: solid #373435 1px;
    border-radius: 5px;
    margin: 0px;
    font-size: 0.1em;
    width: 50px;
    height: 19px;
    padding-top: 13px;
    justify-content: center;
    align-items: center;
    flex: 0.2 1;
    padding-bottom: 6px;
    margin-left: 5px;
    cursor: pointer;
    text-align: center;
}

.cart-shipping-fendi .cart-shipping-results {
    display: flex;
    border: solid rgba(0, 0, 0, 0.23) 1px;
    padding: 5px;
    border-radius: 0 0 10px 10px;
    justify-content: space-between;
    display: flex;
    margin-top: 5px;
    margin-bottom: 10px;
}

.cart-shipping-fendi .cart-shipping-results .MuiFormGroup-root {
    margin-left: 20px !important;
}

.cart-coupon-fendi {
    grid-area: cart-coupon-fendi;
}

.cart-coupon-fendi .cart-coupon {
    display: flex;
    margin-top: 25px;
    padding: 15px;
    border-radius: 10px;
    justify-content: space-between;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
}

.cart-coupon-fendi .apply-button {
    border: solid #373435 1px;
    border-radius: 5px;
    margin: 0px;
    font-size: 0.1em;
    width: 50px;
    height: 19px;
    padding-top: 13px;
    justify-content: center;
    align-items: center;
    flex: 0.2 1;
    padding-bottom: 6px;
    margin-left: 5px;
    cursor: pointer;
    text-align: center;
}

.cart-coupon-fendi .cart-coupon .MuiFormLabel-root.Mui-focused {
    color: #373435 !important;
}

.cart-coupon-fendi
    .cart-coupon
    .MuiOutlinedInput-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border-color: #373435 !important;
    border-width: 2px;
}

.cart-header-fendi {
    grid-area: cart-header-fendi;
    display: flex;
    align-items: center;
    background-color: white;
    top: 0;
    position: fixed;
    height: 70px;
    width: 100%;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
    z-index: 100;
}
.cart-items-fendi {
    grid-area: cart-items-fendi;
}

.cart-items-fendi .item {
    display: flex;
    margin-top: 25px;
}

.cart-items-fendi .item .item-description {
    margin-left: 30px;
    margin-top: 30px;
}
.cart-items-fendi .item-picture-image {
    width: 130px;
    height: 190px;
    object-fit: cover;
}

.cart-items-fendi .item-name {
    font-family: "Abhaya Libre", serif;
    line-height: 25px;
    font-size: 22px;
    color: #373435;
}
.cart-items-fendi .item-variation {
    font-family: "Abhaya Libre", serif;
    color: #373435;
}
.cart-items-fendi .item-price {
    margin-top: 15px;
    margin-left: 3px;
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    color: #888888;
    letter-spacing: 0.05em;
}

.cart-items-fendi .item-price-line {
    margin-top: 15px;
    margin-left: 3px;
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    color: #888888;

    text-decoration: line-through;
}

.cart-items-fendi .item-promoprice {
    margin-top: 15px;
    margin-left: 10px;
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    color: #6e7075;
    letter-spacing: 0.05em;
}

.cart-items-fendi .item-quantity {
    display: flex;
    margin-top: 15px;
}

.cart-items-fendi .item .item-quantity .minus {
    border: 1px solid #cccccc;
    width: 28px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: -1px;
    background-color: #b4b3a0;
    box-sizing: unset;
    padding: 0;
}

.cart-items-fendi .item .item-quantity .qty {
    border: 1px solid #cccccc;
    width: 28px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #b4b3a0;
}
.cart-items-fendi .item .item-quantity .add {
    border: 1px solid #cccccc;
    width: 28px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -1px;
    background-color: #b4b3a0;
    box-sizing: unset;
    padding: 0;
}

.cart-total-fendi {
    grid-area: cart-total-fendi;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    font-family: "Abhaya Libre", serif;

    font-size: 20px;
    color: #373435;
}
.cart-button-fendi {
    grid-area: cart-button-fendi;
    margin-top: 40px;
    margin-bottom: 100px;
    background-color: #444444;
    height: 58px;
    display: flex;
    box-shadow: 0px 15px 15px 0px rgba(0, 0, 0, 0.1);
    background: linear-gradient(to top, #444444 0%, rgba(0, 0, 0, 0.6) 100%);
    font-family: "Nunito Sans", sans-serif;
    font-size: 15px;
    color: #fff;
    letter-spacing: 0.1em;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

#product-fendi-wrap {
    height: 100vh;
    display: grid;
    grid-template-columns: 21px 1fr 21px;
    grid-template-rows: min-content min-content min-content min-content min-content min-content min-content;
    grid-template-areas:
        "product-fendi-picture product-fendi-picture product-fendi-picture"
        ". product-fendi-description ."
        "product-variations product-variations product-variations"
        ". product-fendi-button ."
        ". product-fendi-recomendations ."
        "copyright-fendi copyright-fendi copyright-fendi";
}

.product-fendi-picture {
    grid-area: product-fendi-picture;
}
.product-fendi-description {
    grid-area: product-fendi-description;
    margin-top: 35px;
}
.product-variations {
    grid-area: product-variations;
}
.product-variations .title {
    background-color: #e6e6e6;
    padding: 24px;
    margin-top: 20px;

    font-size: 20px;
}
.product-variations .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Abhaya Libre";
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: solid 1px;
    border-bottom-color: #e6e6e6;
}

.product-variations .list-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.product-variations .list-box .description-on {
    border: solid 1px;
    background: black;
    color: white;
    padding: 10px;
    margin-bottom: 5px;
    margin-right: 5px;
}
.product-variations .list-box .description-off {
    border: solid 1px;
    background: white;
    padding: 10px;
    margin-bottom: 5px;
    margin-right: 5px;
}

.product-variations .item .description {
    padding-left: 23px;

    font-size: 16px;
}
.product-variations .quantity {
    display: flex;
    padding-right: 23px;
}

.product-variations .quantity .value {
    font-size: 22px;
    padding-right: 15px;
    padding-left: 15px;
}
.product-variations .item .button {
    font-size: 25px;
    color: red;
    font-weight: 600;
    cursor: pointer;
}

.product-variations .list-radio {
    margin-top: 20px;
    margin-left: 23px;
}

.product-variations .list-radio .label {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.product-variations .list-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.product-variations .list-radio .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
}

/* When the radio button is checked, add a blue background */
.product-variations .list-radio input:checked ~ .checkmark {
    background-color: black;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.product-variations .list-radio .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.product-variations .list-radio input:checked ~ .checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.product-variations .list-radio .checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}
.product-fendi-stock {
    display: none;
}

.product-fendi-button {
    grid-area: product-fendi-button;
    display: flex;
    margin-top: 40px;
    margin-bottom: 40px;
    font-family: "Nunito Sans", sans-serif;
    font-size: 15px;
    color: #fff;
    letter-spacing: 0.1em;
    align-items: center;
    justify-content: center;
}

.product-fendi-button .in-stock {
    background-color: #444444;
    height: 58px;
    box-shadow: 0px 15px 15px 0px rgba(0, 0, 0, 0.1);
    background: linear-gradient(to top, #444444 0%, rgba(0, 0, 0, 0.6) 100%);
    cursor: pointer;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
}

.product-fendi-button .out-of-stock {
    height: 58px;
    border: 1px solid lightgray;
    color: gray;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
}

.product-fendi-button .in-stock:active {
    transform: translateY(4px);
    background: gray;
}

.product-fendi-description .product-fendi-name {
    font-family: "Abhaya Libre", serif;
    line-height: 47px;
    font-size: 28px;
    color: #373435;
}
.product-fendi-description .product-fendi-price {
    font-family: "Nunito Sans", sans-serif;
    font-size: 20px;
    color: #888888;
    margin-top: 10px;
    letter-spacing: 0.05em;
    margin-left: 5px;
}
.product-fendi-description .product-fendi-price-line {
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    color: #6e7075;
    margin-top: 10px;
    text-decoration: line-through;
}
.product-fendi-description .product-fendi-promoprice {
    font-family: "Nunito Sans", sans-serif;
    font-size: 15px;
    color: #373435;
    margin-top: 10px;
    letter-spacing: 0;
    margin-left: 15px;
}
.product-fendi-container-price {
    display: flex;
}
.product-grid-fendi-container-price {
    display: flex;
    align-items: left;
    justify-content: flex-start;
    flex-direction: column;
}
.product-fendi-description .product-fendi-details {
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    line-height: 21px;
    color: #373435;
    margin-top: 10px;
    letter-spacing: 0;
    word-break: break-word;
}

.product-fendi-recomendations {
    grid-area: product-fendi-recomendations;
    font-size: 14px;
}
.product-fendi-recomendations h3 {
    font-size: 14px;
    font-weight: bold;
}
.product-fendi-recomendations .images {
    display: flex;
    flex: 1;
    justify-content: space-between;
}
.product-fendi-recomendations img {
    width: 90px;
    height: 120px;
    object-fit: cover;
    margin: 2px;
    margin-bottom: 30px;
}
.blank-fendi {
    grid-area: blank-fendi;
    display: none;
    align-items: center;
    justify-content: center;
    background-color: rgb(222, 222, 222);
}

.blank-fendi span {
    font-weight: bold;
}

.blank-fendi p {
    font-family: "Nunito Sans", sans-serif;
    font-size: 11px;
    color: #6e7075;
    line-height: 2px;
}

.blank-fendi a {
    text-decoration: none;
}
.blank-fendi i {
    color: #6e7075;
    font-size: 20px;
    margin-right: 10px;
}

.blank-fendi-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-fendi {
    grid-area: header-fendi;
    display: flex;
    align-items: center;
    background-color: #f9fafc;
    position: fixed;
    height: 70px;
    width: 100%;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
    z-index: 100;
}
.container-header-fendi {
    display: flex;
    flex: 1;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-end;
}

.banner-fendi {
    grid-area: banner-fendi;
}

.product-grid-fendi {
    grid-area: product-grid-fendi;
    margin-bottom: 30px;
}

.line {
    grid-area: line;
}

.footer-fendi {
    grid-area: footer-fendi;
}

.copyright-fendi {
    grid-area: copyright-fendi;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #e3e3e3;
    padding: 10px;
    line-height: 10px;
}
.copyright-fendi img {
    width: 63px;
    height: 22px;
    margin-bottom: 5px;
}
.copyright-fendi span {
    color: #9b9b9b;
    font-size: 9px;
}

.title-fendi {
    width: 100px;
    font-size: 0px;
    color: transparent;
    background-size: contain;
    background-repeat: no-repeat;
    height: 55px;
    background-position: center;
    background-image: url("https://res.cloudinary.com/rockedevelopr/image/upload/v1637193773/monicabernardes%40gmail.com/logotipo_simbolo_media_kc7srf.png");
}
.menu-fendi img {
    width: 20px;
    height: 14px;
    margin-left: 21px;
}
.back-fendi img {
    width: 20px;
    height: 20px;
    margin-left: 21px;
}
.cart-fendi {
    margin-right: 21px;
    margin-bottom: 4px;
}
.cart-fendi img {
    width: 20px;
    height: 21px;
}

.selectall-fendi img {
    width: 22px;
    height: 18px;
    margin-right: 21px;
}

.product-grid-fendi {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    grid-column-gap: 13px;
}
.product-grid-container-fendi {
    position: relative;
    margin-top: 30px;
}
.product-grid-fendi img {
    width: 100%;
    height: 233px;
    object-fit: cover;
}

.product-grid-fendi-title {
    font-family: "Abhaya Libre", serif;
    line-height: 24px;
    font-size: 22px;
    align-items: center;
    color: #373435;
    margin-top: 15px;
    text-align: left;
    padding-left: 0px;
    padding-right: 10px;
    font-weight: 600;
}

.product-grid-fendi-price {
    font-family: "Nunito Sans", sans-serif;
    font-size: 13px;
    color: #999999;
    margin-top: 10px;
    text-align: center;
    letter-spacing: 0.05em;
}

.product-grid-fendi-promoprice {
    font-family: "Nunito Sans", sans-serif;
    font-size: 13px;
    color: #373435;
    margin-top: 5px;
    text-align: left;
    margin-left: 0px;
    letter-spacing: 0;
    font-weight: 700;
}

.product-grid-fendi-price-line {
    font-family: "Nunito Sans", sans-serif;
    font-size: 13px;
    color: #999999;
    margin-top: 10px;
    text-align: left;
    text-decoration: line-through;
}

.banner-container-fendi {
    position: relative;
}

.banner-container-fendi:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.banner-fendi-title {
    position: absolute;
    bottom: 65px;
    left: 21px;
    z-index: 1;
    color: white;
    font-family: "Abhaya Libre", serif;
    line-height: 30px;
    font-size: 28px;
    padding-right: 120px;
}

.banner-fendi-price {
    position: absolute;
    bottom: 30px;
    left: 25px;
    text-align: center;
    font-size: 30px;
    z-index: 1;
    color: white;
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    letter-spacing: 0.05em;
}

.banner-fendi-promoprice {
    position: absolute;
    bottom: 10px;
    left: 25px;
    text-align: center;

    z-index: 1;
    color: white;
    font-family: "Nunito Sans", sans-serif;
    font-size: 22px;
    letter-spacing: 0.05em;
}
.banner-fendi-price-line {
    position: absolute;
    bottom: 35px;
    left: 25px;
    text-align: center;
    font-size: 33px;
    z-index: 1;
    color: lightgray;
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    text-decoration: line-through;
}

.banner-fendi-add {
    position: absolute;
    width: 40px;
    height: 40px;
    bottom: 40px;
    right: 21px;
    background-color: #fff;
    color: #fff;
    border-radius: 20px;
    text-align: center;
    font-size: 30px;
    box-shadow: 0px 15px 5px 0px rgba(0, 0, 0, 0.1);
    z-index: 1;
}

.card-fendi {
    margin-top: 30px;
    width: 100%;
    height: 200px;
    background-color: #d4d4d4;
    box-shadow: 0px 15px 50px 0px rgba(0, 0, 0, 0.1);
}

.card-fendi-text {
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    color: #6e7075;
    padding: 30px;
}

.footer-fendi-title {
    margin-top: 30px;
    font-family: "Abhaya Libre", serif;
    font-size: 33px;
    color: #373435;
}
.footer-fendi-logo {
    display: flex;
    margin-top: 30px;
    font-family: "Nunito Sans", sans-serif;
    font-size: 13px;
    color: #6e7075;
    padding: 30px 15px;
}

.footer-fendi-logo img {
    width: 80px;
    height: 80px;
    border-radius: 40px;
}
.footer-fendi-logo li {
    list-style: none;
    margin-left: 30px;
}
.footer-fendi-logo li ul {
    margin: 0;
    padding: 0.4em;
    font-size: 14px;
    word-break: break-word;
}
.footer-fendi-logo li ul img {
    width: 15px;
    height: 15px;
}

.announcement-fendi {
    grid-area: announcement-fendi;
    background-color: #999999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.announcement-fendi .text {
    font-family: "Nunito Sans", sans-serif;
    font-size: 11px;
    color: #f9fafc;
    letter-spacing: 0.5em;
    text-align: center;
}

@media screen and (min-width: 480px) {
    #fendi-wrap {
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr 600px 1fr;
        grid-template-rows: 70px 20px min-content min-content min-content min-content min-content;
        grid-template-areas:
            ". header-fendi ."
            " announcement-fendi announcement-fendi announcement-fendi"
            ". free-shipping ."
            ". banner-fendi ."
            ". product-grid-fendi ."
            "load-more-button load-more-button load-more-button"
            ". rating-fendi ."
            ". line ."
            ". social-media ."
            ". footer-fendi ."
            "copyright-fendi copyright-fendi copyright-fendi";
    }
    #product-fendi-wrap {
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr 600px 1fr;
        grid-template-rows: min-content min-content min-content min-content min-content min-content;
        grid-template-areas:
            ". product-fendi-picture ."
            ". product-fendi-description ."
            ". product-variations ."
            ". product-fendi-button ."
            ". product-fendi-recomendations ."
            "copyright-fendi copyright-fendi copyright-fendi";
    }
    #cart-fendi-wrap {
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr 600px 1fr;
        grid-template-rows: 70px min-content min-content min-content 200px min-content;
        grid-template-areas:
            "cart-header-fendi cart-header-fendi cart-header-fendi"
            ". cart-items-fendi ."
            ". cart-shipping-fendi ."
            ". cart-coupon-fendi ."
            ". cart-total-fendi ."
            ". cart-button-fendi ."
            ". cart-blank-fendi ."
            "copyright-fendi copyright-fendi copyright-fendi";
    }

    .cart-fendi {
        margin-right: 420px;
        margin-bottom: 4px;
    }

    .menu-fendi img {
        width: 20px;
        height: 14px;
        margin-left: 420px;
    }
    .back-fendi img {
        width: 20px;
        height: 20px;
        margin-left: 420px;
    }

    .selectall-fendi img {
        width: 22px;
        height: 18px;
        margin-right: 420px;
    }

    .cart-blank-fendi {
        grid-area: cart-blank-fendi;
    }

    .empty-cart-image {
        width: 30%;
    }
    .empty-cart-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
